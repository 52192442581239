import * as Yup from "yup";
import MESSAGE from "~/utils/messages";

export const schema = Yup.object().shape({
  Codigo: Yup.string()
    .matches(
      /^[0-9]+$/,
      MESSAGE.invalidCode
    )
    .required(MESSAGE.requiredField),  
});