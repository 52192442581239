import styled from "styled-components";
import { Checkbox } from "formik-material-ui";

export const StyledCheckbox = styled(Checkbox)`
  font-size: 18px;
  display: inline-block;
  position: relative;
  padding: 0;
  margin-right: 8px;
  span {
    width: 18px;
    height: 18px;
  }
`;
