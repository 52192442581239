import styled from "styled-components";
import { Text } from "~/components/elements";
import theme from "~/styles/theme";

export const TitleHolder = styled.div`
  text-align: center;
  padding: 0 16px;
  margin: 0 auto;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 735px;
  }
`;
export const TextHolder = styled.div`
  text-align: center;
  padding: 0 16px;
  margin: 0 auto;
  max-width: 350px;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 770px;
  }
`;

export const BubblesHolder = styled.div`
  margin: 0 auto 8px;
  max-width: 313px;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 560px;
    margin-bottom: 16px;
  }
`;

export const Bubble = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  color: ${theme.palette.secondary.main};
  font-size: 33.6px;
  line-height: 56px;
  text-align: center;
  font-family: ${theme.typography.secondary.bold};
  background: ${(props) =>
    props.active
      ? theme.palette.bubbles.active
      : theme.palette.bubbles.inactive};
  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 48px;
  }
`;
export const BubbleBar = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 71.5px;
  height: 4px;
  background: ${(props) =>
    props.active
      ? theme.palette.bubbles.active
      : theme.palette.bubbles.inactive};
  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 160px;
  }
`;

export const LabelHolder = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 320px;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 550px;
  }
`;

export const BubbleLable = styled(Text)`
  color: ${(props) =>
    props.active
      ? theme.palette.bubbles.active
      : theme.palette.bubbles.inactive};
`;
