import styled from "styled-components";
import theme from "~/styles/theme";

export const TitleHolder = styled.div`
  padding: 0 16px;
  width: 100%;
  h1 {
    color: #454545;
    text-align: center;
    span {
      color: #454545;
      font-size: 0.5em;
      vertical-align: super;
    }
  }
  .text {
    color: #454545;
    line-height: 130%;
    text-align: center;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 643px;
    margin: 0 auto;
    p {
      margin: 0 auto;
      max-width: 590px;
    }
  }
`;
