import styled from "styled-components";
import theme from "~/styles/theme";

export const CtTitle = styled.div`
  * {
    color: ${(props) =>
      props.primary
        ? theme.palette.primary.main
        : theme.palette.secondary.main};
    font-family: ${(props) =>
      props.emphasis
        ? theme.typography.secondary.bold
        : theme.typography.primary.regular};
  }

  &.title-1 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 64px;
      line-height: 72px;
    }
  }
  &.title-2 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 56px;
      line-height: 64px;
    }
  }
  &.title-3 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 48px;
      line-height: 56px;
    }
  }
  &.title-4 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 40px;
      line-height: 48px;
    }
  }
  &.title-5 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 32px;
      line-height: 40px;
    }
  }
  &.title-6 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;
