import React from "react";
import PropTypes from "prop-types";
import { P, Span, Strong, Anchor } from "./styles";

export default function Text({
  className,
  as,
  level,
  children,
  primary,
  emphasis,
  onClick,
  inset,
  id,
  underline,
}) {
  const existLevel = `${className} text-${level}` || `${className}`;

  function TextAsTag() {
    switch (as) {
      case "span":
        return (
          <Span
            className={existLevel}
            primary={primary}
            emphasis={emphasis}
            onClick={onClick}
            id={id}
          >
            {children}
          </Span>
        );
      case "strong":
        return (
          <Strong
            className={existLevel}
            primary={primary}
            emphasis={emphasis}
            onClick={onClick}
            id={id}
            underline={underline}
          >
            {children}
          </Strong>
        );
      case "anchor":
        return (
          <Anchor
            className={existLevel}
            primary={primary}
            emphasis={emphasis}
            onClick={onClick}
            id={id}
          >
            {children}
          </Anchor>
        );
      default:
        if (inset) {
          return (
            <P
              className={existLevel}
              primary={primary}
              emphasis={emphasis}
              onClick={onClick}
              dangerouslySetInnerHTML={{ __html: children }}
              id={id}
            ></P>
          );
        }
        return (
          <P
            className={existLevel}
            primary={primary}
            emphasis={emphasis}
            onClick={onClick}
            id={id}
          >
            {children}
          </P>
        );
    }
  }

  return <>{TextAsTag()}</>;
}

Text.defaultProps = {
  id: "",
  level: "1",
  as: "p",
  className: "",
  primary: false,
  emphasis: false,
  inset: false,
  onClick: () => {},
};

Text.propTypes = {
  level: PropTypes.oneOf(["1", "2", "3", "4", "5", "6", "action"]),
  id: PropTypes.string,
  className: PropTypes.string,
  as: PropTypes.string,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  emphasis: PropTypes.bool,
  inset: PropTypes.bool,
  underline: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
