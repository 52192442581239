import styled from "styled-components";
import theme from "~/styles/theme";

export const PageHolder = styled.div`
  padding: 0 16px;
  width: 100%;
  text-align: center;
`;

export const TitleHolder = styled.div`
  max-width: 240px;
  margin: 0 auto;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: unset;
  }
`;
export const TextHolder = styled.div`
  margin: 0 auto;
  p,
  span {
    color: ${theme.palette.black.main};
  }
  span {
    font-family: ${theme.typography.secondary.bold};
  }
  > div {
    position: relative;
    margin: 0 auto;
    display: block;
    span {
      color: inherit;
    }
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 560px;
  }
`;
export const EmailHolder = styled.div`
  border: 1px solid ${theme.palette.checkbox.border};
  border-radius: 6px;
  padding: 28px 0;
`;
export const ButtonHolder = styled.div`
  margin: 0 auto;
  button {
    width: 100%;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 242px;
  }
`;
