import { Grid } from "@material-ui/core";
import styled from "styled-components";
import theme from "~/styles/theme";

export const FormHolder = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  background-color: ${theme.palette.brand.primary.light};
  border-radius: 8px;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 24px;
    max-width: 590px;
  }

  p {
    color: ${theme.palette.black.main};

    span {
      cursor: pointer;
      font-family: ${theme.typography.secondary.bold};
      text-decoration: underline;
    }

    strong {
      font-family: ${theme.typography.secondary.bold};
    }
  }
`;

export const GridHolder = styled.div`
  margin: -8px;
`;

export const FormLimiter = styled.form`
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 560px;
    margin: 0 auto;
  }
`;

export const TermsHolder = styled.div`
  background: ${theme.palette.neutral.primary.key};
  border: 1px solid ${theme.palette.neutral.secondary.key};
  border-radius: 8px;
  position: relative;
  padding: 32px;
  margin-bottom: 24px;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 48px;
  }

  label {
    display: flex;

    > div {
      // width: 150%;
      margin-left: 26px;
    }
  }

  p {
    display: inline-block;
    vertical-align: top;

    a {
      color: ${theme.palette.black.main};
    }
  }
`;

export const CommunicationHolder = styled.div`
  label {
    display: flex;
  }
`;

export const SVGHolder = styled.div`
  width: 31px;
  height: 31px;
  position: absolute;
  top: -15px;
  left: 32px;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    left: 48px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ButtonsGrid = styled(Grid)`
  button {
    width: 100%;
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    button {
      width: auto;
    }
  }
`;
export const CaptchaHolder = styled.div`
  > div > div > div {
    margin: 0 auto;
  }
`;

export const PasswordRules = styled.div`
  text-align: left;
  padding: 48px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.black.lighter};
  background: ${theme.palette.disclaimer.bg};
  margin-bottom: 24px;

  p {
    font-size: 18px;
    color: ${theme.palette.black.main};
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  ul {
    li {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      font-weight: 400;
      padding: 4px 0;
      flex-direction: row;
      display: flex;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
        margin-right: 12px;
      }

      &.error {
        color: ${theme.palette.error.main};
      }
    }
  }
`;

export const NewButton = styled.div`
  button {
    min-width: 80px;
    height: 80px;
    // padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    // background: ${theme.palette.neutral.primary.key};
    // color: ${theme.palette.neutral.secondary.medium};

    &.animation-size{
      min-width: unset !important;
    }
  }
`;
