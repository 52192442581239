import styled, { css } from "styled-components";
import { SnackbarContent } from "@material-ui/core";
import theme from "~/styles/theme";

export const Snack = styled(SnackbarContent)`
font-size: 12px;
color: ${theme.palette.error.main}
position: relative;
min-width: auto;
box-shadow: none;
padding:0;
> div {
  padding: 0;
}

  ${(props) =>
    props.variant === "success" &&
    css`
      color: ${theme.palette.success.main};
      background: transparent;
    `}

    svg{
      margin-right: 5px;
    }
  ${(props) =>
    props.variant === "error" &&
    css`
      color: ${theme.palette.error.main};
      background: transparent;
    `}

    svg{
      margin-right: 5px;
    }
`;
