export const Types = {
  // Get Details
  GET_USER_DETAILS: "authentication/GET_USER_DETAILS",
  GET_DETAILS_SUCCESS: "authentication/GET_DETAILS_SUCCESS",
  GET_DETAILS_FAIL: "authentication/GET_DETAILS_FAIL",

  // Edit Personal
  SEND_EDIT_PERSONAL_DATA: "authentication/SEND_EDIT_PERSONAL_DATA",
  SEND_EDIT_PERSONAL_DATA_SUCCESS:
    "authentication/SEND_EDIT_PERSONAL_DATA_SUCCESS",
  SEND_EDIT_PERSONAL_DATA_FAIL: "authentication/SEND_EDIT_PERSONAL_DATA_FAIL",
  SET_EDIT_PERSONAL_DATA: "authentication/SET_EDIT_PERSONAL_DATA",

  // Edit Contact
  SEND_EDIT_CONTACT_DATA: "authentication/SEND_EDIT_CONTACT_DATA",
  SEND_EDIT_CONTACT_DATA_SUCCESS: "authentication/SEND_EDIT_CONTACT_DATA_SUCCESS",
  SEND_EDIT_CONTACT_DATA_FAIL: "authentication/SEND_EDIT_CONTACT_DATA_FAIL",
  SET_EDIT_CONTACT_DATA: "authentication/SET_EDIT_CONTACT_DATA",

  // update password
  SEND_PASSWORD: "authentication/SEND_PASSWORD",
  SEND_PASSWORD_SUCCESS: "authentication/SEND_PASSWORD_SUCCESS",
  SEND_PASSWORD_FAIL: "authentication/SEND_PASSWORD_FAIL",
  RESET_SEND_PASSWORD: "authentication/RESET_SEND_PASSWORD",

  // Resend Email
  RESEND_CONFIRM_NEW_EMAIL: "authentication/RESEND_CONFIRM_NEW_EMAIL",
  RESEND_CONFIRM_NEW_EMAIL_SUCCESS: "authentication/RESEND_CONFIRM_NEW_EMAIL_SUCCESS",
  RESEND_CONFIRM_NEW_EMAIL_FAIL: "authentication/RESEND_CONFIRM_NEW_EMAIL_FAIL",
  SET_RESEND_CONFIRM_NEW_EMAIL: "authentication/SET_RESEND_CONFIRM_NEW_EMAIL",

  // Confirm new email
  RESEND_CONFIRM_CODE_NEW_EMAIL: "authentication/RESEND_CONFIRM_CODE_NEW_EMAIL",
  RESEND_CONFIRM_CODE_NEW_EMAIL_SUCCESS: "authentication/RESEND_CONFIRM_CODE_NEW_EMAIL_SUCCESS",
  RESEND_CONFIRM_CODE_NEW_EMAIL_FAIL: "authentication/RESEND_CONFIRM_CODE_NEW_EMAIL_FAIL",
  SET_RESEND_CONFIRM_CODE_NEW_EMAIL: "authentication/SET_RESEND_CONFIRM_CODE_NEW_EMAIL",

  // update email
  SEND_UPDATE_EMAIL: "authentication/SEND_UPDATE_EMAIL",
  SEND_UPDATE_EMAIL_FAIL: "authentication/SEND_UPDATE_EMAIL_FAIL",
  SEND_UPDATE_EMAIL_SUCCESS: "authentication/SEND_UPDATE_EMAIL_SUCCESS",
  SET_UPDATE_EMAIL: "authentication/SET_UPDATE_EMAIL",
  SET_VALID_EMAIL: "authentication/SET_VALID_EMAIL",

  // update communication
  SEND_UPDATE_COMMUNICATION: "authentication/SEND_UPDATE_COMMUNICATION",
  SEND_UPDATE_COMMUNICATION_FAIL:
    "authentication/SEND_UPDATE_COMMUNICATION_FAIL",
  SEND_UPDATE_COMMUNICATION_SUCCESS:
    "authentication/SEND_UPDATE_COMMUNICATION_SUCCESS",
  SET_UPDATE_COMMUNICATION: "authentication/SET_UPDATE_COMMUNICATION",
};

export const Creators = {
  // Get Details
  getUserDetails: () => ({ type: Types.GET_USER_DETAILS }),
  getDetailsSuccess: (data) => ({
    type: Types.GET_DETAILS_SUCCESS,
    payload: data,
  }),
  getDetailsFail: (data) => ({ type: Types.GET_DETAILS_FAIL, payload: data }),

  // Edit Personal
  sendEditPersonalData: (data) => ({
    type: Types.SEND_EDIT_PERSONAL_DATA,
    payload: data,
  }),
  sendEditPersonalDataSuccess: (data) => ({
    type: Types.SEND_EDIT_PERSONAL_DATA_SUCCESS,
    payload: data,
  }),
  sendEditPersonalDataFail: (data) => ({
    type: Types.SEND_EDIT_PERSONAL_DATA_FAIL,
    payload: data,
  }),
  setEditPersonalData: () => ({ type: Types.SET_EDIT_PERSONAL_DATA }),

  // Edit Contact
  sendEditContactData: (data) => ({
    type: Types.SEND_EDIT_CONTACT_DATA,
    payload: data,
  }),
  sendEditContactDataSuccess: (data) => ({
    type: Types.SEND_EDIT_CONTACT_DATA_SUCCESS,
    payload: data,
  }),
  sendEditContactDataFail: (data) => ({
    type: Types.SEND_EDIT_CONTACT_DATA_FAIL,
    payload: data,
  }),
  setEditContactData: () => ({ type: Types.SET_EDIT_CONTACT_DATA }),

  // Send Password
  sendPassword: (data) => ({ type: Types.SEND_PASSWORD, payload: data }),
  sendPasswordSuccess: (data) => ({
    type: Types.SEND_PASSWORD_SUCCESS,
    payload: data,
  }),
  sendPasswordFail: (data) => ({
    type: Types.SEND_PASSWORD_FAIL,
    payload: data,
  }),
  resetSendPassword: () => ({ type: Types.RESET_SEND_PASSWORD }),

  // Resend New Email
  resendConfirmNewEmail: (data) => ({
    type: Types.RESEND_CONFIRM_NEW_EMAIL,
    payload: data,
  }),
  resendConfirmNewEmailSuccess: (data) => ({
    type: Types.RESEND_CONFIRM_NEW_EMAIL_SUCCESS,
    payload: data,
  }),
  resendConfirmNewEmailFail: (data) => ({
    type: Types.RESEND_CONFIRM_NEW_EMAIL_FAIL,
    payload: data,
  }),
  setResendConfirmNewEmail: () => ({
    type: Types.SET_RESEND_CONFIRM_NEW_EMAIL,
  }),

   // update email
  sendUpdateEmail: (data) => ({
    type: Types.SEND_UPDATE_EMAIL,
    payload: data,
  }),
  sendUpdateEmailSuccess: (data) => ({
    type: Types.SEND_UPDATE_EMAIL_SUCCESS,
    payload: data,
  }),
  sendUpdateEmailFail: (data) => ({
    type: Types.SEND_UPDATE_EMAIL_FAIL,
    payload: data,
  }),
  setUpdateEmail: () => ({
    type: Types.SET_UPDATE_EMAIL,
  }),

  // set email has validated
  setValidatedEmail: (data) => ({
    type: Types.SET_VALID_EMAIL,
    payload: data,
  }),

  // confirm new email
  sendConfirmEmailCode: (data) => ({
    type: Types.RESEND_CONFIRM_CODE_NEW_EMAIL,
    payload: data,
  }),
  sendConfirmEmailSuccess: (data) => ({
    type: Types.RESEND_CONFIRM_CODE_NEW_EMAIL_SUCCESS,
    payload: data,
  }),
  sendConfirmEmailFail: (data) => ({
    type: Types.RESEND_CONFIRM_CODE_NEW_EMAIL_FAIL,
    payload: data,
  }),
  setConfirmEmailCode: () => ({
    type: Types.SET_RESEND_CONFIRM_CODE_NEW_EMAIL,
  }),
  
  // update communication
  sendUpdateCommunication: (data) => ({
    type: Types.SEND_UPDATE_COMMUNICATION,
    payload: data,
  }),
  sendUpdateCommunicationSuccess: (data) => ({
    type: Types.SEND_UPDATE_COMMUNICATION_SUCCESS,
    payload: data,
  }),
  sendUpdateCommunicationFail: (data) => ({
    type: Types.SEND_UPDATE_COMMUNICATION_FAIL,
    payload: data,
  }),
  setUpdateCommunication: () => ({
    type: Types.SET_UPDATE_COMMUNICATION,
  }),
};
