import React from "react";
import ReactSVG from "react-svg";
import { Margin, Title, Text } from "~/components/elements";
import Email from "~/assets/img/icon/email.svg";
import { useSelector } from "react-redux";

export default function UpdateEmailSent({ mobile, closeModal }) {
  const newEmail = useSelector((state) => state.account.updateEmail.user.email);
  return (
    <>
      <Margin mb={2}>
        <ReactSVG wrapper="div" src={Email} />
      </Margin>
      <Margin mb={2}>
        <Title emphasis level="5" as="3">
          Solicitação de troca de e-mail enviada com sucesso
        </Title>
      </Margin>
      <Margin mb={4}>
        <Text primary level="2">
          Um e-mail de confirmação foi enviado para{" "}
          <strong>{newEmail}</strong>
        </Text>
      </Margin>
      <Margin mb={4}>
        <Text primary level="2">
          <strong>
            Por favor, entre no seu novo e-mail e clique em "trocar e-mail" para
            confirmar a atualização.
          </strong>{" "}
          O novo endereço de e-mail só será alterado na sua conta após a
          confirmação.
        </Text>
      </Margin>
    </>
  );
}
