import styled from "styled-components";
import Select from "react-select";
import theme from "~/styles/theme";

export const BoxInput = styled.div`
  label {
    color: ${theme.palette.black.main}
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    display: block;
    margin-bottom: 8px;
  }
`;

export const SelectInput = styled(Select)`
  box-sizing: content-box;
  border-radius: 6px;
  height: 56px;
  font-size: 16px;
  line-height: 24px;
  svg {
    color: ${theme.palette.secondary.main};
  }
`;
