import styled from "styled-components";
import { Grid } from "@material-ui/core";
import theme from "~/styles/theme";

export const FormHolder = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  text-align: center;
  p {
    color: ${theme.palette.black.main};
    span {
      cursor: pointer;
      font-family: ${theme.typography.secondary.bold};
      text-decoration: underline;
      text-transform: uppercase;
    }
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 560px;
    width: 100%;
    display: flex;
    justify-content: center;

    > div {
      max-width: 560px;
    }
    p {
      max-width: 560px;
    }
  }

  form {
    > div {
      > div {
        width: 100%;

        @media (min-width: ${theme.breakpoints.values.md}px) {
          width: calc(100% + 50px);
        }
      }
    }
  }
`;

export const FormLimiter = styled.form`
  button {
    width: 100%;
    max-width: 235px;
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 560px;
    margin: 0 auto;
  }
`;

export const PasswordRules = styled.div`
  text-align: left;
  padding: 48px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.black.lighter};
  background: ${theme.palette.disclaimer.bg};
  margin-bottom: 24px;

  p {
    font-size: 18px;
    color: ${theme.palette.black.main};
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  ul {
    li {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      font-weight: 400;
      padding: 4px 0;
      flex-direction: row;
      display: flex;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
        margin-right: 12px;
      }

      &.error {
        color: ${theme.palette.error.main};
      }
    }
  }
`;

export const ForgotLink = styled.div`
  text-align: left;
  a {
    font-size: 12px;
    color: ${theme.palette.black.main};
    font-family: ${theme.typography.secondary.bold};
    text-decoration: underline;
  }
`;

export const ButtonsGrid = styled(Grid)`
  button {
    margin-bottom: 16px;
    max-width: 100%;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    button {
      width: auto;
      max-width: 235px;
    }
  }
`;
