import produce from "immer";
import { Types as AccountTypes } from "./actions";

const INITIAL_STATE = {
  details: {
    loading: false,
    dadosPessoais: {
      Nome: "",
      DataNascimento: "",
      Sexo: "",
      Estado: "",
      Uf: "",
      AceitaReceberNoticias: "",
    },
    dadosContato: {
      TelCelular: "",
      Email: "",
      ParticipanteEmail: {},
    },
    necessidades: {},
    interesses: {},
    respostas: {},
    errorMessage: "",
  },
  editPersonal: {
    sending: false,
    success: false,
    message: "",
    showToaster: false,
  },
  editContact: {
    sending: false,
    success: false,
    message: "",
    showToaster: false,
  },
  password: {
    sending: false,
    errorMessage: "",
    success: false,
  },
  resendNewEmail: {
    sending: false,
    success: false,
    message: "",
    showToaster: false,
  },
  newEmail: {
    sending: false,
    success: false,
    message: "",
    email: "",
    emailValidated: true,
  },
  updateEmail: {
    sending: false,
    success: false,
    errorMessage: "",
    emailAlreadyExists: false,
    user: {
      email: "",
    },
  },
};

export default function account(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      // Get Details
      case AccountTypes.GET_USER_DETAILS: {
        draft.details.loading = true;
        draft.details.errorMessage = INITIAL_STATE.details.errorMessage;
        break;
      }
      case AccountTypes.GET_DETAILS_SUCCESS: {
        draft.details.loading = false;
        draft.details.dadosPessoais = action.payload.dadosPessoais;
        draft.details.dadosContato = action.payload.dadosContato;
        draft.details.necessidades = action.payload.Necessidades;
        draft.details.interesses = action.payload.Interesses;
        draft.details.respostas = action.payload.Respostas;
        break;
      }
      case AccountTypes.GET_DETAILS_FAIL: {
        draft.details.loading = false;
        draft.details.errorMessage = action.payload.msg;
        break;
      }

      // Edit Personal
      case AccountTypes.SEND_EDIT_PERSONAL_DATA: {
        draft.editPersonal.sending = true;
        draft.editPersonal.message = INITIAL_STATE.editPersonal.message;
        break;
      }
      case AccountTypes.SEND_EDIT_PERSONAL_DATA_SUCCESS:
      case AccountTypes.SEND_UPDATE_COMMUNICATION_FAIL: {
        draft.editPersonal.sending = false;
        draft.editPersonal.success = true;
        draft.editPersonal.message = action.payload.msg;
        draft.editPersonal.showToaster = true;
        break;
      }
      case AccountTypes.SEND_EDIT_PERSONAL_DATA_FAIL: {
        draft.editPersonal.sending = false;
        draft.editPersonal.success = false;
        draft.editPersonal.message = action.payload.msg;
        draft.editPersonal.showToaster = true;
        break;
      }
      case AccountTypes.SET_EDIT_PERSONAL_DATA: {
        draft.editPersonal = INITIAL_STATE.editPersonal;
        break;
      }

      // Edit Contact
      case AccountTypes.SEND_EDIT_CONTACT_DATA: {
        draft.editContact.sending = true;
        draft.editContact.message = INITIAL_STATE.editContact.message;
        break;
      }
      case AccountTypes.SEND_EDIT_CONTACT_DATA_SUCCESS: {
        draft.editContact.sending = false;
        draft.editContact.success = true;
        draft.editContact.message = action.payload.msg;
        draft.editContact.showToaster = true;
        break;
      }
      case AccountTypes.SEND_EDIT_CONTACT_DATA_FAIL: {
        draft.editContact.sending = false;
        draft.editContact.success = false;
        draft.editContact.message = action.payload.msg;
        draft.editContact.showToaster = true;
        break;
      }
      case AccountTypes.SET_EDIT_CONTACT_DATA: {
        draft.editContact = INITIAL_STATE.editContact;
        break;
      }

      case AccountTypes.SEND_PASSWORD: {
        draft.password.sending = true;
        draft.password.errorMessage = INITIAL_STATE.password.errorMessage;
        break;
      }
      case AccountTypes.SEND_PASSWORD_SUCCESS: {
        draft.password.sending = false;
        draft.password.success = true;
        break;
      }
      case AccountTypes.SEND_PASSWORD_FAIL: {
        draft.password.sending = false;
        draft.password.errorMessage = action.payload.msg;
        break;
      }
      case AccountTypes.RESET_SEND_PASSWORD: {
        draft.password = INITIAL_STATE.password;
        break;
      }

      // Resend Email
      case AccountTypes.RESEND_CONFIRM_NEW_EMAIL: {
        draft.resendNewEmail.sending = true;
        draft.resendNewEmail.showToaster =
          INITIAL_STATE.resendNewEmail.showToaster;
        break;
      }
      case AccountTypes.RESEND_CONFIRM_NEW_EMAIL_SUCCESS: {
        draft.resendNewEmail.sending = false;
        draft.resendNewEmail.success = true;
        draft.resendNewEmail.message = action.payload.msg;
        draft.resendNewEmail.showToaster = true;
        break;
      }
      case AccountTypes.RESEND_CONFIRM_NEW_EMAIL_FAIL: {
        draft.resendNewEmail.sending = false;
        draft.resendNewEmail.message = action.payload.msg;
        draft.resendNewEmail.showToaster = true;
        break;
      }
      case AccountTypes.SET_RESEND_CONFIRM_NEW_EMAIL: {
        draft.resendNewEmail = INITIAL_STATE.resendNewEmail;
        break;
      }

      // confirm code email
      case AccountTypes.RESEND_CONFIRM_CODE_NEW_EMAIL: {
        draft.newEmail.sending = true;
        break;
      }
      case AccountTypes.RESEND_CONFIRM_CODE_NEW_EMAIL_SUCCESS: {
        draft.newEmail.sending = false;
        draft.newEmail.success = true;
        draft.newEmail.message = action.payload.msg;
        draft.newEmail.email = action.payload.email;
        break;
      }
      case AccountTypes.RESEND_CONFIRM_CODE_NEW_EMAIL_FAIL: {
        draft.newEmail.sending = false;
        draft.newEmail.success = false;
        draft.newEmail.message = action.payload.msg;
        draft.newEmail.email = INITIAL_STATE.newEmail.email;
        break;
      }

      case AccountTypes.SET_RESEND_CONFIRM_CODE_NEW_EMAIL: {
        draft.newEmail = INITIAL_STATE.newEmail;
        break;
      }

      // update email
      case AccountTypes.SEND_UPDATE_EMAIL: {
        draft.updateEmail.user = INITIAL_STATE.updateEmail.user;
        draft.updateEmail.emailAlreadyExists =
          INITIAL_STATE.updateEmail.emailAlreadyExists;
        draft.updateEmail.sending = true;
        draft.updateEmail.updated = false;
        break;
      }

      case AccountTypes.SEND_UPDATE_EMAIL_SUCCESS: {
        draft.updateEmail.sending = INITIAL_STATE.updateEmail.sending;
        draft.updateEmail.success = action.payload.success;
        draft.updateEmail.updated = action.payload.success;
        draft.updateEmail.user.email = action.payload.email;

        draft.newEmail.emailValidated = false;
        break;
      }

      case AccountTypes.SET_VALID_EMAIL: {
        draft.details.dadosContato.ParticipanteEmail.Validado =
          action.payload.validated;
        break;
      }

      case AccountTypes.SEND_UPDATE_EMAIL_FAIL: {
        draft.updateEmail.sending = INITIAL_STATE.updateEmail.sending;
        draft.updateEmail.success = action.payload.success;
        draft.updateEmail.updated = action.payload.success;
        draft.updateEmail.emailAlreadyExists =
          action.payload.emailAlreadyExists;
        draft.updateEmail.errorMessage = action.payload.msg;
        break;
      }

      case AccountTypes.SET_UPDATE_EMAIL: {
        draft.updateEmail = INITIAL_STATE.updateEmail;
        break;
      }

      case AccountTypes.SEND_UPDATE_COMMUNICATION_SUCCESS: {
        draft.editPersonal.sending = false;
        draft.editPersonal.success = true;
        draft.editPersonal.message = action.payload.msg;
        break;
      }

      default:
    }
  });
}
