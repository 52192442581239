import React from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import {
  Margin,
  Title,
  Input,
  Button,
  Text,
  ErrorMessage,
  Loading,
} from "~/components/elements";
import * as S from "./styles";
import { schema } from "./validation";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AuthenticationActions } from "~/store/modules/authentication/actions";

export default function FormValidateLogin({ mobile }) {
  const dispatch = useDispatch();

  const errorMessage = useSelector(
    (state) => state.authentication.validateAuth.errorMessage
  );

  const sending = useSelector((state) => state.authentication.validateAuth.sending);

  const personalData = useSelector(
    (state) => state.account.details.dadosContato
  );

  const origin = process.env.REACT_APP_ORIGIN;

  return (
    <>
      <Formik
        initialValues={{ Senha: "" }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          dispatch(
            AuthenticationActions.sendValidateAuthentication({
              Email: personalData.Email,
              Senha: values.Senha,
              origin: origin
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setSubmitting,
          handleSubmit,
        }) => (
          <>
            <Margin mb={2}>
              <Title emphasis level="5" as="3">
                Digite sua senha
              </Title>
            </Margin>
            <Margin mb={4}>
              <Text primary level={mobile ? "2" : "1"}>
                Digite sua senha antes de alterar seu e-mail.
              </Text>
            </Margin>
            <Margin mb={mobile ? 4 : 3}>
              <Input
                label="Senha"
                placeholder="Senha"
                htmlFor="senhaFieldFormValidateLogin"
                name="Senha"
                type="password"
                value={values.Senha}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.Senha && touched.Senha && errors.Senha}
                valid={!errors.Senha && touched.Senha && !errors.Senha}
                // required
              />
            </Margin>
            <Margin mb={4}>
              <S.ForgotLink>
                <Link
                  to="/esqueci-minha-senha"
                  id="forgotLinkFormValidateLogin"
                >
                  Esqueci minha senha
                </Link>
              </S.ForgotLink>
            </Margin>

            <S.AddInfoButtonHolder>
              <Button
                primary
                onClick={() => handleSubmit(values, setSubmitting)}
                color={
                  !values.Senha || !!errors.Senha || sending
                    ? "disabled"
                    : "secondary"
                }
                disabled={!values.Senha || !!errors.Senha || sending}
                id="validateLoginForm"
              >
                {sending ? <Loading button /> : "CONFIRMAR"}
              </Button>
            </S.AddInfoButtonHolder>
          </>
        )}
      </Formik>
      {errorMessage && (
        <Margin mt={3} mb={3}>
          <ErrorMessage inset>{errorMessage}</ErrorMessage>
        </Margin>
      )}
    </>
  );
}
