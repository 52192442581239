import styled from "styled-components";
import theme from "~/styles/theme";
import { Snackbar } from "@material-ui/core";

export const Toast = styled(Snackbar)`
  width: 100%;
  max-width: 351px;
`;

export const Alert = styled.div`
  width: 100%;
  background: ${(props) =>
    props.success ? theme.palette.toast.success : theme.palette.toast.error};
  border: 1px solid ${(props) =>
    props.success ? theme.palette.toast.sucessBorder : theme.palette.toast.errorBorder};
  border-radius: 8px;
  height: 80px;
  font-size: 16px;
  color: ${theme.palette.primary.main};
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 16px;
    width: 16px;
  }
`;