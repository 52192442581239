import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { store } from "~/store";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        store.getState().authentication.auth.loginToken ? (
          <Component {...props} listing={rest.listing} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: rest.location.search
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
