import styled from "styled-components";
import theme from "~/styles/theme";

export const PageHolder = styled.div`
  padding: 0 16px;
  width: 100%;
  text-align: center;
`;

export const SadHolder = styled.div`
  width: 173px;
  height: 35px;
  margin: 0 auto;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const TitleHolder = styled.div`
  max-width: 420px;
  margin: 0 auto;
`;

export const ButtonHolder = styled.div`
  margin: 0 auto;
  button {
    width: 100%;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 368px;
  }
`;
