import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { DynamicBG, FormComplement } from "~/components/blocks";
import { Margin, Title, Text } from "~/components/elements";
import { scrollTop, parentScrollTop } from "~/utils/tools";
import isMobile from "~/hooks/isMobile";
import banner from "~/assets/img/coupleWithKid.jpg";
import * as S from "./styles";

export default function SignUpComplement() {
  const mobile = isMobile();
  const transition = {
    duration: 0.3,
    ease: [0.43, 0.13, 0.23, 0.96],
  };

  const animations = {
    exit: { y: "50%", opacity: 0, transition },
    enter: {
      y: "0%",
      opacity: 1,
      transition,
    },
  };

  useEffect(() => {
    scrollTop();
    parentScrollTop();
  }, []);

  return (
    <motion.div
      variants={animations}
      className="single"
      initial="exit"
      animate="enter"
      exit="exit"
    >
      <DynamicBG>
        <Margin mt={mobile ? 12 : 9} mb={8}>
          <Margin mb={2}>
            <S.Hero>
              <Margin mb={2}>
                <img className="banner" src={banner} alt="Banner" />
              </Margin>
              <div className="content">
                <Margin mb={2}>
                  <Title as="1" levelDesktop="6" level="6" emphasis>
                    Já estamos acabando!
                  </Title>
                </Margin>
                <Text level={mobile ? "2" : "1"} primary>
                  Preencha as últimas informações e{" "}
                  <strong>ganhe um cupom de 20% de desconto</strong> em produtos Johnson´s® Baby.
                </Text>
              </div>
            </S.Hero>
          </Margin>
          <FormComplement />
        </Margin>
      </DynamicBG>
    </motion.div>
  );
}
