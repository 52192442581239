import styled from "styled-components";
import theme from "~/styles/theme";

export const FormHolder = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  text-align: center;
  p {
    color: ${theme.palette.black.main};
    max-width: 440px;
    span {
      cursor: pointer;
      font-family: ${theme.typography.secondary.bold};
      text-decoration: underline;
    }
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 560px;
  }
`;

export const FormLimiter = styled.form`
  button {
    width: 100%;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 560px;
    margin: 0 auto;
  }
`;
export const ErrorText = styled.div`
  p {
    color: ${(props) =>
      props.success ? theme.palette.success.main : theme.palette.error.main};
  }
`;

export const SuccessMessage = styled.p`
  text-align: center;
  color: ${theme.palette.success.main} !important;
  margin: 0;
`;

export const ButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    @media (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 194px;
    }
  }
`;

export const TextHolder = styled.div`
  p {
    font-size: 16px;
    text-align: right;
    font-weight: 700;

    span {
      color: ${theme.palette.secondary.main};
    }
  }
`