import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <GoogleOAuthProvider clientId="143858440577-9h58kggjf7vnbmlkrmi4ague2v9ag6k8.apps.googleusercontent.com"> 
    <App />
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
