import styled from "styled-components";
import theme from "~/styles/theme";

export const TitleHolder = styled.div`
  padding: 0 16px;
  width: 100%;
  margin: 0 auto;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    text-align: center;
  }
  p {
    color: ${theme.palette.black.main};
    @media (min-width: ${theme.breakpoints.values.md}px) {
      max-width: ${props => props.success ? "400px" : "299px"};
      margin: 0 auto;
    }
  }
`;

export const TextContent = styled.div`
  text-align: justify;
  padding: 0 16px;
  max-width: 750px;
  margin: 0 auto;
  p {
    color: ${theme.palette.black.main};
  }
  ul {
    list-style: disc;
    margin-left: 48px;
    ul {
      list-style: circle;
      margin-left: 48px;
    }
  }
`;

export const ButtonHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 96px;
  width: 100%;
`