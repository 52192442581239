import { call, put, takeLatest } from "redux-saga/effects";
import apiNoAuth from "~/services/apiNoAuth";
import { Types as AuthenticationTypes } from "./actions";
import { urlEncode } from "~/utils/tools";
import { variables } from "~/utils/variables";
import MESSAGES from "~/utils/messages";

export function* sendAuthentication({ payload }) {
  try {
    payload.Device = navigator.userAgent;
    payload.Token = variables.promoToken;
    payload.ConnectHash = variables.connectHash;
    const values = urlEncode(payload);
    const { data } = yield call(apiNoAuth.post, `/engine/login/email`, values);
    if (data.sucesso) {
      yield put({
        type: AuthenticationTypes.SEND_AUTHENTICATION_SUCCESS,
        payload: { ...data, success: data.sucesso },
      });
    } else {
      yield put({
        type: AuthenticationTypes.SEND_AUTHENTICATION_FAIL,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: AuthenticationTypes.SEND_AUTHENTICATION_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendSocialAuthentication({ payload }) {
  try {
    payload.Device = navigator.userAgent;
    payload.Token = variables.promoToken;
    payload.ConnectHash = variables.connectHash;
    const values = urlEncode(payload);
    const { data } = yield call(apiNoAuth.post, `/engine/login/social`, values);
    if (data.sucesso) {
      yield put({
        type: AuthenticationTypes.SEND_SOCIAL_AUTHENTICATION_SUCCESS,
        payload: {
          ...data,
          success: data.sucesso,
          SocialGoogleId: payload.SocialGoogleId,
          SocialFacebookId: payload.SocialFacebookId,
        },
      });
      yield put({
        type: AuthenticationTypes.SEND_SOCIAL_AUTHENTICATION_INFO,
        payload: { ...data },
      });
    } else {
      yield put({
        type: AuthenticationTypes.SEND_SOCIAL_AUTHENTICATION_FAIL,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: AuthenticationTypes.SEND_SOCIAL_AUTHENTICATION_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendAuthenticationCode({ payload }) {
  try {
    payload.Device = navigator.userAgent;
    payload.Token = variables.promoToken;
    payload.ConnectHash = variables.connectHash;
    const values = urlEncode(payload);
    const { data } = yield call(
      apiNoAuth.post,
      `/engine/login/valida-codigo-login`,
      values
    );
    if (data.sucesso) {
      yield put({
        type: AuthenticationTypes.SEND_AUTHENTICATION_CODE_SUCCESS,
        payload: { ...data, success: data.sucesso },
      });
      yield put({
        type: AuthenticationTypes.SHOW_CODE_SUCCESS,
        payload: { showFormSignUp: true },
      });
    } else {
      yield put({
        type: AuthenticationTypes.SEND_AUTHENTICATION_CODE_FAIL,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: AuthenticationTypes.SEND_AUTHENTICATION_CODE_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendValidateAuthentication({ payload }) {
  try {
    payload.Device = navigator.userAgent;
    payload.Token = variables.promoToken;
    payload.ConnectHash = variables.connectHash;
    const values = urlEncode(payload);
    const { data } = yield call(apiNoAuth.post, `/engine/login/entrar`, values);
    if (data.sucesso) {
      yield put({
        type: AuthenticationTypes.SEND_VALIDATE_AUTHENTICATION_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: AuthenticationTypes.SEND_VALIDATE_AUTHENTICATION_FAIL,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: AuthenticationTypes.SEND_VALIDATE_AUTHENTICATION_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendForgotPassword({ payload }) {
  try {
    const values = urlEncode(payload);
    const { data } = yield call(
      apiNoAuth.post,
      "engine/participante/resetar-senha-engine",
      values
    );
    if (data.sucesso) {
      yield put({
        type: AuthenticationTypes.SEND_FORGOT_PASS_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: AuthenticationTypes.SEND_FORGOT_PASS_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: AuthenticationTypes.SEND_FORGOT_PASS_FAIL,
      payload: { sucesso: false, msg: err || MESSAGES.genericError },
    });
  }
}

export default function* saga() {
  yield takeLatest(AuthenticationTypes.SEND_AUTHENTICATION, sendAuthentication);
  yield takeLatest(
    AuthenticationTypes.SEND_SOCIAL_AUTHENTICATION,
    sendSocialAuthentication
  );
  yield takeLatest(
    AuthenticationTypes.SEND_AUTHENTICATION_CODE,
    sendAuthenticationCode
  );
  yield takeLatest(AuthenticationTypes.SEND_FORGOT_PASS, sendForgotPassword);
  yield takeLatest(
    AuthenticationTypes.SEND_VALIDATE_AUTHENTICATION,
    sendValidateAuthentication
  );
}
