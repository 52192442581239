import { all, call, put, select, takeLatest } from "redux-saga/effects";
import api from "~/services/api";
import apiNoAuth from "~/services/apiNoAuth";
import { Types as AccountTypes } from "./actions";
import { variables } from "~/utils/variables";
import MESSAGES from "~/utils/messages";
import { urlEncode } from "~/utils/tools";
import { toast } from "react-toastify";

function* generatePayload(payload) {
  const commonPayload = {
    Device: navigator.userAgent,
    Token: variables.promoToken,
    ConnectHash: variables.connectHash,
    LoginToken: yield select(
      (state) => state.authentication.auth.loginToken
    ),
    ParticipanteHash: yield select(
      (state) => state.authentication.auth.user.ParticipanteHash
    ),
  };

  return { ...commonPayload, ...payload };
}

export function* getUserDetails() {
  try {
    const payload = yield call(generatePayload);
    const payloadValues = urlEncode(payload);
    const { data } = yield call(
      api.post,
      "/engine/participante/detalhes",
      payloadValues
    );
    if (data.sucesso) {
      yield put({ type: AccountTypes.GET_DETAILS_SUCCESS, payload: data });
    } else {
      yield put({ type: AccountTypes.GET_DETAILS_FAIL, payload: data });
    }
  } catch (err) {
    yield put({
      type: AccountTypes.GET_DETAILS_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendEditPersonalData({ payload }) {
  try {
    const updatedPayload = yield call(generatePayload, payload);
    const payloadValues = urlEncode(updatedPayload);
    const { data } = yield call(
      api.post,
      "/engine/participante/editar/dados-pessoais",
      payloadValues
    );
    if (data.sucesso) {
      yield put({
        type: AccountTypes.SEND_EDIT_PERSONAL_DATA_SUCCESS,
        payload: data,
      });
      yield put({ type: AccountTypes.GET_USER_DETAILS });
    } else {
      yield put({
        type: AccountTypes.SEND_EDIT_PERSONAL_DATA_FAIL,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: AccountTypes.SEND_EDIT_PERSONAL_DATA_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendEditContactData({ payload }) {
  try {
    const updatedPayload = yield call(generatePayload, payload);
    const payloadValues = urlEncode(updatedPayload);
    const { data } = yield call(
      api.post,
      "/engine/participante/editar/dados-contato",
      payloadValues
    );
    if (data.sucesso) {
      yield put({
        type: AccountTypes.SEND_EDIT_CONTACT_DATA_SUCCESS,
        payload: data,
      });
      yield put({ type: AccountTypes.GET_USER_DETAILS });
    } else {
      yield put({
        type: AccountTypes.SEND_EDIT_CONTACT_DATA_FAIL,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: AccountTypes.SEND_EDIT_CONTACT_DATA_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendPassword({ payload }) {
  try {
    const updatedPayload = yield call(generatePayload, payload);
    const payloadValues = urlEncode(updatedPayload);
    const { data } = yield call(
      api.post,
      "/engine/participante/editar-senha",
      payloadValues
    );
    if (data.sucesso) {
      yield put({ type: AccountTypes.SEND_PASSWORD_SUCCESS, payload: data });
    } else {
      yield put({
        type: AccountTypes.SEND_PASSWORD_FAIL,
        payload: { msg: data.msg },
      });
    }
  } catch (err) {
    yield put({
      type: AccountTypes.SEND_PASSWORD_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* resendConfirmNewEmail({ payload }) {
  try {
    const updatedPayload = yield call(generatePayload, payload);
    const payloadValues = urlEncode(updatedPayload);
    const { data } = yield call(
      api.post,
      "engine/participante/reenviar-confirmacao-email",
      payloadValues
    );
    if (data.sucesso) {
      yield put({
        type: AccountTypes.RESEND_CONFIRM_NEW_EMAIL_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: AccountTypes.RESEND_CONFIRM_NEW_EMAIL_FAIL,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: AccountTypes.RESEND_CONFIRM_NEW_EMAIL_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendConfirmUpdateEmailCode({ payload }) {
  try {
    const updatedPayload = yield call(generatePayload, payload);
    const payloadValues = urlEncode(updatedPayload);
    const { data } = yield call(
      api.post,
      "engine/participante/confirmacao-email",
      payloadValues
    );
    if (data.sucesso) {
      yield put({
        type: AccountTypes.RESEND_CONFIRM_CODE_NEW_EMAIL_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: AccountTypes.RESEND_CONFIRM_CODE_NEW_EMAIL_FAIL,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: AccountTypes.RESEND_CONFIRM_CODE_NEW_EMAIL_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendUpdateEmail({ payload }) {
  try {
    const updatedPayload = yield call(generatePayload, payload);
    const values = urlEncode(updatedPayload);
    const { data } = yield call(
      apiNoAuth.post,
      `/engine/participante/editar-email`,
      values
    );
    if (data.sucesso) {
      yield put({
        type: AccountTypes.SEND_UPDATE_EMAIL_SUCCESS,
        payload: { email: payload.Email, success: data.sucesso },
      });
    } else {
      yield put({
        type: AccountTypes.SEND_UPDATE_EMAIL_FAIL,
        payload: {
          ...data,
          emailAlreadyExists:
            data.msg === "O E-mail informado já está Cadastrado.",
        },
      });
    }
  } catch (err) {
    yield put({
      type: AccountTypes.SEND_UPDATE_EMAIL_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendUpdateCommunication({ payload }) {
  try {
    const updatedPayload = yield call(generatePayload, payload);
    const values = urlEncode(updatedPayload);
    const { data } = yield call(
      apiNoAuth.post,
      `/engine/participante/editar/opt-out-in`,
      values
    );
    if (data.sucesso) {
      toast.success(data?.msg);
      yield put({
        type: AccountTypes.SEND_UPDATE_COMMUNICATION_SUCCESS,
        payload: data,
      });
    } else {
      toast.error(data?.msg);
      yield put({
        type: AccountTypes.SEND_UPDATE_COMMUNICATION_FAIL,
        payload: data,
      });
    }
  } catch (err) {
    toast.error(err || MESSAGES.genericError);
    yield put({
      type: AccountTypes.SEND_UPDATE_COMMUNICATION_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export default function* saga() {
  yield all([
    takeLatest(AccountTypes.GET_USER_DETAILS, getUserDetails),
    takeLatest(AccountTypes.SEND_EDIT_PERSONAL_DATA, sendEditPersonalData),
    takeLatest(AccountTypes.SEND_EDIT_CONTACT_DATA, sendEditContactData),
    takeLatest(AccountTypes.SEND_PASSWORD, sendPassword),
    takeLatest(AccountTypes.RESEND_CONFIRM_NEW_EMAIL, resendConfirmNewEmail),
    takeLatest(
      AccountTypes.RESEND_CONFIRM_CODE_NEW_EMAIL,
      sendConfirmUpdateEmailCode
    ),
    takeLatest(AccountTypes.SEND_UPDATE_EMAIL, sendUpdateEmail),
    takeLatest(AccountTypes.SEND_UPDATE_COMMUNICATION, sendUpdateCommunication),
  ]);
}
