import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field } from "formik";
import { Grid } from "@material-ui/core";
import {
  useLocation,
  // useHistory
} from "react-router-dom";

import {
  Title,
  Text,
  Margin,
  Input,
  Button,
  Select,
  Checkbox,
  ErrorMessage,
  Loading,
  Message,
} from "~/components/elements";
import * as S from "./styles";
import { schema } from "./validation";
import { Creators as SignUpActions } from "~/store/modules/signUp/actions";
import { phoneMask } from "~/utils/tools";
import { ufSelect } from "~/utils/variables";
import isMobile from "~/hooks/isMobile";

export default function FormSignUpComplement({
  userEmail,
  userEmailSocial,
  userGivenName,
  userFamilyName,
  userID,
}) {
  const location = useLocation();
  const mobile = isMobile();
  const [midiaParams, setMidiaParams] = useState({});
  const dispatch = useDispatch();
  const sending = useSelector((state) => state.signUp.sign.sending);
  const errorMessage = useSelector((state) => state.signUp.sign.errorMessage);
  const initialValues = {
    Nome: userID ? userGivenName : "",
    Sobrenome: userID ? userFamilyName : "",
    TelCelular: "",
    Estado: ufSelect[0],
    NotificacaoEmail: 0,
    NotificacaoWhatsApp: 0,
    FormSignUp: 0,
    AceitaReceberNoticias: 0,
    AceitaTermosLegais: 0,
    SocialGoogleId: "",
    SocialFacebookId: "",
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramObj = {};
    for (const [key, value] of queryParams.entries()) {
      paramObj[key] = value;
    }
    setMidiaParams(JSON.stringify(paramObj));
  }, [location]);

  const url = process.env.REACT_APP_HOST_URL;
  const origin = process.env.REACT_APP_ORIGIN;

  return (
    <S.FormHolder>
      <Margin mt={6} mb={mobile ? 3 : 4}>
        <Title as="2" level="5" className="title-email" primary emphasis>
          Tudo certo com seu e-mail!
        </Title>
        <Text className="text-email">
          Agora é só informar os dados abaixo para continuar com o cadastro. Não
          se esqueça de confirmar os termos de aceite.
        </Text>
        <Text className="subtext-email">
          Aviso de Privacidade: os dados pessoais que você fornecer abaixo
          poderão ser usados para ter acesso a conteúdos exclusivos, dicas de
          cuidados com seu bebê, promoções, lançamentos e produtos e serviços
          KENVUE. Você também declara que está ciente com o conteúdo da nossa{" "}
          <a href="https://www.johnsonsbaby.com.br/politica-de-privacidade">
            Política de Privacidade
          </a>{" "}
          e concorda com os nossos{" "}
          <a
            href="https://www.johnsonsbaby.com.br/termos-e-condicoes"
            target="_blank"
            rel="noopener noreferrer"
          >
            Termos legais
          </a>
          , inclusive com a possibilidade de seus dados pessoais serem
          transferidos para afiliadas da JNTL em outros países para a proteção
          de dados pessoais.
        </Text>
      </Margin>

      <Margin mb={3}>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            dispatch(SignUpActions.setSignUpData());
            const payloadValues = {
              ...values,
              Nome: userID
                ? `${userGivenName} ${userFamilyName}`
                : `${values.Nome} ${values.Sobrenome}`,
              Estado: values.Estado.value,
              url: url,
              origin: origin,
              midia: midiaParams,
              TipoValidacao: 2,
              email: userEmail || userEmailSocial,
              SocialGoogleId: userID,
              SocialFacebookId: userID,
              NotificacaoEmail:
                values.NotificacaoEmail && values.NotificacaoEmail.length > 0
                  ? true
                  : false,
              NotificacaoWhatsApp:
                values.NotificacaoWhatsApp &&
                values.NotificacaoWhatsApp.length > 0
                  ? true
                  : false,
              AceitaReceberNoticias:
                (values.NotificacaoEmail &&
                  values.NotificacaoEmail.length > 0) ||
                (values.NotificacaoWhatsApp &&
                  values.NotificacaoWhatsApp.length > 0)
                  ? true
                  : false,
            };
            delete payloadValues.Sobrenome;
            dispatch(SignUpActions.sendSignUpForm(payloadValues));
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValid,
            dirty,
          }) => (
            <S.FormLimiter onSubmit={handleSubmit}>
              <S.GridHolder>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Margin mb={mobile ? 3 : 2}>
                      <Input
                        label="Nome"
                        placeholder="Digite seu nome"
                        htmlFor="nameFieldFormSignup"
                        name="Nome"
                        value={values.Nome}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors.Nome && touched.Nome && errors.Nome}
                        valid={!errors.Nome && touched.Nome && !errors.Nome}
                        required
                      />
                    </Margin>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Margin mb={mobile ? 3 : 2}>
                      <Input
                        label="Sobrenome"
                        placeholder="Digite seu sobrenome"
                        htmlFor="nameFieldFormSignup"
                        name="Sobrenome"
                        value={values.Sobrenome}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={
                          errors.Sobrenome &&
                          touched.Sobrenome &&
                          errors.Sobrenome
                        }
                        valid={
                          !errors.Sobrenome &&
                          touched.Sobrenome &&
                          !errors.Sobrenome
                        }
                        required
                      />
                    </Margin>
                  </Grid>
                </Grid>
              </S.GridHolder>
              <S.GridHolder>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Margin mb={mobile ? 3 : 2}>
                      <Input
                        mask={phoneMask}
                        label="Celular"
                        placeholder="(00)00000-0000"
                        htmlFor="phoneFieldFormSignup"
                        name="TelCelular"
                        value={values.TelCelular}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={
                          errors.TelCelular &&
                          touched.TelCelular &&
                          errors.TelCelular
                        }
                        valid={
                          !errors.TelCelular &&
                          touched.TelCelular &&
                          !errors.TelCelular
                        }
                        required
                      />
                    </Margin>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Select
                      label="Estado"
                      name="Estado"
                      placeholder="UF"
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      value={values.Estado}
                      errors={errors.Estado && touched.Estado && errors.Estado}
                      valid={!errors.Estado && touched.Estado && !errors.Estado}
                      options={ufSelect}
                      required
                      id="stateFieldFormSignup"
                    />
                  </Grid>
                </Grid>
              </S.GridHolder>
              <S.CheckboxGroup>
                <Text level="3" className="title-checkbox">
                  Escolha como receber as novidades:
                </Text>
                <div className="contact-checkbox">
                  <Margin mb={2} mt={2} mr={2}>
                    <label>
                      <Field
                        component={Checkbox}
                        type="checkbox"
                        name="NotificacaoEmail"
                        id="notificacaoEmail"
                        htmlFor="notificacaoEmail"
                        value="1"
                      />
                      <Text level="3">E-mail</Text>
                    </label>
                  </Margin>

                  <Margin mb={2} mt={2}>
                    <label>
                      <Field
                        component={Checkbox}
                        type="checkbox"
                        name="NotificacaoWhatsApp"
                        id="notificacaoWhatsApp"
                        htmlFor="notificacaoWhatsApp"
                        value="1"
                      />
                      <Text level="3">WhatsApp</Text>
                    </label>
                  </Margin>
                </div>
                {/*<Margin mb={2}>
                  <label>
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      name="AceitaReceberNoticias"
                      id="aceitaReceberNoticias"
                      htmlFor="aceitaReceberNoticias"
                      value="1"
                    />
                    <Text level="3">
                      Quero receber comunicações de marketing sobre os produtos Johnson’s® Baby.
                    </Text>
                  </label>
                </Margin>*/}
                <Margin mb={6}>
                  <label>
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      name="AceitaTermosLegais"
                      value="1"
                      id="aceitaTermosLegais"
                      htmlFor="aceitaTermosLegais"
                    />
                    <Text level="3">
                      Estou de acordo com Aviso de Privacidade e com a{" "}
                      <a href="https://www.johnsonsbaby.com.br/politica-de-privacidade">
                        Política de Privacidade
                      </a>{" "}
                      da JNTL, bem como com os{" "}
                      <a
                        href="https://www.johnsonsbaby.com.br/termos-e-condicoes"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Termos legais
                      </a>{" "}
                      desta página.
                    </Text>
                    {errors.AceitaTermosLegais &&
                      errors.AceitaTermosLegais.length < 50 && (
                        <Message variant="error">
                          {" "}
                          {errors.AceitaTermosLegais}{" "}
                        </Message>
                      )}
                  </label>
                </Margin>
              </S.CheckboxGroup>
              <S.ButtonsGrid
                container
                justifyContent="flex-end"
                direction={mobile ? "column-reverse" : "row"}
              >
                <Margin mb={mobile ? 3 : 0}>
                  <Button
                    className="animation-size"
                    color={
                      !isValid || !dirty || sending ? "disabled" : "secondary"
                    }
                    type="submit"
                    disabled={!isValid || !dirty || sending}
                    id="nextButtonFormSignup"
                  >
                    {sending ? <Loading button /> : "CADASTRAR"}
                  </Button>
                </Margin>
              </S.ButtonsGrid>
              {errorMessage && (
                <Margin mt={mobile ? 3 : 5}>
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                </Margin>
              )}
            </S.FormLimiter>
          )}
        </Formik>
      </Margin>
    </S.FormHolder>
  );
}
