import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { DynamicBG } from "~/components/blocks";
import { Margin, Title, Text } from "~/components/elements";
import { scrollTop, parentScrollTop } from "~/utils/tools";
import isMobile from "~/hooks/isMobile";
import { Button } from "~/components/elements";
import banner from "~/assets/img/desenho_bebe.png";
import { useHistory } from "react-router-dom";
import ReactSVG from "react-svg";
import Arrow from "~/assets/img/icon/blueRightArrow.svg";
import { Creators as AccountActions } from "~/store/modules/account/actions";
import * as S from "./styles";

export default function SuccessComplement() {
  const dispatch = useDispatch();
  const mobile = isMobile();
  const history = useHistory();
  const transition = {
    duration: 0.3,
    ease: [0.43, 0.13, 0.23, 0.96],
  };

  const animations = {
    exit: { y: "50%", opacity: 0, transition },
    enter: {
      y: "0%",
      opacity: 1,
      transition,
    },
  };

  useEffect(() => {
    dispatch(AccountActions.getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    scrollTop();
    parentScrollTop();
  }, []);

  return (
    <>
      <motion.div
        variants={animations}
        className="single"
        initial="exit"
        animate="enter"
        exit="exit"
      >
        <DynamicBG>
          <Margin mt={mobile ? 12 : 9} mb={8}>
            <Margin mb={2}>
              <S.Hero>
                <Margin mb={2}>
                  <img className="banner" src={banner} alt="Banner" />
                </Margin>
                <S.Card>
                  <div className="content">
                    <Margin mb={2}>
                      <Title as="1" levelDesktop="6" level="6" emphasis>
                        <span role="img" aria-label="icon">
                          🎉
                        </span>{" "}
                        Pronto, seu desconto está a caminho!
                      </Title>
                    </Margin>
                    <Margin mb={1}>
                      <Text level={mobile ? "2" : "1"} primary>
                        Em breve, você receberá um e-mail com um cupom exclusivo
                        de 20% de desconto em produtos Johnson´s® Baby.
                      </Text>
                    </Margin>
                    <Text level={mobile ? "2" : "1"} primary>
                      Confira suas caixas de entrada e spam.
                    </Text>
                  </div>
                </S.Card>
              </S.Hero>
            </Margin>
            <S.TextHolder>
              <Margin mb={3}>
                <Text as="p">
                  <strong>Agora você faz parte do mundo KENVUE!</strong>
                </Text>
              </Margin>
              <Button
                className="animation-size"
                color={"secondary"}
                variant="outlined"
                id="goToLogin"
                onClick={() => history.push("/minha-conta")}
              >
                Acessar minha conta <ReactSVG src={Arrow} className="icon" />
              </Button>
            </S.TextHolder>
          </Margin>
        </DynamicBG>
      </motion.div>
    </>
  );
}
