import * as Yup from "yup";
import MESSAGE from "~/utils/messages";
import {
  checkHasNumber,
  checkHasString,
  checkHasSpecialCharacter,
} from "~/utils/tools";

export const schema = Yup.object().shape({
  Password: Yup.string().required(MESSAGE.requiredField),
  NewPassword: Yup.string()
    .required(MESSAGE.requiredField)
    .matches(
      // eslint-disable-next-line
      /^(?=.*\d)[A-Za-z0-9 _!@#$%^&*()~+`\-=\[\]{};':"\\|,.<>\/?]*$/,
      MESSAGE.passwordMatch
    )
    .min(8, MESSAGE.passwordMatch)
    .max(12, MESSAGE.passwordMatch)
    .test({
      name: "newPasswordNumber",
      message: MESSAGE.passwordMatch,
      test: (value) => value && checkHasNumber(value),
    })
    .test({
      name: "newPasswordString",
      message: MESSAGE.passwordMatch,
      test: (value) => value && checkHasString(value),
    })
    .test({
      name: "newPasswordSpecialCharacter",
      message: MESSAGE.passwordMatch,
      test: (value) => value && checkHasSpecialCharacter(value),
    }),
  ConfirmPassword: Yup.string()
    .required(MESSAGE.requiredField)
    .oneOf([Yup.ref("NewPassword"), null], MESSAGE.passwordMatch)
    .test({
      name: "newPasswordNumber",
      message: MESSAGE.passwordMatch,
      test: (value) => value && checkHasNumber(value),
    })
    .test({
      name: "newPasswordString",
      message: MESSAGE.passwordMatch,
      test: (value) => value && checkHasString(value),
    })
    .test({
      name: "newPasswordSpecialCharacter",
      message: MESSAGE.passwordMatch,
      test: (value) => value && checkHasSpecialCharacter(value),
    }),
});
