import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Grid } from "@material-ui/core";
import {
  // Margin,
  Input,
  Button,
  Loading,
} from "~/components/elements";
import * as S from "./styles";
import { phoneMask } from "~/utils/tools";
import { schema } from "./validation";
import { Creators as AccountActions } from "~/store/modules/account/actions";

export default function AccountContact({ mobile, contactData, setEdit, edit }) {
  const dispatch = useDispatch();
  const editContact = useSelector((state) => state.account.editContact);

  const { sending, success } = editContact;

  const initialValues = {
    TelCelular: contactData.TelCelular,
    Email: contactData.Email
  };

  useEffect(() => {
    dispatch(AccountActions.setEditContactData());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setEdit(0);
    }
  }, [success, setEdit]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        dispatch(AccountActions.sendEditContactData(values));
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
      }) => (
        <form onSubmit={handleSubmit}>
          <S.FormHolder>
            <Grid
              container
              justifyContent="space-between"
              alignContent="flex-start"
              alignItems="flex-end"
            >
              <S.LabelHolder>
                <Input
                  mask={phoneMask}
                  label="Telefone/Celular"
                  placeholder="(00)00000-0000"
                  htmlFor="phoneFieldAccountContact"
                  name="TelCelular"
                  value={values.TelCelular}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={
                    errors.TelCelular && touched.TelCelular && errors.TelCelular
                  }
                  valid={
                    !errors.TelCelular &&
                    touched.TelCelular &&
                    !errors.TelCelular
                  }
                />
              </S.LabelHolder>
              <S.SubmitButtonHolder>
                <Button
                  className="animation-size"
                  color={
                    edit !== 2 || !isValid || sending ? "disabled" : "secondary"
                  }
                  type="submit"
                  disabled={edit !== 2 || !isValid || sending}
                  id="saveButtonAccountContact"
                >
                  {sending ? <Loading button /> : "Salvar dados"}
                </Button>
              </S.SubmitButtonHolder>
            </Grid>
          </S.FormHolder>
        </form>
      )}
    </Formik>
  );
}
