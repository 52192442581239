import styled from "styled-components";

export const SocialBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  width: 100%;
  border-radius: 56px;
  border-width: 1px;
  border-color: #454545;
  background-color: #FFF;
  cursor: pointer;
  transition: background-color .2s;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: #454545;
    span {
      color: #FFF;
    }
  }

  span {
    color: #454545;
  }

  svg {
    margin-right: 8px;
  }
`;
