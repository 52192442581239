import styled from "styled-components";
import { FormControlLabel, Radio, Grid } from "@material-ui/core";
import theme from "~/styles/theme";

export const FormHolder = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0;
    max-width: 560px;
  }
`;

export const FormLimiter = styled.form`
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 560px;
    margin: 0 auto;
  }
`;

export const BlueFormLabel = styled(FormControlLabel)`
  color: ${theme.palette.primary.light};
  font-size: 18px;
  margin: 0 32px 0 0;
`;

export const BlueRadio = styled(Radio)`
  color: ${theme.palette.primary.light};
  width: 27px;
  height: 27px;
  margin-right: 8px;
  &.Mui-checked {
    color: ${theme.palette.primary.light};
  }
`;

export const SonsInfosHolder = styled.div`
  background: ${theme.palette.disclaimer.bg};
  border: 1px solid ${theme.palette.disclaimer.border};
  margin: 0 -16px;
  padding: 32px 16px;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 48px;
    border-radius: 32px;
    margin: 0 auto;
    h3 {
      max-width: 320px;
    }
    p {
      max-width: 350px;
    }
  }
`;
export const AddButtonHolder = styled.div`
  button {
    width: 100%;
    background: ${theme.palette.secondary.dark};
    border-color: ${theme.palette.secondary.dark};
    color: ${theme.palette.primary.main};
    &:hover {
      color: ${theme.palette.secondary.main};
      svg {
        g {
          stroke: ${theme.palette.secondary.main};
        }
      }
    }
    span {
      justify-content: flex-start;
    }
    svg {
      width: 21px;
      height: 24px;
      vertical-align: middle;
      margin-right: 53px;
    }
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    text-align: right;
    button {
      width: 232px;
      svg {
        margin-right: 11px;
      }
    }
  }
`;
export const SonGrid = styled(Grid)`
  border-top: 1px solid #979797;
  padding-top: 15px;
`;
export const SonCell = styled.div`
  width: 50%;
  label {
    display: block;
    font-size: 12px;
    color: ${theme.palette.black.main};
    line-height: 18px;
    margin-bottom: 13px;
  }
  p {
    display: block;
    font-family: ${theme.typography.secondary.bold};
    font-size: 14px;
    line-height: 18px;
    color: ${theme.palette.black.main};
    max-width: 104px;
    height: 36px;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    label {
      font-size: 14px;
      line-height: 18px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      max-width: 134px;
      height: 48px;
    }
  }
`;

export const SonButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
`;
export const SonButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-left: 40px;
  }
  span {
    font-family: ${theme.typography.secondary.bold};
    color: ${theme.palette.primary.light};
    font-size: 14px;
    line-height: 18px;
    margin-left: 8px;
  }
`;

export const TermsHolder = styled.div`
  background: ${theme.palette.disclaimer.bg};
  border: 1px solid ${theme.palette.disclaimer.border};
  border-radius: 32px;
  position: relative;
  padding: 32px;
  label {
    display: flex;
    > div {
      width: 150%;
      margin-left: 26px;
    }
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 48px;
  }
  p {
    color: ${theme.palette.black.main};
    max-width: 236px;
    display: inline-block;
    vertical-align: top;
    a {
      color: ${theme.palette.black.main};
    }
    @media (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 452px;
    }
  }
`;
export const SVGHolder = styled.div`
  width: 31px;
  height: 31px;
  position: absolute;
  top: -15px;
  left: 32px;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    left: 48px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const NextButtonHolder = styled.div`
  button {
    width: 100%;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    text-align: right;
    button {
      max-width: 177px;
    }
  }
`;

export const NavButtonsHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  button {
    width: 100%;
    &:last-child {
      margin-bottom: 24px;
    }
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    flex-direction: row;
    justify-content: space-between;
    button {
      max-width: 177px;
      &:last-child {
        margin: 0;
      }
    }
  }
`;

export const CheckBoxLabel = styled.label`
  cursor: pointer;
  padding: 24px;
  border: 1px solid ${(props) =>
    props.checked ? theme.palette.primary.light : theme.palette.secondary.dark};
  color: ${(props) =>
    props.checked ? theme.palette.secondary.main : theme.palette.primary.light};
  background: ${(props) =>
    props.checked ? theme.palette.primary.light : "transparent"}
  width: 100%;
  display: block;
  border-radius: 16px;
  p {
    display: inline-block;
    color: ${(props) =>
      props.checked
        ? theme.palette.secondary.main
        : theme.palette.primary.light};
    vertical-align: middle;
  }
  span {
    color: ${(props) =>
      props.checked
        ? theme.palette.secondary.main
        : theme.palette.primary.light};
    margin-right: 8px;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 36px 32px;
  }
`;

export const CodeFlyer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    flex-direction: row;
  }

  & > div {
    width: 100%;
    margin-bottom: 34px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  button {
    border-radius: 8px;
    width: 100%;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      width: auto;
    }
  }
`;

export const SuccessMessage = styled.span`
  font-size: 12px;
  color: ${theme.palette.black.light};
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 400;
`;
