import React from "react";
import PropTypes from "prop-types";
import { ButtonStyle } from "./styles";

export default function Button({
  id,
  disabled,
  type,
  color,
  children,
  variant,
  className,
  onClick,
  gtmAction,
  gtmValue,
}) {
  return (
    <ButtonStyle
      className={className}
      disabled={disabled}
      type={type}
      variant={variant}
      color={color}
      onClick={onClick}
      gtm-action={gtmAction}
      gtm-value={gtmValue}
      id={id}
    >
      {children}
    </ButtonStyle>
  );
}

Button.defaultProps = {
  id: "",
  type: "button",
  color: "primary",
  variant: "contained",
  disabled: undefined,
  className: "",
  onClick: () => {},
  gtmAction: "",
  gtmValue: "",
};

Button.propTypes = {
  id: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary", "disabled"]),
  variant: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  gtmAction: PropTypes.string,
  gtmValue: PropTypes.string,
};
