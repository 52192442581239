import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";
import checkSVG from "~/assets/img/icon/check.svg";
import errorSVG from "~/assets/img/icon/error.svg";

import * as S from "./styles";

export default function Toast({ open, message, success }) {
  const [opened, setOpened] = useState(open);

  useEffect(() => {
    setOpened(open);
  }, [open]);

  return (
    <S.Toast
      open={opened}
      autoHideDuration={6000}
      onClose={() => setOpened(false)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <S.Alert success={success}>
        {success ? <ReactSVG src={checkSVG} /> : <ReactSVG src={errorSVG} />}
        {message}
      </S.Alert>
    </S.Toast>
  );
}

Toast.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  success: PropTypes.bool,
};
