import styled from "styled-components";
import theme from "~/styles/theme";

export const ContentCard = styled.div`
  background: ${theme.palette.hero.bg};
  width: 100%;
  padding: 40px;
  border-radius: 8px;
  h3,
  p {
    color: ${theme.palette.black.main};
  }
`;

export const AccountCard = styled.div`
  width: 100%;
  border: 1px solid ${theme.palette.menu.border};
  border-radius: 8px;
  padding: 40px;
  margin-bottom: 32px;
  text-align: left;
  h3,
  p {
    color: ${theme.palette.primary.main};
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: 24px;
  }
`;

export const CardSeparator = styled.div`
  border-bottom: 2px solid ${theme.palette.menu.innerborder};
`;

export const CardLink = styled.div`
  cursor: pointer;

  p {
    margin-right: 8px;
  }

  strong {
    margin-right: 8px;
  }
`;

export const CardAbstract = styled.div`
  margin-top: 24px;
  flex-wrap: wrap;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 24px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 0;
      max-width: 175px;
      width: 100%;
      flex: auto;
    }

    span {
      font-size: 13px;
    }

    &:last-child {
      margin-top: 32px;
    }
  }
`;

export const TitleHolder = styled.div`
  text-align: center;
  h3 {
    color: ${theme.palette.primary.main};
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    text-align: left;
  }
`;

export const UpdatePasswordCard = styled.div`
  padding: 32px;
  background: ${theme.palette.menu.bg};
  border: 1px solid ${theme.palette.menu.border};
  border-radius: 16px;
  text-align: left;
  position: relative;
  width: 100%;
  margin-bottom: 32px;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 40px;
  }

  h3 {
    margin-bottom: 8px;
  }

  p {
    color: ${theme.palette.black.main};
  }
`;

export const UpdatePasswordActions = styled.div`
  @media (min-width: ${theme.breakpoints.values.md}px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
  }

  p {
    margin-bottom: 40px;
    color: ${theme.palette.primary.main};

    @media (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 305px;
    }
  }

  a {
    color: ${theme.palette.secondary.main};
    text-align: right;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-decoration: none;

    svg {
      margin-left: 8px;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      g {
        stroke: ${theme.palette.secondary.main};
      }
    }
  }
`;

export const CardContact = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 31px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      flex-direction: row;
    }

    &:nth-child(1) {
      border-bottom: 1px solid ${theme.palette.black.silver};
      padding-bottom: 30px;

      @media (min-width: ${theme.breakpoints.values.md}px) {
        padding-bottom: 0;
      }

      > div {
        @media (min-width: ${theme.breakpoints.values.md}px) {
          margin-bottom: 32px;
        }
      }
    }
  }
`;

export const CardLinkHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 32px;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    flex-direction: row;
    margin-top: 32px;
  }

  > div {
    &:nth-child(1) {
      margin-bottom: 31px;

      @media (min-width: ${theme.breakpoints.values.md}px) {
        margin-bottom: 0;
      }
    }
  }
`;

export const IconMargin = styled.span`
  margin-right: 8px;
`;

export const ContactUs = styled.div`
  margin-top: 24px;

  a {
    font-size: 18px;
    color: ${theme.palette.secondary.main};
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 700;
    text-decoration: none;
  }
`;

export const ResendEmail = styled.div`
  @media (min-width: ${theme.breakpoints.values.md}px) {
    margin-right: 24px;
  }
`;

export const Comunication = styled.div`
  width: 100%;
`;