import React from "react";
import { Formik } from "formik";
import {
  Margin,
  Title,
  Input,
  Button,
  Text,
  Loading,
  ErrorMessage,
} from "~/components/elements";
import * as S from "./styles";
import { schema } from "./validation";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AccountActions } from "~/store/modules/account/actions";

export default function FormUpdateEmail({ mobile }) {
  const dispatch = useDispatch();
  const sending = useSelector((state) => state.account.updateEmail.sending);

  const errorMessage = useSelector(
    (state) => state.account.updateEmail.errorMessage
  );

  const url = process.env.REACT_APP_HOST_URL;
  const origin = process.env.REACT_APP_ORIGIN;

  return (
    <>
      <Formik
        initialValues={{ Email: "", ConfirmaEmail: "" }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          dispatch(
            AccountActions.sendUpdateEmail({
              url: url,
              origin: origin,
              Email: values.Email.toLocaleLowerCase(),
              EmailNovamente: values.EmailNovamente.toLocaleLowerCase(),
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setSubmitting,
          handleSubmit,
        }) => (
          <>
            <Margin mb={2}>
              <Title emphasis level="5" as="3">
                Alterar e-mail
              </Title>
            </Margin>
            <Margin mb={4}>
              <Text primary level="2">
                Preencha os campos abaixo para alterar seu e-mail.
                <strong>
                  {" "}
                  Para alterar este dado, uma confirmação será necessária.
                </strong>
              </Text>
            </Margin>
            <Margin mb={mobile ? 4 : 3}>
              <Input
                label="Novo e-mail"
                placeholder="Novo e-mail"
                htmlFor="emailFieldFormUpdateEmail"
                name="Email"
                value={values.Email}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.Email && touched.Email && errors.Email}
                valid={!errors.Email && touched.Email && !errors.Email}
                successIcon
                // required
              />
            </Margin>
            <Margin mb={mobile ? 4 : 3}>
              <Input
                label="Repita o novo e-mail"
                placeholder="Repita o novo e-mail"
                htmlFor="confirmEmailFieldFormUpdateEmail"
                name="EmailNovamente"
                value={values.EmailNovamente}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={
                  errors.EmailNovamente &&
                  touched.EmailNovamente &&
                  errors.EmailNovamente
                }
                valid={
                  !errors.EmailNovamente &&
                  touched.EmailNovamente &&
                  !errors.EmailNovamente
                }
                successIcon
                // required
              />
            </Margin>

            <S.AddInfoButtonHolder>
              <Button
                primary
                onClick={() => handleSubmit(values, setSubmitting)}
                color={
                  sending ||
                  !values.Email ||
                  !values.EmailNovamente ||
                  !!errors.Email ||
                  !!errors.EmailNovamente
                    ? "disabled"
                    : "secondary"
                }
                disabled={
                  sending ||
                  !values.Email ||
                  !values.EmailNovamente ||
                  !!errors.Email ||
                  !!errors.EmailNovamente
                }
                id="updateEmailForm"
              >
                {sending ? <Loading button /> : "ALTERAR E-MAIL"}
              </Button>
            </S.AddInfoButtonHolder>
          </>
        )}
      </Formik>
      {errorMessage && (
        <Margin mt={3} mb={3}>
          <ErrorMessage inset>{errorMessage}</ErrorMessage>
        </Margin>
      )}
    </>
  );
}
