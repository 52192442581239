import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSVG from "react-svg";
import { Grid } from "@material-ui/core";
import {
  AccountMain,
  // AccountPersonal,
  // AccountContact,
} from "~/components/blocks";
import { Title, Margin, Text, Loading } from "~/components/elements";
import * as S from "./styles";
import ExitSVG from "~/assets/img/icon/sair.svg";
import Person from "~/assets/img/icon/person.svg";
import IconRight from "~/assets/img/icon/iconRight.svg";

import { Creators as AuthenticationActions } from "~/store/modules/authentication/actions";
import isMobile from "~/hooks/isMobile";
import history from "~/routes/history";

export default function AccountContent({
  handleModal,
  resendConfirmNewEmail,
  emailValidated,
}) {
  const [selected, setSelected] = useState(0);
  const [edit, setEdit] = useState(0);
  const loading = useSelector((state) => state.account.details.loading);
  const personalData = useSelector(
    (state) => state.account.details.dadosPessoais
  );
  const contactData = useSelector(
    (state) => state.account.details.dadosContato
  );
  const completedSignUp = useSelector(
    (state) => state.authentication.auth.user.cadastroCompleto
  );
  const mobile = isMobile();
  const dispatch = useDispatch();
  const menuClick = (selection) => {
    setSelected(selection);
    setEdit(0);
  };
  const editClick = (selection) => {
    setSelected(selection);
    setEdit(selection);
  };

  return (
    <>
      <S.TitleHolder>
        <Margin mt={mobile ? 6 : 12} mb={8}>
          <Title as="1" level="5" levelDesktop="3" emphasis>
            Minha conta KENVUE
          </Title>
        </Margin>
      </S.TitleHolder>
      <S.ContentHolder container justifyContent="space-between">
        <S.NavbarHolder>
          <Margin mb={4}>
            <S.NameHolder>
              <Title as="5" level="6" levelDesktop="6" emphasis>
                <ReactSVG wrapper="svg" src={Person} />
                Olá, {personalData.Nome}
              </Title>
            </S.NameHolder>

            <S.AccountMenu>
              <S.MenuItem
                selected={selected === 0}
                onClick={() => menuClick(0)}
                id="accountPersonalButton"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignContent="center"
                >
                  <Text level="1" emphasis={selected === 0}>
                    Meus dados
                  </Text>
                  <ReactSVG src={IconRight} />
                </Grid>
              </S.MenuItem>
              <S.MenuItem
                onClick={() => {
                  dispatch(AuthenticationActions.purgeAuthentication());
                  history.push("/");
                }}
                id="accountLogoutButton"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignContent="center"
                >
                  <Text level="1">Sair</Text>
                  <ReactSVG src={ExitSVG} />
                </Grid>
              </S.MenuItem>
            </S.AccountMenu>
          </Margin>
        </S.NavbarHolder>
        <S.CardsHolder>
          {loading ? (
            <Margin>
              <Loading />
            </Margin>
          ) : (
            <Margin>
              <>
                <Margin mb={3}>
                  <Title level="6" primary>
                    Seus dados
                  </Title>
                </Margin>
                <AccountMain
                  mobile={mobile}
                  personalData={personalData}
                  contactData={contactData}
                  editClick={editClick}
                  history={history}
                  completedSignUp={completedSignUp}
                  handleModal={handleModal}
                  resendConfirmNewEmail={resendConfirmNewEmail}
                  emailValidated={emailValidated}
                  setEdit={setEdit}
                  menuClick={menuClick}
                  edit={edit}
                />
              </>
            </Margin>
          )}
        </S.CardsHolder>
      </S.ContentHolder>
    </>
  );
}
