import * as Yup from "yup";
import MESSAGE from "~/utils/messages";
import { isValid, parse, differenceInYears } from "date-fns";

export const schema = Yup.object().shape({
  nameTemp: Yup.string()
    .matches(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+(?:\s[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+)+$/u,
      MESSAGE.invalidNameChild
    )
    .required(MESSAGE.requiredField),
  birthTemp: Yup.mixed()
  .test({
    name: "birthDate",
    message: MESSAGE.invalidDate,
    test: (value) =>
      value &&
      isValid(parse(value, "dd/MM/yyyy", new Date()))
  })
  .test({
    name: "birthDate",
    message: MESSAGE.legallyOlder,
    test: (value) =>
      differenceInYears(new Date(), parse(value, "dd/MM/yyyy", new Date())) < 18,
  }),
});
