import styled from "styled-components";
import theme from "~/styles/theme";

export const Hero = styled.div`
  width: 100%;
  background-color: ${theme.palette.primary.dark};
  border-radius: 8px;
  text-align: center;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 590px;
    margin: 0 auto;

    p {
      margin: 0 auto;
      max-width: 542px;
    }
  }

  h1 {
    color: ${theme.palette.secondary.light};
  }

  p {
    color: ${theme.palette.secondary.light};
  }

  .banner {
    width: 100%;
    height: auto;
  }

  .content {
    padding: 16px;
    
    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 24px;
    }
  }
`;
