export const Types = {
  // Step
  SET_REGISTER_STEP: "promo/SET_REGISTER_STEP",

  // Nurseries
  GET_NURSERIES: "promo/GET_NURSERIES",
  GET_NURSERIES_SUCCESS: "promo/GET_NURSERIES_SUCCESS",
  GET_NURSERIES_FAIL: "promo/GET_NURSERIES_FAIL",

  // Intrests
  GET_INTERESTS: "promo/GET_INTERESTS",
  GET_INTERESTS_SUCCESS: "promo/GET_INTERESTS_SUCCESS",
  GET_INTERESTS_FAIL: "promo/GET_INTERESTS_FAIL",

  // Send Register
  SEND_REGISTER: "promo/SEND_REGISTER",
  SEND_REGISTER_SUCCESS: "promo/SEND_REGISTER_SUCCESS",
  SEND_REGISTER_FAIL: "promo/SEND_REGISTER_FAIL",
  RESET_SEND_REGISTER: "promo/RESET_SEND_REGISTER",

  // validate coupon
  SEND_COUPON: "promo/SEND_COUPON",
  SEND_COUPON_SUCCESS: "promo/SEND_COUPON_SUCCESS",
  SEND_COUPON_FAIL: "promo/SEND_COUPON_FAIL",

  // Products & Tips
  GET_PAT: "promo/GET_PAT",
  GET_PAT_SUCCESS: "promo/GET_PAT_SUCCESS",
  GET_PAT_FAIL: "promo/GET_PAT_FAIL",

  // Validate user cpf
  GET_EXISTING_USER: "promo/GET_EXISTING_USER",
  GET_EXISTING_USER_SUCCESS: "promo/GET_EXISTING_USER_SUCCESS",
  GET_EXISTING_USER_FAIL: "promo/GET_EXISTING_USER_FAIL",
};

export const Creators = {
  // Step
  setRegisterStep: (data) => ({
    type: Types.SET_REGISTER_STEP,
    payload: data,
  }),

  // Nurseries
  getNurseries: () => ({ type: Types.GET_NURSERIES }),
  getNurseriesSuccess: (data) => ({
    type: Types.GET_NURSERIES_SUCCESS,
    payload: data,
  }),
  getNurseriesFail: (data) => ({
    type: Types.GET_NURSERIES_FAIL,
    payload: data,
  }),

  // Interests
  getInterests: () => ({ type: Types.GET_INTERESTS }),
  getInterestsSuccess: (data) => ({
    type: Types.GET_INTERESTS_SUCCESS,
    payload: data,
  }),
  getInterestsFail: (data) => ({
    type: Types.GET_INTERESTS_FAIL,
    payload: data,
  }),

  // Send Register
  sendRegister: (data) => ({ type: Types.SEND_REGISTER, payload: data }),
  sendRegisterSuccess: (data) => ({
    type: Types.SEND_REGISTER_SUCCESS,
    payload: data,
  }),
  sendRegisterFail: (data) => ({
    type: Types.SEND_REGISTER_FAIL,
    payload: data,
  }),
  resetSendRegister: () => ({ type: Types.RESET_SEND_REGISTER }),

  // Products & Tips
  getPAT: () => ({ type: Types.GET_PAT }),
  getPATSuccess: (data) => ({ type: Types.GET_PAT_SUCCESS, payload: data }),
  getPATFail: () => ({ type: Types.GET_PAT_FAIL }),

  // sendcoupon
  sendCoupon: (data) => ({ type: Types.SEND_COUPON, payload: data }),
  sendCouponFail: (data) => ({ type: Types.SEND_COUPON_FAIL, payload: data }),
  sendCouponSuccess: (data) => ({
    type: Types.SEND_COUPON_SUCCESS,
    payload: data,
  }),

  getExistingUser: (data) => ({ type: Types.GET_EXISTING_USER, payload: data }),
  getExisingUserFail: (data) => ({
    type: Types.GET_EXISTING_USER_FAIL,
    payload: data,
  }),
  getExistingUserSuccess: (data) => ({
    type: Types.GET_EXISTING_USER_SUCCESS,
    payload: data,
  }),
};
