import styled from "styled-components";

export const AddInfoButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    width: 100%;
    max-width: 250px;
  }
`;
