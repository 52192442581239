import * as Yup from "yup";
import MESSAGE from "~/utils/messages";
import { phoneValidation } from "~/utils/tools";

export const schema = Yup.object().shape({
  TelCelular: Yup.mixed().test({
    name: "phone",
    message: MESSAGE.invalidPhone,
    test: (value) => {
      if (value) {
        return phoneValidation(value);
      }
      return true;
    },
  }),
});
