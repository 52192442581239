import React from "react";
import { useDispatch } from "react-redux";
import ReactSVG from "react-svg";
import { Margin, Title, Button, Text } from "~/components/elements";
import Alert from "~/assets/img/icon/alert.svg";
import { Creators as AccountActions } from "~/store/modules/account/actions";

import * as S from "./styles";

export default function EmailRegistered({ mobile, handleModal }) {
  const dispatch = useDispatch();
  
  return (
    <>
      <Margin mb={2}>
        <ReactSVG wrapper="div" src={Alert} />
      </Margin>
      <Margin mb={2}>
        <Title emphasis level="5" as="3">
          E-mail já cadastrado
        </Title>
      </Margin>
      <Margin mb={4}>
        <Text primary level={mobile ? "2" : "1"}>
          O e-mail informado já existe em nosso sistema. <br />
          Insira um novo e-mail.
        </Text>
      </Margin>

      <S.AddInfoButtonHolder>
        <Button
          primary
          onClick={() => {
            dispatch(AccountActions.setUpdateEmail());
            handleModal("FormUpdateEmail")
          }}
          color="secondary"
          id="btnUpdateEmailSent"
        >
          INSERIR NOVO E-MAIL
        </Button>
      </S.AddInfoButtonHolder>
    </>
  );
}
