import * as Yup from "yup";
import MESSAGE from "~/utils/messages";
import { isValid, parse, differenceInYears } from "date-fns";

export const schema = Yup.object().shape({
  Nome: Yup.string()
    .matches(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+(?:\s[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+)+$/u,
      MESSAGE.invalidName
    )
    .required(MESSAGE.requiredField),
  DataNascimento: Yup.mixed()
    .required(MESSAGE.requiredField)
    .test({
      name: "birthDate",
      message: MESSAGE.invalidDate,
      test: (value) => value && isValid(parse(value, "dd/MM/yyyy", new Date())),
    })
    .test({
      name: "birthDate",
      message: MESSAGE.legallyYounger,
      test: (value) =>
        differenceInYears(new Date(), parse(value, "dd/MM/yyyy", new Date())) >=
        18,
    }),
  Sexo: Yup.object().shape({
    label: Yup.string().required(MESSAGE.requiredSelect),
    value: Yup.string().required(MESSAGE.requiredSelect),
    id: Yup.number().required(MESSAGE.requiredSelect),
  }),
  Estado: Yup.object().shape({
    label: Yup.string().required(MESSAGE.requiredSelect),
    value: Yup.string().required(MESSAGE.requiredSelect),
    id: Yup.number().required(MESSAGE.requiredSelect),
  }),
});
