import React, { useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
// import GuestRoute from "./guestRoute";
import PrivateRoute from "./privateRoute";
import { AnimatePresence } from "framer-motion";

import * as P from "~/pages";

const Routes = () => {
  const history = useHistory();
  const location = useLocation();
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const register = queryParams.get("cadastrar");

    const paramObj = {};
    for (const [key, value] of queryParams.entries()) {
      paramObj[key] = value;
    }

    if (register === "true") {
      history.push({
        pathname: "/",
        search: location.search,
      });
    }
    //eslint-disable-next-line
  }, [history]);

  return (
    <Route
      render={({ location }) => (
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname} >
            <Route exact path="/" component={P.SignUp} />
            <Route exact path="/login" component={P.Login} />
            <PrivateRoute exact path="/complemento" component={P.SignUpComplement} />
            <PrivateRoute exact path="/complemento-sucesso" component={P.SuccessComplement} />
            <PrivateRoute exact path="/minha-conta" component={P.MyAccount} />
            <Route component={P.Error404} />
            {/* <Route exact path="/esqueci-minha-senha" component={P.ForgotPass} /> */}
            {/* <GuestRoute exact path="/verifique-seu-email" component={P.VerifyEmail}/> */}
            {/* <GuestRoute exact path="/verifique-seu-novo-email/:code" component={P.VerifyNewEmail}/> */}
            {/* <GuestRoute exact path="/cadastro-validado/:code" component={P.VerifiedEmail}/> */}
            {/* <PrivateRoute exact path="/atualizar-senha" component={P.UpdatePassword}/> */}
          </Switch>
        </AnimatePresence>
      )}
    />
  );
};

export default Routes;
