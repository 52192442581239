import styled from 'styled-components';
import theme from '~/styles/theme';

export const MessageHolder = styled.div`
    background: ${theme.palette.errorMessage.bg};
    border: 1px solid ${theme.palette.errorMessage.border};
    color: ${theme.palette.errorMessage.color};
    padding: 32px;
    border-radius: 32px;
    p {
        color: ${theme.palette.errorMessage.color}!important;
    }
    @media (min-width: ${theme.breakpoints.values.md}px) {
        text-align: center;
    }
`;