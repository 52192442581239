import styled from "styled-components";
import { Grid } from "@material-ui/core";
import theme from "~/styles/theme";

export const ContentHolder = styled.div`
  padding: 0 16px;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

export const TextHolder = styled.div`
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 440px;
  }

  &.fullwidth {
    max-width: 752px;
  }
`;

export const AccountGrid = styled(Grid)`
  padding: 32px;
  background: ${theme.palette.disclaimer.bg};
  border: 1px solid ${theme.palette.disclaimer.border};
  border-radius: 32px;
  button {
    width: 100%;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 72px 96px;
    h4 {
      max-width: 470px;
    }
    button {
      max-width: 235px;
    }
  }
`;

export const ProductCard = styled.div`
  width: 272px;
  margin: 0 8px;
  > div {
    min-height: 430px;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 368px;
    > div {
      min-height: 470px;
    }
  }
`;

export const ProductImgHolder = styled.div`
  background: ${(props) =>
    props.odd ? theme.palette.tertiary.main : theme.palette.primary.light};
    width: 272px
    height: 200px;
    border-radius: 32px;
    overflow: hidden;
    text-align: center;
    img {
      height: 100%;
      margin: 0 auto;
    }
    @media (min-width: ${theme.breakpoints.values.md}px) {
      width: 368px;
      height: 271px;
    }
`;

export const TipCard = styled.div`
  text-align: left;
  border-radius: 32px;
  width: 272px;
  min-height: 312px;
  padding: 32px;
  margin: 0 8px;
  background: ${(props) =>
    props.odd ? theme.palette.tertiary.main : theme.palette.primary.light};
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 48px;
    width: 557px;
    min-height: 271px;
    p {
      max-width: 320px;
    }
  }
`;

export const CardLink = styled.div`
  cursor: pointer;
  a {
    text-transform: uppercase;
    text-decoration: none;
    p {
      margin-right: 8px;
    }
    svg {
      width: 14px;
      height: 13px;
    }
    * {
      display: inline-block;
    }
  }
`;

export const CouponInformation = styled.div`
  background: ${theme.palette.hero.bg};
  border-radius: 32px;
  padding: 32px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 64px;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    display: flex;
    padding: 44px 96px;
  }
`;

export const CouponCode = styled.div`
  .text-1 {
    font-size: 40px;
    color: ${theme.palette.primary.light};;
    letter-spacing: 0;
    line-height: 56px;
    font-weight: 700;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 32px;
    }

    span {
      margin-right: 16px;
    }

    img {
      cursor: pointer;
    }
  }

  .text-5 {
    font-size: 24px;
    color: ${theme.palette.black.main};;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
  }
`

export const GoToWebSite = styled.div`
    max-width: 272px;
    width: 100%;

    button {
      width: 100%;
    }
`;

export const SuccessMessage = styled.span`
  font-size: 12px;
  color: ${theme.palette.black.light};
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 400;
`;