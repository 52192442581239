import React, { useEffect, useState } from "react";
import { FormCode } from "~/components/blocks";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Formik } from "formik";
import axios from "axios";

import {
  Title,
  Text,
  Margin,
  Input,
  Button,
  Loading,
  ErrorMessage,
  SocialButton,
} from "~/components/elements";
import { schema } from "./validation";
import { Creators as AuthenticationActions } from "~/store/modules/authentication/actions";
import isMobile from "~/hooks/isMobile";

import { useGoogleOneTapLogin, useGoogleLogin } from "@react-oauth/google";

import * as S from "./styles";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

export default function FormLogin({ participanteHash }) {
  const mobile = isMobile();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showValidationCode, setShowValidationCode] = useState(null);
  const loginToken = useSelector(
    (state) => state.authentication.auth.loginToken
  );
  const interesses = useSelector((state) => state.account?.details?.interesses);
  const respostas = useSelector((state) => state.account?.details?.respostas);
  const sending = useSelector((state) => state.authentication.auth.sending);
  const url = process.env.REACT_APP_HOST_URL;
  const origin = process.env.REACT_APP_ORIGIN;
  const [midiaParams, setMidiaParams] = useState({});
  const initialValues = {
    Email: "",
    Senha: "",
  };
  const emailValidated = useSelector(
    (state) => state.authentication.auth.emailValidated
  );
  const completeSignUp = useSelector(
    (state) => state.authentication.auth.user.cadastroCompleto
  );
  const errorMessage = useSelector(
    (state) => state.authentication.auth.errorMessage
  );
  const showMsg = useSelector((state) => state.authentication.auth.showMsg);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    dispatch(AuthenticationActions.setAuthenticationMessage());
    if (loginToken || emailValidated) {
      history.push("/minha-conta");
    }
    return () => {
      dispatch(AuthenticationActions.setAuthenticationMessage());
    };
    //eslint-disable-next-line
  }, [dispatch, loginToken, completeSignUp]);

  useEffect(() => {
    if (interesses && loginToken) {
      history.push("/minha-conta");
    }

    if ((!interesses || !respostas) && loginToken) {
      history.push("/complemento");
    }
    //eslint-disable-next-line
  }, [loginToken, interesses, respostas]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramObj = {};
    for (const [key, value] of queryParams.entries()) {
      paramObj[key] = value;
    }
    setMidiaParams(JSON.stringify(paramObj));
  }, [location]);

  const socialLoginMessage = async (response) => {
    let userInfo = {};
    if (response.access_token) {
      userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${response.access_token}` },
        })
        .then((res) => res.data)
        .catch((error) => failureMessage(error));
    } else {
      userInfo = jwt_decode(response?.credential);
    }
    handleAuthentication(
      userInfo?.email,
      userInfo?.sub,
      userInfo?.email_verified,
      "google"
    );
  };

  const handleAuthentication = (email, userID, emailValidated, socialMedia) => {
    let socialGoogleId = null;
    let socialFacebookId = null;

    if (socialMedia === "google") {
      socialGoogleId = userID;
    } else if (socialMedia === "facebook") {
      socialFacebookId = userID;
    }
    dispatch(
      AuthenticationActions.sendSocialAuthentication({
        url: url,
        origin: origin,
        Email: email,
        SocialGoogleId: socialGoogleId,
        SocialFacebookId: socialFacebookId,
        senha: null,
        emailValidated: emailValidated,
      })
    );
  };

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => socialLoginMessage(credentialResponse),
    onError: (data) => failureMessage(data),
  });

  const loginGoogle = useGoogleLogin({
    onSuccess: (data) => socialLoginMessage(data),
    onError: (data) => failureMessage(data),
  });

  const failureMessage = (error) => {
    console.log(error);
  };

  return (
    <S.FormHolder>
      <Margin mt={mobile ? 6 : 12} mb={mobile ? 1 : 2}>
        <Title as="1" levelDesktop="3" level="4" emphasis>
          É bom te ver de novo!
        </Title>
      </Margin>
      <Margin mb={4}>
        <Text level={mobile ? "2" : "1"}>
          Acesse sua conta e confira as novidades do mundo Kenvue.
        </Text>
      </Margin>
      {/*<Margin mb={4}>
        <Text as="strong" level={mobile ? "2" : "1"}>
          Faça login com sua conta Google{" "}
        </Text>
      </Margin>

      <Margin mb={2}>
        <SocialButton
          text="Entrar com o Google"
          typeIcon="google"
          onClick={() => loginGoogle()}
        />
      </Margin>

      <Margin mb={4}>
        <FacebookLogin
          appId="676402717814782"
          fields="name,email,picture"
          render={(renderProps) => (
            <SocialButton
              text="Entrar com o Facebook"
              typeIcon="facebook"
              onClick={renderProps.onClick}
            />
          )}
          callback={(userInfo) => {
            handleAuthentication(
              userInfo?.email,
              userInfo?.userID,
              true,
              "facebook"
            );
          }}
        />
      </Margin>
      <Margin mb={4}>
        <fieldset>
          <legend>
            <Text as="strong" level={mobile ? "2" : "1"}>
              ou
            </Text>
          </legend>
        </fieldset>
      </Margin>*/}
      <Margin mb={5}>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            setUserEmail(values.Email);
            dispatch(
              AuthenticationActions.sendAuthentication({
                ...values,
                url: url,
                origin: origin,
                TipoValidacao: 1,
                AssuntoEmail: "Chegou o seu código Johnson´s® Baby",
              })
            );
            setShowValidationCode(true);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty,
          }) => (
            <S.FormLimiter onSubmit={handleSubmit}>
              <Margin mb={mobile ? 3 : 4}>
                <Input
                  label="E-mail"
                  placeholder="Digite seu e-mail cadastrado"
                  htmlFor="emailFieldFormLogin"
                  name="Email"
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.Email && touched.Email && errors.Email}
                  valid={!errors.Email && touched.Email && !errors.Email}
                  required
                />
              </Margin>
              <Margin mb={mobile ? 3 : 4}>
                {!errorMessage && showMsg && (
                  <Margin mt={3} mb={3}>
                    <Text level="2" as="strong" className="text">
                      O código de verificação foi enviado. Por favor, insira ele
                      no campo abaixo.
                    </Text>
                  </Margin>
                )}
              </Margin>
              <S.ButtonHolder>
                <Button
                  className="animation-size"
                  color={
                    !isValid || !dirty || sending ? "disabled" : "secondary"
                  }
                  type="submit"
                  disabled={!isValid || !dirty || sending}
                  id="sendButtonFormLogin"
                >
                  {sending ? (
                    <Loading button />
                  ) : (
                    "Enviar código de verificação"
                  )}
                </Button>
              </S.ButtonHolder>
            </S.FormLimiter>
          )}
        </Formik>
      </Margin>

      {showValidationCode && showMsg && <FormCode userEmail={userEmail} tipoValidacao={1} />}

      <Margin mb={mobile ? 5 : 12}>
        <S.TextHolder>
          <Margin mb={2}>
            <Text level="1" as="strong">
              É novo por aqui?
            </Text>
          </Margin>
          <Button
            className="animation-size"
            color={"secondary"}
            variant="outlined"
            id="goToLogin"
            onClick={() => history.push("/")}
          >
            Cadastre-se
          </Button>
        </S.TextHolder>
      </Margin>

      {errorMessage && !participanteHash && (
        <Margin mb={3}>
          <ErrorMessage inset>{errorMessage}</ErrorMessage>
        </Margin>
      )}
    </S.FormHolder>
  );
}
