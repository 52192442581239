import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { DynamicBG, FormSignUp } from "~/components/blocks";
import { Margin, Title, Text } from "~/components/elements";
import { TitleHolder } from "./styles";
import { scrollTop, parentScrollTop } from "~/utils/tools";
import isMobile from "~/hooks/isMobile";

export default function SignUp() {
  const mobile = isMobile();
  const transition = {
    duration: 0.3,
    ease: [0.43, 0.13, 0.23, 0.96],
  };

  const animations = {
    exit: { y: "50%", opacity: 0, transition },
    enter: {
      y: "0%",
      opacity: 1,
      transition,
    },
  };

  useEffect(() => {
    scrollTop();
    parentScrollTop();
  }, []);

  return (
    <motion.div
      variants={animations}
      className="single"
      initial="exit"
      animate="enter"
      exit="exit"
    >
      <DynamicBG>
        <Margin mt={mobile ? 6 : 8} mb={6}>
          <TitleHolder>
            <Title as="1" levelDesktop="3" level="4" emphasis>
              Faça parte do mundo Kenvue!
            </Title>
            <Margin pt={mobile ? 2 : 2} mb={6}>
              <Text level={mobile ? "2" : "1"} className="text">
                Entre para nossa comunidade e receba as novidades e promoções em
                primeira mão.
              </Text>
            </Margin>
          </TitleHolder>
          <FormSignUp />
        </Margin>
      </DynamicBG>
    </motion.div>
  );
}
