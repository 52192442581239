import { put, takeLatest, call } from "redux-saga/effects";
import api from "~/services/apiNoAuth";
import { Types as SignUpTypes } from "./actions";
import { Types as AuthenticationTypes } from "../authentication/actions";
import { urlEncode } from "~/utils/tools";
import { variables } from "~/utils/variables";
import history from "~/routes/history";
import MESSAGES from "~/utils/messages";

export function* sendSignupForm({ payload }) {
  try {
    payload.Device = navigator.userAgent;
    payload.ConnectHash = variables.connectHash;
    const payloadValues = urlEncode(payload);
    const { data } = yield call(
      api.post,
      "engine/participante/cadastrar-compacto",
      payloadValues
    );
    if (data.sucesso) {
      yield put({
        type: SignUpTypes.SEND_SIGNUP_SUCCESS,
        payload: {
          userMail: payload.Email,
          userHash: data.ParticipanteHash,
        },
      });
      yield put({
        type: SignUpTypes.SEND_SOCIAL_SIGNUP_INFO,
        payload: { ...data, success: data.sucesso },
      });
      yield put({
        type: AuthenticationTypes.SEND_AUTHENTICATION_SUCCESS,
        payload: { ...data, success: data.sucesso },
      });
    } else {
      yield put({ type: SignUpTypes.SEND_SIGNUP_FAIL, payload: data });
    }
  } catch (err) {
    yield put({
      type: SignUpTypes.SEND_SIGNUP_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendComplementForm({ payload }) {
  try {
    payload.Device = navigator.userAgent;
    payload.ConnectHash = variables.connectHash;
    const payloadValues = urlEncode(payload);
    const { data } = yield call(
      api.post,
      "engine/participante/editar/dados-pessoais",
      payloadValues
    );
    if (data.sucesso) {
      yield put({
        type: SignUpTypes.SEND_COMPLEMENT_SUCCESS, payload: data });
      yield history.push("/complemento-sucesso");
    } else {
      yield put({ type: SignUpTypes.SEND_COMPLEMENT_FAIL, payload: data });
    }
  } catch (err) {
    yield put({
      type: SignUpTypes.SEND_COMPLEMENT_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendConfirmCode({ payload }) {
  try {
    payload.Device = navigator.userAgent;
    payload.ConnectHash = variables.connectHash;
    const payloadValues = urlEncode(payload);
    const { data } = yield call(
      api.post,
      "engine/participante/validar-cadastro",
      payloadValues
    );
    if (data.sucesso) {
      yield put({
        type: SignUpTypes.SEND_CONFIRM_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: SignUpTypes.SEND_CONFIRM_FAIL,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: SignUpTypes.SEND_CONFIRM_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* resendConfirmEmail({ payload }) {
  try {
    payload.Device = navigator.userAgent;
    payload.ConnectHash = variables.connectHash;
    const payloadValues = urlEncode(payload);
    const { data } = yield call(
      api.post,
      "engine/participante/reenviar-email-validar-cadastro",
      payloadValues
    );
    if (data.sucesso) {
      yield put({
        type: SignUpTypes.RESEND_CONFIRM_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: SignUpTypes.RESEND_CONFIRM_FAIL,
        payload: data,
      });
    }
  } catch (err) {
    yield put({
      type: SignUpTypes.RESEND_CONFIRM_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export default function* saga() {
  yield takeLatest(SignUpTypes.SEND_SIGNUP_FORM, sendSignupForm);
  yield takeLatest(SignUpTypes.SEND_COMPLEMENT_FORM, sendComplementForm);
  yield takeLatest(SignUpTypes.SEND_CONFIRM_CODE, sendConfirmCode);
  yield takeLatest(SignUpTypes.RESEND_CONFIRM_EMAIL, resendConfirmEmail);
}
