import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { AccountContent } from "~/components/sections";
import {
  DynamicBG,
  FormUpdateEmail,
  FormValidateLogin,
  UpdateEmailSent,
  EmailRegistered,
  EmailUpdated,
} from "~/components/blocks";
import { Modal, Toast } from "~/components/elements";
import { Creators as AccountActions } from "~/store/modules/account/actions";
import { Creators as AuthActions } from "~/store/modules/authentication/actions";

import { scrollTop, parentScrollTop } from "~/utils/tools";
import isMobile from "~/hooks/isMobile";

export default function MyAccount() {
  const [openModal, setOpenModal] = useState(false);
  const [modalActive, setModalActive] = useState(null);
  const dispatch = useDispatch();
  const mobile = isMobile();
  const [showToaster, setShowToaster] = useState(null)
  const [messageToaster, setMessageToaster] = useState(null)
  const [successToaster, setSuccessToaster] = useState(null)

  const editPersonal = useSelector((state) => state?.account?.editPersonal);

  useEffect( () => {
    setShowToaster(editPersonal.showToaster)
    setMessageToaster(editPersonal.message)
    setSuccessToaster(editPersonal.success)
  }, [editPersonal])

  const editContact = useSelector((state) => state?.account?.editContact);

  useEffect( () => {
    setShowToaster(editContact.showToaster)
    setMessageToaster(editContact.message)
    setSuccessToaster(editContact.success)
  }, [editContact])

  const resendNewEmail = useSelector((state) => state?.account?.resendNewEmail);

  useEffect( () => {
    setShowToaster(resendNewEmail.showToaster)
    setMessageToaster(resendNewEmail.message)
    setSuccessToaster(resendNewEmail.success)
  }, [resendNewEmail])

  const url = process.env.REACT_APP_HOST_URL;
  const origin = process.env.REACT_APP_ORIGIN;

  const transition = {
    duration: 0.3,
    ease: [0.43, 0.13, 0.23, 0.96],
  };

  const animations = {
    exit: { y: "50%", opacity: 0, transition },
    enter: {
      y: "0%",
      opacity: 1,
      transition,
    },
  };

  useEffect(() => {
    scrollTop();
    parentScrollTop();
    dispatch(AuthActions.setValidateAuth());
    dispatch(AccountActions.setUpdateEmail());
    dispatch(AccountActions.getUserDetails());
    dispatch(AccountActions.setResendConfirmNewEmail());
  }, [dispatch]);

  const validateAuthSuccess = useSelector(
    (state) => state?.authentication?.validateAuth?.success
  );

  const updateEmailSentSuccess = useSelector(
    (state) => state?.account?.updateEmail?.success
  );

  const emailAlreadyExists = useSelector(
    (state) => state?.account?.updateEmail?.emailAlreadyExists
  );

  const showModalEmailUpdated = useSelector(
    (state) => state?.account?.newEmail?.success
  );

  const newEmail = useSelector((state) => state?.authentication?.auth?.user?.email);

  const emailValidated = useSelector(
    (state) => state?.account?.details?.dadosContato?.ParticipanteEmail
  );

  useEffect(() => {
    // if (validateAuthSuccess) {
    //   handleModal("FormUpdateEmail");
    // } else {
    //   handleModal(null);
    // }
    validateAuthSuccess && handleModal("FormUpdateEmail");
    emailAlreadyExists && handleModal("EmailRegistered");
    showModalEmailUpdated && handleModal("EmailUpdated");
    if (updateEmailSentSuccess) {
      handleModal("UpdateEmailSent");
      dispatch(AccountActions.getUserDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    validateAuthSuccess,
    updateEmailSentSuccess,
    emailAlreadyExists,
    showModalEmailUpdated,
  ]);

  useEffect(() => {
    handleModal(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resendConfirmNewEmail = () => {
    dispatch(AccountActions.resendConfirmNewEmail({ url: url, origin: origin }));
  };

  const handleModal = (data) => {
    setOpenModal(!!data);
    parentScrollTop();

    switch (data) {
      case "FormValidateLogin":
        setModalActive(<FormValidateLogin mobile={mobile} />);
        break;
      case "FormUpdateEmail":
        setModalActive(<FormUpdateEmail mobile={mobile} />);
        break;
      case "UpdateEmailSent":
        setModalActive(
          <UpdateEmailSent
            closeModal={() => {
              dispatch(AccountActions.setUpdateEmail());
              setOpenModal(false);
              parentScrollTop();
            }}
            mobile={mobile}
          />
        );
        break;
      case "EmailRegistered":
        setModalActive(
          <EmailRegistered handleModal={handleModal} mobile={mobile} />
        );
        break;
      case "EmailUpdated":
        setModalActive(
          <EmailUpdated
            mobile={mobile}
            email={newEmail}
            handleModal={handleModal}
          />
        );
        break;
      default:
        setOpenModal(false);
        setModalActive(null);
        parentScrollTop();
        break;
    }
  };

  const closeModal = () => {
    setOpenModal(false);
    setModalActive(null);
    parentScrollTop();
    dispatch(AuthActions.setValidateAuth());
    dispatch(AccountActions.setResendConfirmNewEmail());
    dispatch(AccountActions.setUpdateEmail());
  };

  return (
    <>
      <Modal open={openModal} mobile={mobile} closeModal={closeModal}>
        {modalActive}
      </Modal>
      <Toast
        open={showToaster}
        message={messageToaster}
        success={successToaster}
      />
      <motion.div
        variants={animations}
        className="single"
        initial="exit"
        animate="enter"
        exit="exit"
      >
        <DynamicBG content={true}>
          <AccountContent
            handleModal={handleModal}
            resendConfirmNewEmail={resendConfirmNewEmail}
            emailValidated={
              Object.keys(emailValidated).length === 0
                ? true
                : emailValidated.Validado
            }
          />
        </DynamicBG>
      </motion.div>
    </>
  );
}
