export default {
  requiredField: "Preenchimento obrigatório",
  requiredSelect: "Escolha uma opção",
  requiredCheck: "Seleção obrigatória",
  passwordMatch: "Sua senha deve conter de 8 a 12 dígitos, com uma combinação de letras e números",
  mismatchedPass: "As senhas não estão idênticas",
  charMessage: "Somente letras são permitidas",
  invalidName: "Digite seu nome, por favor",
  invalidLastname: "Digite seu sobrenome, por favor",
  invalidNameChild: "Digite o nome completo de seu filho, por favor.",
  invalidMail: "E-mail inválido",
  invalidCode: "Código inválido",
  mismatchedMail: "O e-mail inserido não corresponde",
  invalidPhone: "Número inválido",
  invalidDate: "Data inválida",
  legallyYounger: "Apenas maiores de 18 anos",
  legallyOlder: "Apenas menores de 18 anos",
  invalidCPF: "CPF inválido",
  genericError: "Ocorreu um erro, tente novamente",
};
