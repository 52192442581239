import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { DynamicBG, FormLogin, ModalLogin } from "~/components/blocks";
import { useDispatch, useSelector } from "react-redux";
import { scrollTop, parentScrollTop } from "~/utils/tools";
import { Creators as SignUpActions } from "~/store/modules/signUp/actions";

export default function Login() {
  const dispatch = useDispatch();

  const transition = {
    duration: 0.3,
    ease: [0.43, 0.13, 0.23, 0.96],
  };

  const animations = {
    exit: { y: "50%", opacity: 0, transition },
    enter: {
      y: "0%",
      opacity: 1,
      transition,
    },
  };

  const errorMessage = useSelector(
    (state) => state.authentication.auth.errorMessage
  );

  const participanteHash = useSelector(
    (state) => state.authentication.auth.user.ParticipanteHash
  );

  const url = process.env.REACT_APP_HOST_URL;
  const origin = process.env.REACT_APP_ORIGIN;

  const handleResend = () => {
    dispatch(SignUpActions.setResendData());
    dispatch(
      SignUpActions.resendConfirmEmail({
        ParticipanteHash: participanteHash,
        url: url,
        origin: origin,
      })
    );
  };

  useEffect(() => {
    scrollTop();
    parentScrollTop();
  }, []);

  return (
    <>
      {errorMessage && participanteHash ? (
        <ModalLogin message={errorMessage} handleResend={handleResend} />
      ) : null}

      <motion.div
        variants={animations}
        className="single"
        initial="exit"
        animate="enter"
        exit="exit"
      >
        <DynamicBG content={true}>
          <FormLogin participanteHash={participanteHash} />
        </DynamicBG>
      </motion.div>
    </>
  );
}
