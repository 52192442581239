import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Grid } from "@material-ui/core";

import {
  Margin,
  Input,
  Select,
  Button,
  Loading,
} from "~/components/elements";
import * as S from "./styles";

import { genderSelect, ufSelect } from "~/utils/variables";
import { formatDateView, formatDate, dateMask } from "~/utils/tools";
import { schema } from "./validation";
import { Creators as AccountActions } from "~/store/modules/account/actions";

export default function AccountPersonal({ personalData, setEdit, edit }) {
  const dispatch = useDispatch();
  const editPersonal = useSelector((state) => state.account.editPersonal);
  const { sending, success } = editPersonal;
  const initialValues = {
    Nome: personalData.Nome,
    DataNascimento: formatDateView(personalData.DataNascimento),
    Sexo: genderSelect.find((gender) => gender.value === personalData.Sexo),
    Estado: ufSelect.find((uf) => uf.value === personalData.Uf),
  };

  useEffect(() => {
    dispatch(AccountActions.setEditPersonalData());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setEdit(0);
    }
  }, [success, setEdit]);

  return (
    <S.CardAbstract>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          const payloadValues = {
            ...values,
            Estado: values.Estado.value,
            Sexo: values.Sexo.value,
            DataNascimento: formatDate(values.DataNascimento),
          };

          dispatch(AccountActions.sendEditPersonalData(payloadValues));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            <>
              <Grid
                container
                justifyContent="space-between"
                alignContent="flex-start"
              >
                <S.NameHolder>
                  <Margin mb={5}>
                    <Input
                      label="Nome completo"
                      placeholder="Digite seu nome"
                      htmlFor="nameFieldAccountPersonal"
                      name="Nome"
                      value={values.Nome}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.Nome && touched.Nome && errors.Nome}
                      valid={!errors.Nome && touched.Nome && !errors.Nome}
                      required
                    />
                  </Margin>
                </S.NameHolder>
                <S.LabelHolder>
                  <Margin mb={5}>
                    <Input
                      mask={dateMask}
                      label="Data de nascimento"
                      placeholder="00/00/0000"
                      htmlFor="birthFieldAccountPersonal"
                      name="DataNascimento"
                      value={values.DataNascimento}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={
                        errors.DataNascimento &&
                        touched.DataNascimento &&
                        errors.DataNascimento
                      }
                      valid={
                        !errors.DataNascimento &&
                        touched.DataNascimento &&
                        !errors.DataNascimento
                      }
                      required
                    />
                  </Margin>
                </S.LabelHolder>
                <S.LabelHolder>
                  <Margin mb={5}>
                    <Select
                      label="Gênero"
                      name="Sexo"
                      placeholder="Selecione"
                      onChange={setFieldValue}
                      value={values.Sexo}
                      errors={errors.Sexo && touched.Sexo && errors.Sexo}
                      options={genderSelect}
                      required
                      id="genderFieldAccountPersonal"
                    />
                  </Margin>
                </S.LabelHolder>
                <S.LabelHolder>
                  <Select
                    label="Estado"
                    name="Estado"
                    placeholder="UF"
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    value={values.Estado}
                    errors={errors.Estado && touched.Estado && errors.Estado}
                    options={ufSelect}
                    required
                    id="stateFieldAccountPersonal"
                  />
                </S.LabelHolder>
              </Grid>
              <S.SubmitButtonHolder>
                <Button
                  className="animation-size"
                  color={
                    !isValid ||
                    !Object.values(values).every((value) => !!value) ||
                    sending
                      ? "disabled"
                      : "secondary"
                  }
                  type="submit"
                  disabled={
                    !isValid ||
                    !Object.values(values).every((value) => !!value) ||
                    sending
                  }
                  id="saveButtonAccountPersonal"
                >
                  {sending ? <Loading button /> : "SALVAR DADOS"}
                </Button>
              </S.SubmitButtonHolder>
            </>
          </form>
        )}
      </Formik>
    </S.CardAbstract>
  );
}
