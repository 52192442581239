import * as Yup from "yup";
import MESSAGE from "~/utils/messages";

export const schema = Yup.object().shape({
  Senha: Yup.string()
    .matches(/^[A-Za-z0-9 _!@#$%&*?]*\d+[A-Za-z0-9 _!@#$%&*?]*$/, MESSAGE.passwordMatch)
    .min(8, MESSAGE.passwordMatch)
    .max(12, MESSAGE.passwordMatch)
    .required(MESSAGE.requiredField),
});
