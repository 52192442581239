export const variables = {
  promoToken: "16DA11D0-5D6E-EC11-AB22-0E32428CE4CB",
  connectHash: "c2l0ZWRydXBhbEBtYXN0ZXIuY29tfCUjUzF0M0BEcnVwYWwh",
};
export const ufSelect = [
  { id: 0, value: undefined, label: "UF" },
  { id: 1, value: "AC", label: "AC" },
  { id: 2, value: "AL", label: "AL" },
  { id: 3, value: "AP", label: "AP" },
  { id: 4, value: "AM", label: "AM" },
  { id: 5, value: "BA", label: "BA" },
  { id: 6, value: "CE", label: "CE" },
  { id: 7, value: "DF", label: "DF" },
  { id: 8, value: "ES", label: "ES" },
  { id: 9, value: "GO", label: "GO" },
  { id: 10, value: "MA", label: "MA" },
  { id: 11, value: "MT", label: "MT" },
  { id: 12, value: "MS", label: "MS" },
  { id: 13, value: "MG", label: "MG" },
  { id: 14, value: "PA", label: "PA" },
  { id: 15, value: "PB", label: "PB" },
  { id: 16, value: "PR", label: "PR" },
  { id: 17, value: "PE", label: "PE" },
  { id: 18, value: "PI", label: "PI" },
  { id: 19, value: "RJ", label: "RJ" },
  { id: 20, value: "RN", label: "RN" },
  { id: 21, value: "RS", label: "RS" },
  { id: 22, value: "RO", label: "RO" },
  { id: 23, value: "RR", label: "RR" },
  { id: 24, value: "SC", label: "SC" },
  { id: 25, value: "SP", label: "SP" },
  { id: 26, value: "SE", label: "SE" },
  { id: 27, value: "TO", label: "TO" },
];

export const genderSelect = [
  { id: 0, value: undefined, label: "Selecione seu gênero" },
  { id: 1, value: "F", label: "Feminino" },
  { id: 2, value: "M", label: "Masculino" },
  { id: 3, value: "O", label: "Outros" },
  { id: 2, value: "N", label: "Prefiro não informar" },
];

export const babyAge = [
  { id: 0, value: undefined, label: "Selecione a faixa etária" },
  { id: 1, value: "gestacao", label: "Em gestação" },
  { id: 2, value: "bebe", label: "Bebê (0-2 anos)" },
  { id: 3, value: "crianca", label: "Criança (3+ anos)" },
];
