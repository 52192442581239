import React from "react";
import * as S from "./styles";

export default function DynamicBG({ content, children }) {
  return (
    <S.BGColor content={content}>
      <S.BGGrid container justifyContent="center">
        {children}
      </S.BGGrid>
    </S.BGColor>
  );
}
