import styled from "styled-components";
import theme from "~/styles/theme";

export const P = styled.p`
  color: ${(props) =>
    props.primary ? theme.palette.primary.main : theme.palette.secondary.main};
  font-family: ${(props) =>
    props.emphasis
      ? theme.typography.secondary.bold
      : theme.typography.primary.regular};
`;
export const Span = styled.span`
  color: ${(props) =>
    props.primary ? theme.palette.primary.main : theme.palette.secondary.main};
  font-family: ${(props) =>
    props.emphasis
      ? theme.typography.secondary.bold
      : theme.typography.primary.regular};
`;
export const Strong = styled.strong`
  color: ${(props) =>
    props.primary ? theme.palette.primary.main : theme.palette.secondary.main};
  font-family: ${(props) =>
    props.emphasis
      ? theme.typography.secondary.bold
      : theme.typography.primary.regular};
  text-decoration: ${(props) =>
    props.underline
      ? "underline"
      : "auto"};
`;
export const Anchor = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) =>
    props.primary ? theme.palette.primary.main : theme.palette.secondary.main};
  font-family: ${(props) =>
    props.emphasis
      ? theme.typography.secondary.bold
      : theme.typography.primary.regular};
`;
