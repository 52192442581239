import React from "react";
import { Margin, Title, Button, Text } from "~/components/elements";
import { useDispatch } from "react-redux";
import { Creators as AccountActions } from "~/store/modules/account/actions";

import * as S from "./styles";

export default function EmailUpdated({ mobile, email, handleModal }) {
  const dispatch = useDispatch();

  return (
    <>
      <Margin mb={2}>
        <Title emphasis level="5" as="3">
          E-mail alterado com sucesso!
        </Title>
      </Margin>
      <Margin mb={4}>
        <Text primary level={mobile ? "2" : "1"}>
          Seu novo e-mail <strong>{email}</strong> passará a
          receber toda a comunicação Kenvue.
        </Text>
      </Margin>

      <S.AddInfoButtonHolder>
        <Button
          primary
          onClick={() => {
            dispatch(AccountActions.setConfirmEmailCode());
            handleModal(null);
          }}
          color="secondary"
          id="btnUpdateEmailSent"
        >
          VOLTAR A NAVEGAR
        </Button>
      </S.AddInfoButtonHolder>
    </>
  );
}
