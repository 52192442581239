import styled, { css } from "styled-components";
import theme from "~/styles/theme";

export const ArrowBackground = styled.div`
  z-index: 1;
  height: 80px;
  width: 80px;
  cursor: pointer;
  background: ${theme.palette.bubbles.inactive};
  text-align: center;
  line-height: 90px;
  border-radius: 100%;
  position: absolute;
  bottom: -53px;
  ${(props) =>
    props.right
      ? css`
          right: calc(50% - 250px);
        `
      : css`
          left: calc(50% - 250px);
        `}
  &:hover {
    background: ${theme.palette.bubbles.hover};
  }
  svg {
    width: 22px;
    height: 21px;
  }
`;

export const DotsHolder = styled.div`
  li {
    width: 16px;
    height: 16px;
    &.slick-active {
      > div {
        opacity: 1;
        background: ${theme.palette.primary.light};
      }
    }
  }
`;
export const CustomDots = styled.div`
  width: 16px;
  height: 16px;
  background: ${theme.palette.bubbles.inactive};
  opacity: 0.5;
  border-radius: 100%;
`;
