import styled from "styled-components";
import theme from "~/styles/theme";

export const FormHolder = styled.div`
  margin: 0 auto;
  text-align: center;
  p {
    color: ${theme.palette.black.main};
    span {
      cursor: pointer;
      font-family: ${theme.typography.secondaryFont};
      text-decoration: underline;
      text-transform: uppercase;
    }
  }

  h1, strong {
    color: ${theme.palette.black.main};
  }

  fieldset {
    border-top: 1px solid #000;

    legend {
      padding: 0 10px;
    }
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 590px;
    width: 100%;

    > div {
      width: 100%;
    }
    p {
      width: 100%;
    }
  }
`;

export const FormLimiter = styled.form`
  button {
    width: 100%;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 590px;
    margin: 0 auto;
  }
`;

export const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;

  button {
    .icon {
      margin-left: 8px;
      svg {
        width: 24px;
        margin-top: 5px;
      }
    }
  }
  .new-code {
    margin: 0 0 24px;
    border-radius: 56px;
    border: 1px solid #3797DB;
    color: #3797DB;
    background-color: #fff;
    text-transform: initial;
    font-size: 18px;
  }
  .verify {
    border-radius: 56px;
    border: 1px solid #3797DB;
    background-color: #3797DB;
    color: #fff;
    text-transform: initial;
    width: 336px;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    flex-direction: row;
    justify-content: flex-end;    
    button {
      max-width: 316px;
    }
    .new-code {
      margin: 0 16px 0 40px;
      width: 265px;
    }
  }
`;

export const ButtonNewCode = styled.div`
  margin: 0 16px 0 40px;
`;
