import { animateScroll as scroll } from "react-scroll";
import theme from "~/styles/theme";

export function formatCPF(value) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}
export function formatCNPJ(value) {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function formatPhone(value) {
  value = value.replace(/\D/g, "").replace(/(\(|\)|-)/g, "");
  if (value.length === 11) {
    return `${value}`.replace(/(\d{2})(\d{5})(\d{4})/g, "($1)$2-$3");
  }
  return `${value}`.replace(/(\d{2})(\d{4})(\d{4})/g, "($1)$2-$3");
}

export function formatDate(value) {
  const date = value.split("/");
  return `${date[2]}-${date[1]}-${date[0]}`;
}

export function formatDateView(value) {
  if (!value) {
    return "";
  }
  
  const dateTime = value.split("T");
  const date = dateTime[0].split("-");
  return `${date[2]}/${date[1]}/${date[0]}`;
}

export function removeMask(value) {
  return value.replace(/\D+/g, "");
}

export function nameMask(value) {
  const regEx = /[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]/;
  return value.split("").map((char) => (regEx.test(char) ? regEx : ""));
}
export function phoneMask(value) {
  const onlyNumber = value.replace(/\D/g, "");
  if (onlyNumber && onlyNumber.length > 10) {
    return [
      "(",
      /\d/,
      /\d/,
      ")",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }
  return [
    "(",
    /\d/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
}
export function cpfMask() {
  return [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];
}
export function dateMask() {
  return [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
}
export function phoneValidation(phone) {
  return /^(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/.test(phone);
}
export function cpfValidation(strCpf) {
  const cpf = removeMask(strCpf);
  if (!/[0-9]{11}/.test(cpf)) return false;
  if (cpf === "00000000000") return false;
  if (cpf === "11111111111") return false;
  if (cpf === "22222222222") return false;
  if (cpf === "33333333333") return false;
  if (cpf === "44444444444") return false;
  if (cpf === "55555555555") return false;
  if (cpf === "66666666666") return false;
  if (cpf === "77777777777") return false;
  if (cpf === "88888888888") return false;
  if (cpf === "99999999999") return false;

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i += 1)
    soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10), 10)) return false;

  soma = 0;
  for (let i = 1; i <= 10; i += 1)
    soma += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11), 10)) return false;
  return true;
}

export function scrollTop() {
  scroll.scrollToTop();
}

export function parentScrollTop() {
  window.parent.postMessage(["scrollToTop", true], "*");
}

export function scrollBottom() {
  scroll.scrollToBottom();
}

export function urlEncode(obj) {
  const values = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    values.append(`${key}`, `${obj[key]}`);
  });
  return values;
}

// BI
export function pageView(data) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "Page View",
      label: data,
    });
  }
}

export function sendError(message) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "sendEvent",
      "event-action": "etapa-erro",
      "event-value": message,
    });
  }
}

export function checkHasNumber(value) {
  const regex = /\d/;
  return regex.test(value);
}

export function checkHasString(value) {
  const regex = /[A-Za-z]+/;
  return regex.test(value);
}

export function checkHasSpecialCharacter(value) {
  //eslint-disable-next-line
  const regex = /[!@#$%^&*()_ ~+`\-=\[\]{};':"\\|,.<>\/?]+/;
  return regex.test(value);
}

export function handleFontFamily (font, weight) {
  if (font === "primary") {
    switch (weight) {
      case "light":
        return theme.typography.primary.light;
      case "regular":
        return theme.typography.primary.regular;
      case "medium":
        return theme.typography.primary.medium;
      case "bold":
        return theme.typography.primary.bold;
    
      default:
        return theme.typography.primary.regular;
    }
  } else if (font === "secondary") {
    switch (weight) {
      case "light":
        return theme.typography.secondary.light;
      case "regular":
        return theme.typography.secondary.regular;
      case "medium":
        return theme.typography.secondary.medium;
      case "bold":
        return theme.typography.secondary.bold;
    
      default:
        return theme.typography.secondary.regular;
    }
  } else if (font === "tertiary") {
    switch (weight) {
      case "light":
        return theme.typography.tertiary.light;
      case "regular":
        return theme.typography.tertiary.regular;
      case "medium":
        return theme.typography.tertiary.medium;
      case "bold":
        return theme.typography.tertiary.bold;
    
      default:
        return theme.typography.tertiary.regular;
    }
  }
};

export function handleColor (colorCategory, colorComplement) {
  if (colorCategory === "brand") {
    switch (colorComplement) {
      case "primary-light":
        return theme.palette.brand.primary.light;
      case "primary-key":
        return theme.palette.brand.primary.key;
      case "primary-medium":
        return theme.palette.brand.primary.medium;
      case "primary-dark":
        return theme.palette.brand.primary.dark;
      case "secondary-light":
        return theme.palette.brand.secondary.light;
      case "secondary-key":
        return theme.palette.brand.secondary.key;
      case "secondary-medium":
        return theme.palette.brand.secondary.medium;
      case "secondary-dark":
        return theme.palette.brand.secondary.dark;
    
      default:
        return theme.palette.brand.primary.key;
    }
  } else if (colorCategory === "neutral") {
    switch (colorComplement) {
      case "primary-light":
        return theme.palette.neutral.primary.light;
      case "primary-key":
        return theme.palette.neutral.primary.key;
      case "primary-medium":
        return theme.palette.neutral.primary.medium;
      case "primary-dark":
        return theme.palette.neutral.primary.dark;
      case "secondary-light":
        return theme.palette.neutral.secondary.light;
      case "secondary-key":
        return theme.palette.neutral.secondary.key;
      case "secondary-medium":
        return theme.palette.neutral.secondary.medium;
      case "secondary-dark":
        return theme.palette.neutral.secondary.dark;
    
      default:
        return theme.palette.neutral.primary.key;
    }
  }
};