import * as Yup from "yup";
import MESSAGE from "~/utils/messages";
import { cpfValidation } from "~/utils/tools";

export const schema = Yup.object().shape({
  Documento: Yup.mixed()
    .required(MESSAGE.requiredField)
    .test({
      name: "cpf",
      message: MESSAGE.invalidCPF,
      test: (value) => value && cpfValidation(value),
    }),
});
