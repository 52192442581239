import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { StyledCheckbox } from "./styles";
import theme from "~/styles/theme";

export default function Checkbox({ ...props }) {
  const ThemeCheckbox = withStyles({
    root: {
      color: props.error
        ? theme.palette.error.main
        : theme.palette.checkbox.border,
      borderRadius: "2px",
      "&$checked": {
        color: theme.palette.secondary.main,
      },
    },
    checked: {},
  })((props) => <StyledCheckbox color="default" {...props} />);
  return <ThemeCheckbox {...props} />;
}
