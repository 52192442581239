import React, { useEffect } from "react";
import ReactSVG from "react-svg";
import WhiteArrow from "~/assets/img/icon/whiteRightArrow.svg";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { Formik } from "formik";

import {
  //Title,
  //Text,
  Margin,
  Input,
  Button,
  Loading,
  //ErrorMessage
} from "~/components/elements";
import { schema } from "./validation";
import { Creators as AuthenticationActions } from "~/store/modules/authentication/actions";
import isMobile from "~/hooks/isMobile";
import * as S from "./styles";

export default function FormCode({ userEmail, showFormSignUpComplement, tipoValidacao }) {
  const mobile = isMobile();
  // const history = useHistory();
  const dispatch = useDispatch();
  const sending = useSelector((state) => state.authentication.auth.sending);
  // const logged = useSelector((state) => state.authentication.auth.loginToken);
  const completeSignUp = useSelector((state) => state.authentication.auth.user.cadastroCompleto);
  /*const errorMessage = useSelector(
    (state) => state.authentication.auth.errorMessage
  );*/
  const url = process.env.REACT_APP_HOST_URL;
  const origin = process.env.REACT_APP_ORIGIN;
  const initialValues = {
    Email: "",
    Senha: "",
  };
  useEffect(() => {
    dispatch(AuthenticationActions.setAuthenticationCodeMessage());
    // if (logged) {
    //   history.push("/minha-conta");
    // }
    return () => {
      dispatch(AuthenticationActions.setAuthenticationCodeMessage());
    };
    //eslint-disable-next-line
  }, [dispatch, completeSignUp]);

  return (
    <>
      <S.FormHolder>
        <Margin mb={5}>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              dispatch(
                AuthenticationActions.sendAuthenticationCode({
                  ...values,
                  url: url,
                  origin: origin,
                })
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              dirty,
            }) => (
              <S.FormLimiter onSubmit={handleSubmit}>
                <Margin mb={mobile ? 3 : 4}>
                  <Input
                    label="Código de verificação"
                    placeholder="Digite o código enviado para o e-mail"
                    htmlFor="codeFieldFormLogin"
                    id="codeFieldFormLogin"
                    name="Codigo"
                    value={values.Codigo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.Codigo && touched.Codigo && errors.Codigo}
                    valid={!errors.Codigo && touched.Codigo && !errors.Codigo}
                    required
                  />
                </Margin>
                
                {
                  !showFormSignUpComplement ? 
                  <S.ButtonHolder>
                    <Button
                      className="animation-size new-code"
                      disabled={!isValid}
                      id="sendButtonFormNewCode"
                      onClick={() => {
                        dispatch(
                          AuthenticationActions.sendAuthentication({
                            ...values,
                            url: url,
                            origin: origin,
                            Email: userEmail,
                            email: userEmail,
                            TipoValidacao: tipoValidacao,
                            AssuntoEmail: "Chegou o seu código Johnson´s® Baby",
                          })
                        );
                      }}
                    >
                      Enviar novo código
                    </Button>
                    <Button
                      type="submit"
                      className="animation-size verify"
                      color={!isValid || !dirty || sending ? "disabled" : "secondary"}
                      disabled={!isValid || !dirty || sending}
                      id="sendButtonFormVerifyCode"
                    >
                      {sending ? (
                        <Loading button />
                      ) : (
                        <>
                          Verificar e continuar
                          <ReactSVG src={WhiteArrow} className="icon" />
                        </>
                      )}
                    </Button>
                  </S.ButtonHolder> : null
                }
              </S.FormLimiter>
            )}
          </Formik>
        </Margin>
      </S.FormHolder>
    </>
  );
}
