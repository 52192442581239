import { call, put, select, takeLatest } from "redux-saga/effects";
import apiNoAuth from "~/services/apiNoAuth";
import apiJson from "~/services/apiJson";
import { Types as PromoTypes } from "./actions";
import { Types as AuthenticationTypes } from "../authentication/actions";
import { variables } from "~/utils/variables";
import { urlEncode } from "~/utils/tools";
import history from "~/routes/history";
import MESSAGES from "~/utils/messages";

export function* getNurseries() {
  try {
    const payload = {
      Token: variables.promoToken,
    };
    const payloadValues = urlEncode(payload);
    const { data } = yield call(
      apiNoAuth.post,
      "/promo/maternidade/listar",
      payloadValues
    );
    if (data.sucesso) {
      yield put({ type: PromoTypes.GET_NURSERIES_SUCCESS, payload: data });
    } else {
      yield put({ type: PromoTypes.GET_NURSERIES_FAIL, payload: data });
    }
  } catch (err) {
    yield put({
      type: PromoTypes.GET_NURSERIES_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* getInterests() {
  try {
    const payload = {
      Token: variables.promoToken,
    };
    const payloadValues = urlEncode(payload);
    const { data } = yield call(
      apiNoAuth.post,
      "/promo/interesses/listar",
      payloadValues
    );
    if (data.sucesso) {
      yield put({ type: PromoTypes.GET_INTERESTS_SUCCESS, payload: data });
    } else {
      yield put({ type: PromoTypes.GET_INTERESTS_FAIL, payload: data });
    }
  } catch (err) {
    yield put({
      type: PromoTypes.GET_INTERESTS_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendRegister({ payload }) {
  try {
    payload.Device = navigator.userAgent;
    payload.ConnectHash = variables.connectHash;
    payload.Token = variables.promoToken;
    payload.PremioId = 1;
    payload.ParticipanteHash = yield select(
      (state) => state.authentication.auth.user.ParticipanteHash
    );
    payload.LoginToken = yield select(
      (state) => state.authentication.auth.loginToken
    );

    const { data } = yield call(
      apiJson.post,
      "/promo/participante/cadastrar-participante-maternidade",
      payload
    );
    if (data.sucesso) {
      yield put({ type: PromoTypes.SEND_REGISTER_SUCCESS, payload: data });
      yield put({ type: AuthenticationTypes.SET_COMPLETE_SIGNUP });
      yield history.push("/cadastro-concluido");
    } else {
      yield put({
        type: PromoTypes.SEND_REGISTER_FAIL,
        payload: { msg: data.msg },
      });
    }
  } catch (err) {
    yield put({
      type: PromoTypes.SEND_REGISTER_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}
export function* getPAT({ payload }) {
  try {
    const payload = {
      Device: navigator.userAgent,
      Token: variables.promoToken,
      ConnectHash: variables.connectHash,
      LoginToken: yield select((state) => state.authentication.auth.loginToken),
      ParticipanteHash: yield select(
        (state) => state.authentication.auth.user.ParticipanteHash
      ),
    };

    const { data } = yield call(
      apiJson.post,
      "/promo/participante/listar-sugestoes-maternidade",
      payload
    );
    if (data.sucesso) {
      const user = yield select((state) => state.authentication.auth.user);
      yield put({
        type: PromoTypes.GET_PAT_SUCCESS,
        payload: { ...data, user },
      });
    } else {
      yield put({
        type: PromoTypes.GET_PAT_FAIL,
        payload: { msg: data.msg },
      });
    }
  } catch (err) {
    yield put({
      type: PromoTypes.GET_PAT_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* sendCoupon({ payload }) {
  try {
    payload.Token = variables.promoToken;
    payload.PremioId = 1;
    const payloadValues = urlEncode(payload);
    const { data } = yield call(
      apiNoAuth.post,
      "/promo/brindes/buscar-por-codigo",
      payloadValues
    );
    if (data.sucesso) {
      yield put({ type: PromoTypes.SEND_COUPON_SUCCESS, payload: data });
    } else {
      yield put({
        type: PromoTypes.SEND_COUPON_FAIL,
        payload: { msg: data.msg },
      });
    }
  } catch (err) {
    yield put({
      type: PromoTypes.SEND_COUPON_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}

export function* getExistingUser({ payload }) {
  try {
    payload.Device = navigator.userAgent;
    payload.ConnectHash = variables.connectHash;
    payload.Token = variables.promoToken;
    payload.ParticipanteHash = yield select(
      (state) => state.authentication.auth.user.ParticipanteHash
    );
    payload.LoginToken = yield select(
      (state) => state.authentication.auth.loginToken
    );
    const payloadValues = urlEncode(payload);
    const { data } = yield call(
      apiNoAuth.post,
      "/engine/participante/existe",
      payloadValues
    );
    if (data.sucesso) {
      yield put({ type: PromoTypes.GET_EXISTING_USER_SUCCESS, payload: data });
    } else {
      yield put({
        type: PromoTypes.GET_EXISTING_USER_FAIL,
        payload: { msg: data.msg },
      });
    }
  } catch (err) {
    yield put({
      type: PromoTypes.GET_EXISTING_USER_FAIL,
      payload: { msg: err || MESSAGES.genericError },
    });
  }
}
export default function* saga() {
  yield takeLatest(PromoTypes.GET_NURSERIES, getNurseries);
  yield takeLatest(PromoTypes.GET_INTERESTS, getInterests);
  yield takeLatest(PromoTypes.SEND_REGISTER, sendRegister);
  yield takeLatest(PromoTypes.GET_PAT, getPAT);
  yield takeLatest(PromoTypes.SEND_COUPON, sendCoupon);
  yield takeLatest(PromoTypes.GET_EXISTING_USER, getExistingUser);
}
