import React from "react";
import { HeaderWrapper, Logo, Link, LinkWrapper } from "./styles";
import logo from "~/assets/img/logo-johnsons.png";
import ReactSVG from "react-svg";
import chevronLeft from "~/assets/img/icon/chevron-left.svg";

export default function Header() {
  return (
    <HeaderWrapper>
      <Link href="https://www.johnsonsbaby.com.br/fale-conosco" className="left">
        Fale conosco
      </Link>
      <Logo src={logo} alt="Logo" className="logo" />
      <LinkWrapper>
        <ReactSVG src={chevronLeft} />
        <Link href="https://www.johnsonsbaby.com.br/" className="right">
          Voltar para o site
        </Link>
      </LinkWrapper>
    </HeaderWrapper>
  );
}