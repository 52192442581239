import styled from "styled-components";
import theme from "~/styles/theme";

export const FormHolder = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  text-align: center;
  p {
    color: ${theme.palette.black.main};
    span {
      cursor: pointer;
      font-family: ${theme.typography.secondary.bold};
      text-decoration: underline;
      text-transform: uppercase;
    }
  }

  h1, strong {
    color: ${theme.palette.black.main};
  }

  fieldset {
    border-top: 1px solid #000;

    legend {
      padding: 0 10px;
    }
  }

  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 590px;
    width: 100%;

    > div {
      width: 100%;
    }
    p {
      width: 100%;
    }
  }
`;

export const FormLimiter = styled.form`
  button {
    width: 100%;
  }
  .text {
    color: #62980E;
    font-weight: 400;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 590px;
    margin: 0 auto;
  }
`;

export const ForgotLink = styled.div`
  text-align: left;
  z-index: 100;
  position: sticky;

  a {
    font-size: 13px;
    color: ${theme.palette.secondary.main};
    font-family: ${theme.typography.secondary.bold};
    text-decoration: none;
  }
`;

export const ButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    @media (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 316px;
    }
  }
`;

export const TextHolder = styled.div`
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px 0;
`

export const SuccessMessage = styled.p`
  text-align: center;
  color: ${theme.palette.success.main} !important;
  margin: 0;
`;
