import styled from "styled-components";
import theme from "~/styles/theme";

export const TextHolder = styled.div`
  color: ${theme.palette.black.main};

  // p {
  //   font-size: 14px;
  //   color: ${theme.palette.black.main};

  //   @media (min-width: ${theme.breakpoints.values.md}px) {
  //     font-size: 12px;
  //   }
  // }
`;

export const CardAbstract = styled.div`
  width: 100%;
  border: 1px solid ${theme.palette.black.lighter};
  border-radius: 8px;
  padding: 40px;
  margin-bottom: 32px;
  text-align: left;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: 24px;
  }

  h3,
  p {
    color: ${theme.palette.black.main};
  }

  .gray {
    color: ${theme.palette.black.lighter};
  }
`;

export const CheckBoxLabel = styled.label`
  cursor: pointer;
  color: ${theme.palette.black.main};
  width: 100%;
  display: block;
  border-radius: 16px;
  margin 30px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    display: inline-block;
    color: ${theme.palette.black.main};
    vertical-align: middle;
  }
  span {
    color: ${(props) =>
      props.checked ? theme.palette.black.main : theme.palette.black.lighter};
    margin-right: 8px;
  }
`;

export const SubmitButtonHolder = styled.div`
  text-align: center;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    text-align: left;
  }

  button {
    width: 100%;
    margin-top: 26px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      width: 202px;
      padding: 14px 19px;
      margin-top: 32px;
    }
  }
`;
