import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ReactSVG from "react-svg";
import { Grid } from "@material-ui/core";
import { Margin, Title, Text } from "~/components/elements";
import EditSVG from "~/assets/img/icon/editar.svg";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import { genderSelect } from "~/utils/variables";
import { formatDateView } from "~/utils/tools";
import { AccountPersonal, AccountContact, AccountCommunication } from "..";

export default function AccountMain({
  mobile,
  personalData,
  contactData,
  editClick,
  // handleModal,
  // resendConfirmNewEmail,
  // emailValidated,
  setEdit,
  menuClick,
  edit,
}) {
  const history = useHistory();
  const returnGender = (value) => {
    return genderSelect.find((gender) => gender.value === value)?.label;
  };
  
  const loginToken = useSelector((state) => state.authentication.auth.loginToken);
  const interesses = useSelector((state) => state.account?.details?.interesses);
  const respostas = useSelector((state) => state.account?.details?.respostas);

  useEffect(() => {
    if (interesses && Object.keys(interesses).length && loginToken) {
      history.push("/minha-conta");
    }

    if ((!interesses || !Object.keys(interesses).length || !respostas) && loginToken) {
      history.push("/complemento");
    }
    //eslint-disable-next-line
  }, [loginToken, interesses, respostas]);

  return (
    <Margin mb={mobile ? 4 : 12}>
      <Grid container justifyContent="space-between">
        <S.AccountCard>
          <Margin mb={1}>
            <Title as="3" level="6" emphasis>
              Dados pessoais
            </Title>
          </Margin>
          <Margin sm={9}>
            {edit === 1 ? (
              <AccountPersonal
                mobile={mobile}
                personalData={personalData}
                setEdit={setEdit}
                menuClick={menuClick}
                edit={edit}
              />
            ) : (
              <>
                <S.CardAbstract>
                  <div>
                    <Margin mb={1}>
                      <Text level="1">Nome</Text>
                    </Margin>
                    <Text primary as="strong" level="2">
                      {personalData.Nome}
                    </Text>
                  </div>
                  <div>
                    <Margin mb={1}>
                      <Text level="1">Data de nascimento</Text>
                    </Margin>
                    <Text as="strong" primary level="2">
                      {formatDateView(personalData.DataNascimento)}
                    </Text>
                  </div>
                  <div>
                    <Margin mb={1}>
                      <Text level="1">Gênero</Text>
                    </Margin>

                    <Text as="strong" primary level="2">
                      {returnGender(personalData.Sexo)}
                    </Text>
                  </div>
                  <div>
                    <Margin mb={1}>
                      <Text level="1">Estado</Text>
                    </Margin>

                    <Text as="strong" primary level="2">
                      {personalData.Estado}
                    </Text>
                  </div>
                </S.CardAbstract>
                <S.CardLink
                  onClick={() => editClick(1)}
                  id="accountEditPersonalLink"
                >
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignContent="center"
                  >
                    <S.IconMargin>
                      <ReactSVG src={EditSVG} />
                    </S.IconMargin>
                    <Text as="strong" level="1" emphasis>
                      Editar dados
                    </Text>
                  </Grid>
                </S.CardLink>
              </>
            )}
          </Margin>
        </S.AccountCard>
        <S.AccountCard>
          <Margin mb={4}>
            <Title as="3" level="6" emphasis>
              Dados de contato
            </Title>
          </Margin>
          <S.CardContact>
            {edit === 2 ? (
              <AccountContact
                mobile={mobile}
                contactData={contactData}
                setEdit={setEdit}
                menuClick={menuClick}
                edit={edit}
              />
            ) : (
              <div>
                <div>
                  <Margin mb={1}>
                    <Text level="1">Telefone/Celular</Text>
                  </Margin>
                  <Text as="strong" primary level="2">
                    {contactData.TelCelular}
                  </Text>
                </div>
                <S.CardLink
                  onClick={() => editClick(2)}
                  id="accountEditContactLink"
                >
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignContent={mobile ? "flex-start" : "flex-end"}
                  >
                    <S.IconMargin>
                      <ReactSVG src={EditSVG} />
                    </S.IconMargin>
                    <Text as="strong" level="1" emphasis>
                      Editar dados
                    </Text>
                  </Grid>
                </S.CardLink>
              </div>
            )}

            <div>
              <div>
                <Margin mb={1}>
                  <Text level="1">E-mail</Text>
                </Margin>
                <Text as="strong" primary level="2">
                  {contactData.Email}
                </Text>
              </div>
              {/* <S.CardLinkHolder data-styled="CardLinkHolder">
                {!emailValidated ? (
                  <S.CardLink
                    onClick={() => resendConfirmNewEmail()}
                    id="accountResendConfirmLink"
                  >
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignContent="flex-end"
                    >
                      <S.ResendEmail>
                        <Text as="strong" level="1" emphasis underline>
                          Reenviar verificação
                        </Text>
                      </S.ResendEmail>
                    </Grid>
                  </S.CardLink>
                ) : null}

                <S.CardLink
                  onClick={() => handleModal("FormValidateLogin")}
                  id="accountEditContactLink"
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignContent="flex-end"
                  >
                    <S.IconMargin>
                      <ReactSVG src={EditSVG} />
                    </S.IconMargin>
                    <Text as="strong" level="1" emphasis>
                      Alterar e-mail
                    </Text>
                  </Grid>
                </S.CardLink>
              </S.CardLinkHolder> */}
            </div>
          </S.CardContact>
        </S.AccountCard>

        {/*
          <S.TitleHolder>
          <Margin mt={2} mb={2}>
            <Title as="3" level="6" primary>
              Senha cadastrada
            </Title>
          </Margin>
        </S.TitleHolder>
        <S.UpdatePasswordCard>
          <Grid
            container
            justifyContent="space-between"
            alignContent="flex-start"
          >
            <Title as="3" level="6" emphasis primary>
              Atualizar sua senha
            </Title>
            <S.UpdatePasswordActions>
              <Text level="1">
                Clique em atualizar senha para você mudar sua senha e
                atualizar na conta.
              </Text>
              <Link to="/atualizar-senha">
                <Text as="span" emphasis level="2">
                  Atualizar senha
                </Text>
                <ReactSVG wrapper="span" src={ArrowRight} />
              </Link>
            </S.UpdatePasswordActions>
          </Grid>
        </S.UpdatePasswordCard>
        */}
        
        <S.Comunication>
          <Margin mb={4}>
            <Title as="3" level="6" emphasis primary>
              Preferências
            </Title>
          </Margin>
          <AccountCommunication />
        </S.Comunication>
        <S.ContactUs>
          <Text level="1" primary>
            Se deseja ajustar alguma informação que não conseguiu por aqui,
            por favor,{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.johnsonsbaby.com.br/fale-conosco"
            >
              Fale Conosco.
            </a>
          </Text>
        </S.ContactUs>
      </Grid>
    </Margin>
  );
}
