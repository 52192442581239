import React, { useEffect, useState } from "react";
import { FormCode } from "~/components/blocks";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Formik } from "formik";
import axios from "axios";
import { FormSignUpComplement } from "~/components/blocks";

import {
  Text,
  Margin,
  Input,
  Button,
  Loading,
  ErrorMessage,
  SocialButton,
} from "~/components/elements";
import { schema } from "./validation";
import { Creators as AuthenticationActions } from "~/store/modules/authentication/actions";
import { Creators as SignUpActions } from "~/store/modules/signUp/actions";
import isMobile from "~/hooks/isMobile";

import {
  //useGoogleOneTapLogin,
  useGoogleLogin,
} from "@react-oauth/google";

import * as S from "./styles";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

export default function FormSignUp({ participanteHash }) {
  const mobile = isMobile();
  const history = useHistory();
  const [socialUser, setSocialUser] = useState({
    name: null,
    given_name: null,
    family_name: null,
    email: null,
    picture: null,
    userID: null,
    email_verified: false,
  });
  const dispatch = useDispatch();
  const sending = useSelector((state) => state.authentication.auth.sending);
  //const logged = useSelector((state) => state.authentication.auth.loginToken);
  const completeSignUp = useSelector(
    (state) => state.authentication.auth.user.cadastroCompleto
  );
  const errorMessage = useSelector(
    (state) => state.authentication.auth.errorMessage
  );
  const [userEmail, setUserEmail] = useState(null);
  const [showValidationCode, setShowValidationCode] = useState(null);
  const showFormSignUpComplement = useSelector(
    (state) => state.authentication.auth.showFormSignUp
  );
  const loginToken = useSelector(
    (state) => state.authentication.auth.loginToken
  );
  const interesses = useSelector((state) => state.account?.details?.interesses);
  const respostas = useSelector((state) => state.account?.details?.respostas);
  const [midiaParams, setMidiaParams] = useState({});
  const showMsg = useSelector((state) => state.authentication.auth.showMsg);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramObj = {};
    for (const [key, value] of queryParams.entries()) {
      paramObj[key] = value;
    }
    setMidiaParams(JSON.stringify(paramObj));
  }, [location]);

  const url = process.env.REACT_APP_HOST_URL;
  const origin = process.env.REACT_APP_ORIGIN;

  const initialValues = {
    Email: "",
    Senha: "",
  };

  useEffect(() => {
    dispatch(AuthenticationActions.setAuthenticationMessage());
    //eslint-disable-next-line
  }, [dispatch, completeSignUp]);

  useEffect(() => {
    if (interesses && Object.keys(interesses).length && loginToken) {
      history.push("/minha-conta");
    }

    if (
      (!interesses || !Object.keys(interesses).length || !respostas) &&
      loginToken
    ) {
      history.push("/complemento");
    }
    //eslint-disable-next-line
  }, [loginToken, interesses, respostas]);

  const socialLoginMessage = async (response) => {
    let userInfo = {};
    if (response.access_token) {
      userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${response.access_token}` },
        })
        .then((res) => res.data)
        .catch((error) => failureMessage(error));
    } else {
      userInfo = jwt_decode(response?.credential);
    }
    setSocialUser({
      name: userInfo?.name,
      given_name: userInfo?.given_name,
      family_name: userInfo?.family_name,
      email: userInfo?.email,
      picture: userInfo?.picture,
      userID: userInfo?.sub,
      email_verified: userInfo?.email_verified,
    });
  };

  //useGoogleOneTapLogin({
  //  onSuccess: (credentialResponse) => socialLoginMessage(credentialResponse),
  // onError: (data) => failureMessage(data),
  //});

  const loginGoogle = useGoogleLogin({
    onSuccess: (data) => socialLoginMessage(data),
    onError: (data) => failureMessage(data),
  });

  const failureMessage = (error) => {
    console.log(error);
  };

  return (
    <S.FormHolder>
      {/*
      <Margin mb={4}>
        <Text as="strong" level={mobile ? "2" : "1"}>
          Entre com sua conta Google{" "}
        </Text>
      </Margin>
      <Margin mb={2}>
        <SocialButton
          text="Entrar com o Google"
          typeIcon="google"
          onClick={() => loginGoogle()}
        />
      </Margin>

      <Margin mb={4}>
        <FacebookLogin
          appId="676402717814782"
          fields="name,email,picture"
          render={(renderProps) => (
            <SocialButton
              text="Entrar com o Facebook"
              typeIcon="facebook"
              onClick={renderProps.onClick}
            />
          )}
          callback={(userInfo) => {
            const fullName = userInfo?.name?.split(" ");
            setSocialUser({
              name: userInfo?.name,
              email: userInfo?.email,
              userID: userInfo?.userID,
              given_name: fullName[0],
              family_name: fullName[1],
              picture: null,
              email_verified: !!userInfo?.userID,
            });
          }}
        />
      </Margin>

      <Margin mb={4}>
        <fieldset>
          <legend>
            <Text as="strong" level={mobile ? "2" : "1"}>
              ou
            </Text>
          </legend>
        </fieldset>
      </Margin>
 */}
      {!socialUser.email_verified && (
        <Margin mb={5}>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              setUserEmail(values.Email);
              dispatch(
                AuthenticationActions.sendAuthentication({
                  ...values,
                  url: url,
                  origin: origin,
                  TipoValidacao: 2,
                  email: userEmail,
                  AssuntoEmail: "Chegou o seu código Johnson´s® Baby",
                })
              );
              setShowValidationCode(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              dirty,
            }) => (
              <S.FormLimiter onSubmit={handleSubmit}>
                <Margin mb={mobile ? 3 : 4}>
                  <Input
                    label="E-mail"
                    placeholder="Qual e-mail você utiliza mais?"
                    htmlFor="emailFieldFormLogin"
                    name="Email"
                    value={values.Email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.Email && touched.Email && errors.Email}
                    valid={!errors.Email && touched.Email && !errors.Email}
                    required
                  />
                </Margin>
                <Margin mb={mobile ? 3 : 4}>
                  {!errorMessage && showMsg && (
                    <Margin mt={3} mb={3}>
                      <Text level="2" as="strong" className="text">
                        O código de verificação foi enviado. Por favor, insira
                        ele no campo abaixo.
                      </Text>
                    </Margin>
                  )}
                </Margin>

                {!showFormSignUpComplement ? (
                  <S.ButtonHolder>
                    <Button
                      className="animation-size"
                      color={
                        !isValid || !dirty || sending ? "disabled" : "secondary"
                      }
                      type="submit"
                      disabled={!isValid || !dirty || sending}
                      id="sendButtonFormLogin"
                    >
                      {sending ? (
                        <Loading button />
                      ) : (
                        "Enviar código de verificação"
                      )}
                    </Button>
                  </S.ButtonHolder>
                ) : null}
              </S.FormLimiter>
            )}
          </Formik>
        </Margin>
      )}

      {showValidationCode && showMsg && (
        <FormCode
          userEmail={userEmail}
          showFormSignUpComplement={showFormSignUpComplement}
          tipoValidacao={2}
        />
      )}

      {(socialUser.email_verified || showFormSignUpComplement) && (
        <FormSignUpComplement
          userEmail={userEmail}
          userEmailSocial={socialUser.email}
          userGivenName={socialUser.given_name}
          userFamilyName={socialUser.family_name}
          userID={socialUser.userID}
        />
      )}

      <Margin mb={mobile ? 5 : 12}>
        <S.TextHolder>
          <Margin mb={2}>
            <Text level="1" as="strong">
              Você já tem uma conta?
            </Text>
          </Margin>
          <Button
            className="animation-size"
            color={"secondary"}
            variant="outlined"
            id="goToLogin"
            onClick={() => {
              dispatch(AuthenticationActions.purgeAuthentication());
              history.push("/login");
            }}
          >
            Fazer login
          </Button>
        </S.TextHolder>
      </Margin>

      {errorMessage && !participanteHash && (
        <Margin mb={3}>
          <ErrorMessage inset>{errorMessage}</ErrorMessage>
        </Margin>
      )}
    </S.FormHolder>
  );
}
