import styled from "styled-components";
import theme from "~/styles/theme";

export const AddInfoButtonHolder = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    justify-content: flex-end;
  }

  button {
    width: 100%;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 200px;
    }
  }
`;
