import styled from "styled-components";
import { Grid } from "@material-ui/core";
import theme from "~/styles/theme";

export const ContentHolder = styled(Grid)`
  padding: 0 16px;
  text-align: left;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    min-height: calc(100vh - 429px);
    text-align: left;
  }
`;
export const NavbarHolder = styled.div`
  width: 100%;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 370px;
  }
`;

export const AccountMenu = styled.ul`
  width: 100%;
  border: 1px solid ${theme.palette.menu.border};
  border-radius: 8px;
  overflow: hidden;
  text-align: left;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 272px;
  }
`;

export const MenuItem = styled.li`
  border-bottom: 1px solid ${theme.palette.menu.innerborder};
  padding: 32px 40px;
  cursor: pointer;
  background: ${(props) =>
    props.selected ? theme.palette.menu.selected : theme.palette.menu.bg};
  &:last-child {
    border: none;
  }
  p {
    color: ${theme.palette.primary.main};
  }
`;

export const CardsHolder = styled.div`
  width: 100%;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 752px;
  }
`;

export const TitleHolder = styled.div`
  width: 100%;
  padding: 0 16px;

  h1 {
    text-align: left;
  }
`;

export const NameHolder = styled.div`
  margin-bottom: 24px;
  h5 {
    display: flex;
    align-items: center;
  }

  svg {
    width: 38px;
    height: 38px;
  }
`