import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field } from "formik";
import { Margin, Checkbox, Button, Title, Text } from "~/components/elements";
import { Creators as AccountActions } from "~/store/modules/account/actions";
import * as S from "./styles";

export default function AccountCommunication() {
  const dispatch = useDispatch();
  const acceptNews = useSelector((state) => state?.account?.details?.dadosPessoais?.AceitaReceberNoticias);
  const [AcceptNewsCheck, SetAcceptNewsCheck] = useState(acceptNews);
  
  useEffect(() => {
    SetAcceptNewsCheck(acceptNews)
  }, [acceptNews])

  const initialValues = {
    AceitaReceberNoticias: AcceptNewsCheck,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        const payloadValues = {
          ...values,
          AceitaReceberNoticias: values.AceitaReceberNoticias,
        };
        dispatch(AccountActions.sendUpdateCommunication(payloadValues));
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <S.CardAbstract>
            <Margin mb={1}>
              <S.TextHolder>
                <Title as="3" level="6" emphasis>
                  Comunicação
                </Title>
                <Text level="1">
                  Aqui, você pode escolher se gostaria de receber ou não mensagens personalizadas.
                </Text>
              </S.TextHolder>
            </Margin>
            <Margin mb={2}>
              <S.CheckBoxLabel checked={AcceptNewsCheck}>
                <Field
                  component={Checkbox}
                  type="checkbox"
                  name="AceitaReceberNoticias"
                  checked={AcceptNewsCheck}
                  onClick={() => {
                    SetAcceptNewsCheck(!AcceptNewsCheck);
                  }}
                />
                <Text level="2">Aceite de comunicações personalizadas</Text>
              </S.CheckBoxLabel>
            </Margin>
            <S.SubmitButtonHolder>
              <Button
                className="animation-size"
                type="submit"
                color={"primary"}
              >
                Salvar alteração
              </Button>
            </S.SubmitButtonHolder>
          </S.CardAbstract>
        </form>
      )}
    </Formik>
  );
}
