import axios from "axios";
import history from "~/routes/history";
import { store } from "~/store";
import { Creators as AuthenticationActions } from "~/store/modules/authentication/actions";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

api.interceptors.response.use(
  (response) => {
    if (response.data.forceLogout) {
      const { dispatch } = store;
      localStorage.clear();
      dispatch(AuthenticationActions.purgeAuthentication());
      history.push("/login");
    }
    return response;
  },
  (error) => {
    return error;
  }
);

export default api;
