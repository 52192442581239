import { Grid } from "@material-ui/core";
import styled from "styled-components";
import theme from "~/styles/theme";

export const FormHolder = styled.div`
  padding: 0 16px;
  margin: 0 auto;
  p {
    color: ${theme.palette.black.main};
    span {
      cursor: pointer;
      font-family: ${theme.typography.secondary.bold};
      text-decoration: underline;
    }
    strong {
      font-family: ${theme.typography.secondary.bold};
    }
  }
  .title-email {
    text-align: left;
  }
  .text-email {
    color: #454545;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin: 16px 0;
    text-align: left;
  }
  .subtext-email {
    font-size: 14px;
    line-height: 130%;
    text-align: left;
    a {
      color: #3797DB;
      text-decoration: none;
    }
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0;
    max-width: 590px;
    .title-email h2 {
      font-size: 28px;
    }
    .text-email {
      font-size: 18px;
    }
  }
`;

export const GridHolder = styled.div`
  margin: -8px;
  #stateFieldFormSignup {
    text-align: left;
  }
`;

export const CheckboxGroup = styled.div`
  label, .title-checkbox {
    text-align: left;
  }
  .title-checkbox {
    margin-top: 24px;
  }
  .contact-checkbox {
    display: flex;
  }  
  Text {
    color: #454545;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
`;

export const FormLimiter = styled.form`
  label {
    display: flex;
    > div {
      margin-left: 26px;
    }
    a {
      color: #3797DB;
      text-decoration: none;
    }
  }  
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 590px;
    margin: 0 auto;
  }
`;

export const ButtonsGrid = styled(Grid)`
  button {
    width: 100%;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    button {
      width: auto;
    }
  }
`;