import styled from "styled-components";
import theme from "~/styles/theme";

export const ContentHolder = styled.div`
  text-align: center;
  width: 100vw;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 1128px;
    padding: initial;
  }

  p {
    color: ${(props) =>
      props.success ? theme.palette.primary.main : theme.palette.error.main};
  }

  button {
    width: 100%;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      max-width: fit-content;
      margin: 0 auto;
    }
  }
`;

export const Feedback = styled.div`
  width: 100%;
  border-radius: 32px;
  margin: 0 auto;
  margin-bottom: 24px;
  padding: 24px 15px;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 385px;
    padding: 24px 0;
    margin-bottom: 16px;
  }
`;

export const Banner = styled.div`
  width: 100%;
  background-color: #dceefa;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 54px;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    flex-direction: row;
    margin-bottom: initial;
  }

  .banner {
    width: 100%;
    border-radius: 16px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      width: fit-content;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 272px;
    padding: 24px 0;
    margin: 0 auto;
    text-align: center;
    color: #005cb7;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      width: 486px;
      padding: 0 12px;
    }

    .title,
    .description {
      font-size: 24px;
      font-weight: 700;
      color: #005cb7;
      padding: 0 10px;
    }

    .description {
      font-size: 16px;
    }

    p {
      color: #606060;
    }

    .cupom {
      color: #606060;
      font-size: 24px;
    }

    #loginButtonVerify {
      width: 272px;
      max-width: 100%;

      @media (min-width: ${theme.breakpoints.values.md}px) {
        width: 470px;
      }
    }

    .link-parceiro {
      display: block;
      margin: 0 auto;
      width: 272px;
      font-size: 16px;
      text-transform: uppercase;
      border-radius: 28px;
      padding: 14px;
      line-height: 24px;
      cursor: pointer;
      background: ${theme.palette.secondary.main};
      text-decoration: none;
      font-weight: bold;
      color: ${theme.palette.secondary.light};
      border: 2px solid ${theme.palette.secondary.main};

      @media (min-width: ${theme.breakpoints.values.md}px) {
        // padding: 14px 32px;
        width: 470px;
        margin: 0 auto;
      }

      &:hover {
        transition: all 0.3s ease-in-out;
        background: ${theme.palette.secondary.dark};
        border: 2px solid ${theme.palette.secondary.dark};
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
`;
