import styled from "styled-components";
import theme from "~/styles/theme";

export const FooterWrapper = styled.footer`
  background-color: ${theme.palette.brand.primary.mediumLight};
  padding: 30px 22px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 768px) {
    padding: 30px 160px 40px;
  }
`;

export const LogosWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 48px 0 32px;
  }
`;

export const Logo = styled.img`
  width: 81px;
  height: 33px;
  display: block;
  margin-left: 10px;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 30px 0;

  img {
    width: 40px;
    height: 40px;
    margin: 0 12px;
  }

  @media (min-width: 768px) {
    margin: 0;
    gap: 16px;
    img {
      width: 32px;
      height: 32px;
      margin: 0;
    }
}

  }
`;

export const Divider = styled.hr`
  border: none;
  border-top: 1px solid black;
  width: 100%;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 16px 0;
  }
`;

export const Link = styled.a`
  color: ${theme.palette.neutral.secondary.dark};
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  text-decoration: none;
  margin: 10px 0;

  @media (min-width: 768px) {
    margin: 0;
  }
  `;
  
  export const LegalText = styled.p`
  color: ${theme.palette.neutral.secondary.dark};
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  margin-top: 20px;
  
  @media (min-width: 768px) {
    margin-top: 32px;
  }
`;
