import * as Yup from "yup";
import MESSAGE from "~/utils/messages";
import { isValid, parse, differenceInYears } from "date-fns";

export const schema = (childButton, childNumbers) => Yup.object().shape({
  DataNascimento: Yup.mixed()
    .required(MESSAGE.requiredField)
    .test({
      name: "birthDate",
      message: MESSAGE.invalidDate,
      test: (value) =>
        value &&
        isValid(parse(value, "dd/MM/yyyy", new Date())) &&
        parse(value, "dd/MM/yyyy", new Date()) >= new Date("01/01/1900"),
    })
    .test({
      name: "birthDate",
      message: MESSAGE.legallyYounger,
      test: (value) =>
        differenceInYears(new Date(), parse(value, "dd/MM/yyyy", new Date())) >=
        18,
    }),
  Sexo: Yup.object().shape({
    label: Yup.string().required(MESSAGE.requiredSelect),
    value: Yup.string().required(MESSAGE.requiredSelect),
    id: Yup.number().required(MESSAGE.requiredSelect),
  }),
  // ChegadaDoBebe: Yup.array()
  //   .of(Yup.string())
  //   .required(MESSAGE.requiredCheck),
  // DicasDeCuidado: Yup.array()
  //   .of(Yup.string())
  //   .required(MESSAGE.requiredCheck),
  // FasesDoDesenvolvimento: Yup.array()
  //   .of(Yup.string())
  //   .required(MESSAGE.requiredCheck),
  // SaudeMentalMaterna: Yup.array()
  //   .of(Yup.string())
  //   .required(MESSAGE.requiredCheck),
  // SegurancaEIngredientes: Yup.array()
  //   .of(Yup.string())
  //   .required(MESSAGE.requiredCheck),
  // BrandCommunication: Yup.bool().oneOf([true], MESSAGE.requiredCheck),
  PossuiFilhos: Yup.string().required(MESSAGE.requiredSelect),
  //QuantosFilhos: Yup.string().required(MESSAGE.requiredSelect),
  QuantosFilhos: Yup.string().when(['PossuiFilhos'], {
    is: (PossuiFilhos) => {
        return PossuiFilhos === 'simButton';
      },
      then: Yup.string().required(MESSAGE.requiredSelect),
      otherwise: Yup.string().nullable().notRequired(),
    }
  ),

  // Add validation for FaixaEtaria dynamically
  ...Array.from({ length: parseInt(childNumbers, 10) || 0 }, (_, index) => ({
    [`FaixaEtaria${index + 1}`]: Yup.string().when(['childButton', 'childNumbers'], {
      is: (childButton, childNumbers) => childButton === 'simButton' && childNumbers !== 'fiveMore',
      then: Yup.string().required(MESSAGE.requiredSelect),
      otherwise: Yup.string(),
    }),
  })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
});
