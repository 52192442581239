import produce from "immer";
import { Types as PromoTypes } from "./actions";

const INITIAL_STATE = {
  step: 1,
  nurseries: {
    loading: false,
    errorMessage: "",
    list: [],
  },
  interests: {
    loading: false,
    errorMessage: "",
    list: [],
  },
  register: {
    sending: false,
    errorMessage: "",
  },
  productsAndTips: {
    loading: false,
    errorMessage: "",
    produtosRecomendados: [],
    dicasECuidados: [],
    user: {}
  },
  coupon: {
    loading: false,
    errorMessage: "",
    response: {}
  },
  existingUser: {
    loading: false,
    errorMessage: "",
    response: {}
  }
};

export default function promo(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      // Step
      case PromoTypes.SET_REGISTER_STEP: {
        draft.step = action.payload;
        break;
      }

      // Nurseries
      case PromoTypes.GET_NURSERIES: {
        draft.nurseries.loading = true;
        break;
      }
      case PromoTypes.GET_NURSERIES_SUCCESS: {
        draft.nurseries.loading = false;
        draft.nurseries.list = action.payload.maternidades.map((item) => ({
          id: item.Id,
          value: item.Id,
          label: item.Nome,
        }));
        break;
      }
      case PromoTypes.GET_NURSERIES_FAIL: {
        draft.nurseries.loading = false;
        draft.nurseries.errorMessage = action.payload.msg;
        break;
      }

      // Interests
      case PromoTypes.GET_INTERESTS: {
        draft.interests.loading = true;
        break;
      }
      case PromoTypes.GET_INTERESTS_SUCCESS: {
        draft.interests.loading = false;
        draft.interests.list = action.payload.interesses;
        break;
      }
      case PromoTypes.GET_INTERESTS_FAIL: {
        draft.interests.loading = false;
        draft.interests.errorMessage = action.payload.msg;
        break;
      }

      // Send Register
      case PromoTypes.SEND_REGISTER: {
        draft.register.sending = true;
        draft.register.errorMessage = INITIAL_STATE.register.errorMessage;
        break;
      }
      case PromoTypes.SEND_REGISTER_SUCCESS: {
        draft.register.sending = false;
        break;
      }
      case PromoTypes.SEND_REGISTER_FAIL: {
        draft.register.sending = false;
        draft.register.errorMessage = action.payload.msg;
        break;
      }
      case PromoTypes.RESET_SEND_REGISTER: {
        draft.register = INITIAL_STATE.register;
        draft.step = INITIAL_STATE.step;
        break;
      }
      case PromoTypes.GET_PAT: {
        draft.productsAndTips.loading = true;
        break;
      }
      case PromoTypes.GET_PAT_SUCCESS: {
        draft.productsAndTips.loading = false;
        draft.productsAndTips.produtosRecomendados =
          action.payload.produtosRecomendados;
        draft.productsAndTips.dicasECuidados = action.payload.dicasECuidados;
        draft.productsAndTips.user = action.payload.user;
        draft.productsAndTips.brindes = action.payload.brindes;
        break;
      }
      case PromoTypes.GET_PAT_FAIL: {
        draft.productsAndTips.loading = false;
        draft.productsAndTips.errorMessage = action.payload.msg;
        break;
      }

      case PromoTypes.SEND_COUPON: {
        draft.coupon.sending = true;
        draft.coupon.errorMessage = INITIAL_STATE.coupon.errorMessage;
        break;
      }

      case PromoTypes.SEND_COUPON_FAIL: {
        draft.coupon.sending = false;
        draft.coupon.errorMessage = action.payload.msg;
        break;
      }

      case PromoTypes.SEND_COUPON_SUCCESS: {
        draft.coupon.sending = false;
        draft.coupon.response = action.payload;
        break;
      }

      // validate cpf
      case PromoTypes.GET_EXISTING_USER: {
        draft.existingUser.loading = true;
        draft.existingUser.errorMessage = INITIAL_STATE.coupon.errorMessage;
        break;
      }
      case PromoTypes.GET_EXISTING_USER_SUCCESS: {
        draft.existingUser.loading = false;
        draft.existingUser.response = action.payload;
        break;
      }
      case PromoTypes.GET_EXISTING_USER_FAIL: {
        draft.existingUser.loading = false;
        draft.existingUser.errorMessage = action.payload.msg;
        break;
      }
      default:
    }
  });
}
