import styled from "styled-components";
import theme from "~/styles/theme";

export const Shade = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: ${theme.modalGradient};
  z-index: ${(props) => (props.open ? 100 : -1)};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: ${theme.transition};
  cursor: pointer;
`;

export const ModalBox = styled.div`
  border-radius: 16px;
  width: calc(100vw - 32px);
  padding: 32px 20px 32px 23px;
  background: ${theme.palette.secondary.light};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,10%);
  z-index: ${(props) => (props.open ? 101 : -1)};
  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 556px;
    padding: 48px;
  }
`;

export const Exit = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
`;
