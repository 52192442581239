export const Types = {
  // login
  SEND_AUTHENTICATION: "authentication/SEND_AUTHENTICATION",
  SEND_AUTHENTICATION_SUCCESS: "authentication/SEND_AUTHENTICATION_SUCCESS",
  SEND_AUTHENTICATION_FAIL: "authentication/SEND_AUTHENTICATION_FAIL",
  SET_AUTHENTICATION_MESSAGE: "authentication/SET_AUTHENTICATION_MESSAGE",
  SET_COMPLETE_SIGNUP: "authentication/SET_COMPLETE_SIGNUP",

  // login code
  SEND_AUTHENTICATION_CODE: "authentication/SEND_AUTHENTICATION_CODE",
  SEND_AUTHENTICATION_CODE_SUCCESS: "authentication/SEND_AUTHENTICATION_CODE_SUCCESS",
  SEND_AUTHENTICATION_CODE_FAIL: "authentication/SEND_AUTHENTICATION_CODE_FAIL",
  SET_AUTHENTICATION_CODE_MESSAGE: "authentication/SET_AUTHENTICATION_CODE_MESSAGE",
  SHOW_CODE_SUCCESS: "authentication/SHOW_CODE_SUCCESS",

  // validate auth
  SEND_VALIDATE_AUTHENTICATION: "authentication/SEND_VALIDATE_AUTHENTICATION",
  SEND_VALIDATE_AUTHENTICATION_FAIL: "authentication/SEND_VALIDATE_AUTHENTICATION_FAIL",
  SEND_VALIDATE_AUTHENTICATION_SUCCESS: "authentication/SEND_VALIDATE_AUTHENTICATION_SUCCESS",
  SET_VALIDATE_AUTH: "authentication/SET_VALIDATE_AUTH",

  // logout
  PURGE_AUTHENTICATION: "authentication/PURGE_AUTHENTICATION",

  // forgot password
  SEND_FORGOT_PASS: "authentication/SEND_FORGOT_PASS",
  SEND_FORGOT_PASS_SUCCESS: "authentication/SEND_FORGOT_PASS_SUCCESS",
  SEND_FORGOT_PASS_FAIL: "authentication/SEND_FORGOT_PASS_FAIL",
  SEND_FORGOT_PASS_RESET: "authentication/SEND_FORGOT_PASS_RESET",

  // social login
  SEND_SOCIAL_AUTHENTICATION: "authentication/SEND_SOCIAL_AUTHENTICATION",
  SEND_SOCIAL_AUTHENTICATION_SUCCESS: "authentication/SEND_SOCIAL_AUTHENTICATION_SUCCESS",
  SEND_SOCIAL_AUTHENTICATION_FAIL: "authentication/SEND_SOCIAL_AUTHENTICATION_FAIL",
  SEND_SOCIAL_AUTHENTICATION_INFO: "authentication/SEND_SOCIAL_AUTHENTICATION_INFO",
};

export const Creators = {
  // login
  sendAuthentication: (data) => ({
    type: Types.SEND_AUTHENTICATION,
    payload: data,
  }),
  sendAuthenticationSuccess: (data) => ({
    type: Types.SEND_AUTHENTICATION_SUCCESS,
    payload: data,
  }),
  sendAuthenticationFail: (data) => ({
    type: Types.SEND_AUTHENTICATION_FAIL,
    payload: data,
  }),
  setAuthenticationMessage: () => ({ type: Types.SET_AUTHENTICATION_MESSAGE }),

  // login code
  sendAuthenticationCode: (data) => ({
    type: Types.SEND_AUTHENTICATION_CODE,
    payload: data,
  }),
  sendAuthenticationCodeSuccess: (data) => ({
    type: Types.SEND_AUTHENTICATION_CODE_SUCCESS,
    payload: data,
  }),
  sendAuthenticationCodeFail: (data) => ({
    type: Types.SEND_AUTHENTICATION_CODE_FAIL,
    payload: data,
  }),
  setAuthenticationCodeMessage: () => ({ type: Types.SET_AUTHENTICATION_CODE_MESSAGE }),
  setShowFormSignUp: () => ({ type: Types.SHOW_CODE_MESSAGE }),

  // validate auth
  sendValidateAuthentication: (data) => ({
    type: Types.SEND_VALIDATE_AUTHENTICATION,
    payload: data,
  }),
  sendValidateAuthenticationSuccess: () => ({
    type: Types.SEND_VALIDATE_AUTHENTICATION_SUCCESS,
  }),
  sendValidateAuthenticationFail: () => ({
    type: Types.SEND_VALIDATE_AUTHENTICATION_FAIL,
  }),
  setValidateAuth: () => ({
    type: Types.SET_VALIDATE_AUTH,
  }),
  setCompleteSignup: () => ({ type: Types.SET_COMPLETE_SIGNUP }),

  // logout
  purgeAuthentication: () => ({ type: Types.PURGE_AUTHENTICATION }),

  // forgot password
  sendForgotPassword: (data) => ({
    type: Types.SEND_FORGOT_PASS,
    payload: data,
  }),
  sendForgotPasswordSuccess: (data) => ({
    type: Types.SEND_FORGOT_PASS_SUCCESS,
    payload: data,
  }),
  sendForgotPasswordFail: (data) => ({
    type: Types.SEND_FORGOT_PASS_FAIL,
    payload: data,
  }),
  sendForgotPasswordReset: () => ({ type: Types.SEND_FORGOT_PASS_RESET }),
  
  // Social Login
  sendSocialAuthenticationInfo: (data) => ({ type: Types.SEND_SOCIAL_AUTHENTICATION_INFO, payload: data, }),
  sendSocialAuthentication: (data) => ({ type: Types.SEND_SOCIAL_AUTHENTICATION, payload: data, }),
  sendSocialAuthenticationSuccess: () => ({ type: Types.SEND_SOCIAL_AUTHENTICATION_SUCCESS }),
  sendSocialAuthenticationFail: () => ({ type: Types.SEND_SOCIAL_AUTHENTICATION_FAIL }),
};
