export const Types = {
  // Form Send
  SEND_SIGNUP_FORM: "signUp/SEND_SIGNUP_FORM",
  SEND_SIGNUP_SUCCESS: "signUp/SEND_SIGNUP_SUCCESS",
  SEND_SIGNUP_FAIL: "signUp/SEND_SIGNUP_FAIL",
  SET_SIGNUP_DATA: "signUp/SET_SIGNUP_DATA",
  // Form Complement
  SEND_COMPLEMENT_FORM: "signUp/SEND_COMPLEMENT_FORM",
  SEND_COMPLEMENT_SUCCESS: "signUp/SEND_COMPLEMENT_SUCCESS",
  SEND_COMPLEMENT_FAIL: "signUp/SEND_COMPLEMENT_FAIL",
  SET_COMPLEMENT_DATA: "signUp/SET_COMPLEMENT_DATA",
  // Confirm SignUp
  SEND_CONFIRM_CODE: "signUp/SEND_CONFIRM_CODE",
  SEND_CONFIRM_SUCCESS: "signUp/SEND_CONFIRM_SUCCESS",
  SEND_CONFIRM_FAIL: "signUp/SEND_CONFIRM_FAIL",
  SET_CONFIRM_DATA: "signUp/SET_CONFIRM_DATA",
  // Resend Email
  RESEND_CONFIRM_EMAIL: "signUp/RESEND_CONFIRM_EMAIL",
  RESEND_CONFIRM_SUCCESS: "signUp/RESEND_CONFIRM_SUCCESS",
  RESEND_CONFIRM_FAIL: "signUp/RESEND_CONFIRM_FAIL",
  SET_RESEND_DATA: "signUp/SET_RESEND_DATA",
  // Confirm new email
  RESEND_CONFIRM_CODE_NEW_EMAIL: "signUp/RESEND_CONFIRM_CODE_NEW_EMAIL",
  RESEND_CONFIRM_CODE_NEW_EMAIL_SUCCESS: "signUp/RESEND_CONFIRM_CODE_NEW_EMAIL_SUCCESS",
  RESEND_CONFIRM_CODE_NEW_EMAIL_FAIL: "signUp/RESEND_CONFIRM_CODE_NEW_EMAIL_FAIL",
  SET_RESEND_CONFIRM_CODE_NEW_EMAIL: "signUp/SET_RESEND_CONFIRM_CODE_NEW_EMAIL",
  // Social SignUp
  SEND_SOCIAL_SIGNUP_INFO: "signUp/SEND_SOCIAL_SIGNUP_INFO",
};

export const Creators = {
  // Form Send
  sendSignUpForm: (data) => ({ type: Types.SEND_SIGNUP_FORM, payload: data }),
  sendSignUpSuccess: (data) => ({
    type: Types.SEND_SIGNUP_SUCCESS,
    payload: data,
  }),
  sendSignUpFail: (data) => ({ type: Types.SEND_SIGNUP_FAIL, payload: data }),
  setSignUpData: () => ({ type: Types.SET_SIGNUP_DATA }),
  
  // Form Complement
  sendComplementForm: (data) => ({ type: Types.SEND_COMPLEMENT_FORM, payload: data }),
  sendComplementSuccess: (data) => ({
    type: Types.SEND_COMPLEMENT_SUCCESS,
    payload: data,
  }),
  sendComplementFail: (data) => ({ type: Types.SEND_COMPLEMENT_FAIL, payload: data }),
  setComplementData: () => ({ type: Types.SET_COMPLEMENT_DATA }),

  // Confirm SignUp
  sendConfirmCode: (data) => ({ type: Types.SEND_CONFIRM_CODE, payload: data }),
  sendConfirmSuccess: (data) => ({
    type: Types.SEND_CONFIRM_SUCCESS,
    payload: data,
  }),
  sendConfirmFail: (data) => ({ type: Types.SEND_CONFIRM_FAIL, payload: data }),
  setConfirmData: () => ({ type: Types.SET_CONFIRM_DATA }),

  // Resend Email
  resendConfirmEmail: (data) => ({
    type: Types.RESEND_CONFIRM_EMAIL,
    payload: data,
  }),
  resendConfirmSuccess: (data) => ({
    type: Types.RESEND_CONFIRM_SUCCESS,
    payload: data,
  }),
  resendConfirmFail: (data) => ({
    type: Types.RESEND_CONFIRM_FAIL,
    payload: data,
  }),
  setResendData: () => ({ type: Types.SET_RESEND_DATA }),

  // Social SignUp
  setSocialSignUpInfo: () => ({ type: Types.SEND_SOCIAL_SIGNUP_INFO }),
};
