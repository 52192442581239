import { createGlobalStyle } from "styled-components";

// OpenSans
import OpenSansLightWoff from "~/assets/fonts/OpenSans-Light.woff";
import OpenSansLightWoff2 from "~/assets/fonts/OpenSans-Light.woff2";
import OpenSansLightTtf from "~/assets/fonts/OpenSans-Light.ttf";
import OpenSansRegularWoff from "~/assets/fonts/OpenSans-Regular.woff";
import OpenSansRegularWoff2 from "~/assets/fonts/OpenSans-Regular.woff2";
import OpenSansRegularTtf from "~/assets/fonts/OpenSans-Regular.ttf";
import OpenSansMediumWoff from "~/assets/fonts/OpenSans-Medium.woff";
import OpenSansMediumWoff2 from "~/assets/fonts/OpenSans-Medium.woff2";
import OpenSansMediumTtf from "~/assets/fonts/OpenSans-Medium.ttf";
import OpenSansBoldWoff from "~/assets/fonts/OpenSans-Bold.woff";
import OpenSansBoldWoff2 from "~/assets/fonts/OpenSans-Bold.woff2";
import OpenSansBoldTtf from "~/assets/fonts/OpenSans-Bold.ttf";

//  Johnsons Baby
import JBRegWoff from "~/assets/fonts/JB-Regular.woff";
import JBRegWoff2 from "~/assets/fonts/JB-Regular.woff2";
import JBRegOtf from "~/assets/fonts/JB-Regular.otf";
import JBBoldWoff from "~/assets/fonts/JB-Bold.woff";
import JBBoldWoff2 from "~/assets/fonts/JB-Bold.woff2";
import JBBoldOtf from "~/assets/fonts/JB-Bold.otf";

import theme from "./theme";
import reset from "./reset.css";

const GlobalStyle = createGlobalStyle`

@font-face{
  font-family: "OpenSans-Light";
  src:  url(${OpenSansLightWoff2}) format("woff2"), /* Super Modern Browsers */
        url(${OpenSansLightWoff}) format("woff"), /* Pretty Modern Browsers */
        url(${OpenSansLightTtf}) format("ttf"); /* Safari, Android, iOS */
  font-display: swap; /* Define how the browser behaves during download */
};
@font-face{
  font-family: "OpenSans-Regular";
  src:  url(${OpenSansRegularWoff2}) format("woff2"), /* Super Modern Browsers */
        url(${OpenSansRegularWoff}) format("woff"), /* Pretty Modern Browsers */
        url(${OpenSansRegularTtf}) format("ttf"); /* Safari, Android, iOS */
  font-display: swap; /* Define how the browser behaves during download */
};
@font-face{
  font-family: "OpenSans-Medium";
  src:  url(${OpenSansMediumWoff2}) format("woff2"), /* Super Modern Browsers */
        url(${OpenSansMediumWoff}) format("woff"), /* Pretty Modern Browsers */
        url(${OpenSansMediumTtf}) format("ttf"); /* Safari, Android, iOS */
  font-display: swap; /* Define how the browser behaves during download */
};
@font-face{
  font-family: "OpenSans-Bold";
  src:  url(${OpenSansBoldWoff2}) format("woff2"), /* Super Modern Browsers */
        url(${OpenSansBoldWoff}) format("woff"), /* Pretty Modern Browsers */
        url(${OpenSansBoldTtf}) format("ttf"); /* Safari, Android, iOS */
  font-display: swap; /* Define how the browser behaves during download */
};
@font-face{
  font-family: "JB-Regular";
  src:  url(${JBRegWoff2}) format("woff2"),
        url(${JBRegWoff}) format("woff"),
        url(${JBRegOtf}) format("otf");
};
@font-face{
  font-family: "JB-Bold";
  src:  url(${JBBoldWoff2}) format("woff2"),
        url(${JBBoldWoff}) format("woff"),
        url(${JBBoldOtf}) format("otf");
};

${reset}

  html, body, #root{
    height:100%;
  }

  body{
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    font-family: ${theme.typography.primary.regular}, Fallback, sans-serif;
    background: ${theme.palette.brand.primary.light};
    color: ${theme.palette.neutral.secondary.key};
  }

  body{
    .text-1{
      font-size: 18px;
      line-height: 26px;
    }
    .text-2{
      font-size: 16px;
      line-height: 24px;
    }
    .text-3{
      font-size: 12px;
      line-height: 16px;
    }
  }
  .MuiGrid-container {
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 1200px;
    }
  }
  .mobileOnly {
    @media (min-width: ${theme.breakpoints.values.md}px) {
      display: none;
    }
  }
  .deskOnly {
    display: none;
    @media (min-width: ${theme.breakpoints.values.md}px) {
      display: initial;
    }
  }
  
  .Toastify__toast-container {
    width: 100%;
    max-width: 351px;

    .Toastify__toast {
      font-family: ${theme.typography.primary.regular}, Fallback, sans-serif;
      border: unset;
      border-radius: 8px;
      font-size: 16px;
      color: ${theme.palette.neutral.secondary.key};
      letter-spacing: 0;
      line-height: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
  
      // svg {
      //   margin-right: 16px;
      //   width: 16px;
      // }
    }

    .Toastify__toast--success {
      background-color: ${theme.palette.feedback.success.light};
      border: 1px solid ${theme.palette.feedback.success.key};
    }
    
    .Toastify__toast--error {
      background-color: ${theme.palette.feedback.error.light};
      border: 1px solid ${theme.palette.feedback.error.key};
    }
  }
`;

export default GlobalStyle;
