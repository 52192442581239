import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";
import MaskedInput from "react-text-mask";
import { Message, Loading, Margin } from "~/components/elements";
import errorSVG from "~/assets/img/icon/error.svg";
import successSVG from "~/assets/img/icon/check.svg";
import PassOnSVG from "~/assets/img/icon/passOn.svg";
import PassOffSVG from "~/assets/img/icon/passOff.svg";
import { useField } from "formik";

import {
  BoxInput,
  Icon,
  Error,
  Label,
  RelativeDiv,
  Success,
  Loader,
  IconButton,
  IconInput,
  FlexDiv,
} from "./styles";

export default function Input({
  onChange,
  icon,
  type,
  errors,
  touched,
  label,
  htmlFor,
  name,
  value,
  placeholder,
  onBlur,
  mask,
  disabled,
  min,
  onFocus,
  guide,
  maxLength,
  valid,
  autocomplete,
  required,
  successIcon,
  loading,
  hideError,
  showHidePassword
}) {
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  const conditionInput = () => {
    if (mask) {
      return (
        <>
          <Label htmlFor={htmlFor}>
            {label}
            {required ? <sup>*</sup> : ""}
          </Label>
          <FlexDiv>
            <RelativeDiv>
              <MaskedInput
                mask={mask}
                id={htmlFor}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type={type}
                name={name}
                placeholder={placeholder}
                maxLength={maxLength}
                onFocus={onFocus}
                guide={guide}
                disabled={disabled}
                valid={valid}
              />
              {!!errors && !loading && (
                <Icon>
                  {
                    <Error>
                      <ReactSVG src={errorSVG} />
                    </Error>
                  }
                </Icon>
              )}
              {loading && (
                <Icon>
                  <Loader>
                    <Loading />
                  </Loader>
                </Icon>
              )}
            </RelativeDiv>
            {icon && (
              <IconInput>
                <ReactSVG src={icon} />
              </IconInput>
            )}
          </FlexDiv>

          {!hideError && !!errors && (
            <Margin mt={1}>
              <Message variant="error">{errors}</Message>
            </Margin>
          )}
        </>
      );
    }

    if (showHidePassword && type === "password") {
      return (
        <>
          <Label htmlFor={htmlFor}>
            {label}
            {required ? <sup>*</sup> : ""}
          </Label>
          <FlexDiv>
            <RelativeDiv>
              <input
                id={htmlFor}
                value={value}
                onChange={onChange}
                min={min}
                type={showPassword ? "text" : type}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
                onFocus={() => setInputFocus(true)}
                autoComplete={autocomplete}
              />

              {loading && (
                <Icon>
                  <Loading />
                </Icon>
              )}
            </RelativeDiv>
            <IconButton>
              <ReactSVG
                src={showPassword ? PassOnSVG : PassOffSVG}
                onClick={() => setShowPassword(!showPassword)}
              />
            </IconButton>
          </FlexDiv>
          {!hideError && !!meta.error && inputFocus && (
            <Message variant="error">{meta.error}</Message>
          )}
        </>
      );
    }
    return (
      <>
        <Label htmlFor={htmlFor}>
          {label}
          {required ? <sup>*</sup> : ""}
        </Label>
        <FlexDiv>
          <RelativeDiv>
            <input
              id={htmlFor}
              value={value}
              onChange={onChange}
              min={min}
              onBlur={onBlur}
              type={type}
              name={name}
              placeholder={placeholder}
              disabled={disabled}
              maxLength={maxLength}
              onFocus={onFocus}
              autoComplete={autocomplete}
            />
            {!!errors && !loading && (
              <Icon>
                <Error>
                  <ReactSVG src={errorSVG} />
                </Error>
              </Icon>
            )}

            {valid && successIcon && !loading && (
              <Icon>
                <Success>
                  <ReactSVG src={successSVG} />
                </Success>
              </Icon>
            )}

            {loading && (
              <Icon>
                <Loading />
              </Icon>
            )}
          </RelativeDiv>
        </FlexDiv>
        {!hideError && !!errors && <Message variant="error">{errors}</Message>}
      </>
    );
  };

  return (
    <BoxInput
      valid={
        showHidePassword
          ? inputFocus && !meta.error && field.value.trim().length !== 0
          : valid
      }
      touched={touched}
      errors={showHidePassword ? inputFocus && !!meta.error : errors}
      disabled={disabled}
      icon={!!icon}
      successIcon={successIcon}
      loading={loading}
    >
      {conditionInput()}
    </BoxInput>
  );
}

Input.defaultProps = {
  type: "text",
  placeholder: "",
  icon: "",
  htmlFor: "",
  mask: "",
  errors: "",
  label: "",
  onBlur: () => {},
  onFocus: () => {},
  clearField: null,
  clearCoupon: () => {},
  min: 0,
  disabled: false,
  touched: undefined,
  guide: true,
  maxLength: "50",
  valid: false,
  inputProps: {},
  autocomplete: "none",
  required: undefined,
  loading: false,
  successIcon: false,
  hideError: false,
  showHidePassword: false,
};

Input.propTypes = {
  // coupon
  valid: PropTypes.bool,
  clearCoupon: PropTypes.func,
  clearField: PropTypes.func,
  // coupon
  htmlFor: PropTypes.string,
  maxLength: PropTypes.string,
  guide: PropTypes.bool,
  touched: PropTypes.bool,
  icon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  errors: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.instanceOf(RegExp)),
  ]),
  min: PropTypes.number,
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  autocomplete: PropTypes.string,
  required: PropTypes.bool,
  successIcon: PropTypes.bool,
  loading: PropTypes.bool,
  hideError: PropTypes.bool,
  showHidePassword: PropTypes.bool,
};
