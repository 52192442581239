import styled from "styled-components";
import theme from "~/styles/theme";

export const AddInfoButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    width: 100%;
    max-width: 154px;
  }
`;

export const ForgotLink = styled.div`
  text-align: left;
  z-index: 100;
  position: sticky;

  a {
    font-size: 13px;
    color: ${theme.palette.primary.main};
    font-family: ${theme.typography.secondary.bold};
    text-decoration: underline;
  }
`;
