import * as Yup from "yup";
import MESSAGE from "~/utils/messages";

export const schema = Yup.object().shape({
  Email: Yup.string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g,
      MESSAGE.invalidMail
    )
    .required(MESSAGE.requiredField),
});
