import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { CtTitle } from "./styles";
import isMobile from "~/hooks/isMobile";

export default function Title({
  className,
  as,
  level,
  levelDesktop,
  primary,
  emphasis,
  children,
}) {
  const mobile = isMobile();

  function TitleHasDesktop() {
    if (!mobile && !!levelDesktop) {
      return `title-${levelDesktop}`;
    }
    return `title-${level}`;
  }

  const existLevel = `${className} ${TitleHasDesktop()}` || `${className}`;

  return (
    <CtTitle className={existLevel} primary={primary} emphasis={emphasis}>
      <Typography variant={`h${as}`}>{children} </Typography>
    </CtTitle>
  );
}

Title.defaultProps = {
  level: "",
  levelDesktop: "",
  className: "",
  as: "6",
  primary: false,
  emphasis: false,
  maxWidthM: "100%",
  maxWidthD: "100%",
};

Title.propTypes = {
  level: PropTypes.oneOf(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]),
  levelDesktop: PropTypes.oneOf([
    "",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
  ]),
  className: PropTypes.string,
  as: PropTypes.string,
  primary: PropTypes.bool,
  emphasis: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
