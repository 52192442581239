import * as Yup from "yup";
import MESSAGE from "~/utils/messages";
import { phoneValidation } from "~/utils/tools";

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg || MESSAGE.genericError,
    params: {
      reference: ref.path,
    },
    test: function(value) {
      return value === this.resolve(ref);
    },
  });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);

export const schema = Yup.object().shape({
  Nome: Yup.string()
    .matches(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
      MESSAGE.invalidName
    )
    .required(MESSAGE.requiredField),
  Sobrenome: Yup.string()
    .matches(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
      MESSAGE.invalidLastname
    )
    .required(MESSAGE.requiredField),
  TelCelular: Yup.mixed().test({
    name: "phone",
    message: MESSAGE.invalidPhone,
    test: (value) => {
      if (value) {
        return phoneValidation(value);
      }
      return true;
    },
  })
  .required(MESSAGE.requiredField),
  Estado: Yup.object().shape({
    label: Yup.string().required(MESSAGE.requiredSelect),
    value: Yup.string().required(MESSAGE.requiredSelect),
    id: Yup.number().required(MESSAGE.requiredSelect),
  }),
  AceitaTermosLegais: Yup.array()
    .of(Yup.string())
    .required(MESSAGE.requiredCheck),
});
