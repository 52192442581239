import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    primary: {
      light: "OpenSans-Light",
      regular: "OpenSans-Regular",
      medium: "OpenSans-Medium",
      bold: "OpenSans-Bold",
    },
    secondary: {
      light: "JB-Light",
      regular: "JB-Regular",
      medium: "JB-Medium",
      bold: "JB-Bold",
    },
    fontFamily: "JB-Regular",
    secondaryFont: "JB-Bold",
    fontDisplay: "swap",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1140,
      xl: 1920,
    },
  },
  palette: {
    // newType
    brand: {
      primary: {
        key: "#3797DB",
        light: "#E9F5FF",
        mediumLight: "#CEE7FD",
        medium: "#89BDE8",
        dark: "#0072BC",
      }
    },
    neutral: {
      primary: {
        key: "#FFFFFF",
        light: "#F8F8F8",
        medium: "#D9D9D9",
        dark: "#9E9E9E",
      },
      secondary: {
        key: "#454545",
        light: "#C3C3C3",
        medium: "#6E6E6E",
        dark: "#272727",
      }
    },
    feedback: {
      success: {
        key: "#62980E",
        light: "#F0FFDA",
        medium: "#62980E",
        dark: "#62980E",
      },
      error: {
        key: "#E42436",
        light: "#FFF1F3",
        medium: "#E42436",
        dark: "#E42436",
      }
    },
    // oldType - @TODO Remove after all components are updated
    primary: {
      lighter: "#e8f2f9",
      light: "#017DFB",
      main: "#606060",
      dark: "#006BD7",
    },
    secondary: {
      main: "#009BE0",
      dark: "#c5e2fc",
      darker: "#b1cce7",
      light: "#FFFFFF",
    },
    tertiary: {
      main: "#FDF2E3",
      dark: "#D9CFC2",
    },
    hero: {
      bg: "#ebf4fa",
    },
    disclaimer: {
      bg: "#f7f7f7",
      border: "#e7e7e7",
    },
    menu: {
      bg: "#f7f7f7",
      border: "#d4d4d4",
      innerborder: "#d3d3d3",
      selected: "#E6E6E6",
    },
    checkbox: {
      border: "#979797",
    },
    bubbles: {
      active: "#017DFB",
      inactive: "#9BC9E8",
      hover: "#8AB8D7",
    },
    errorMessage: {
      bg: "#FFF1F3",
      border: "#FFBFC5",
      color: "#E1151F",
    },
    success: {
      main: "#83CC12",
      dark: "#79B619",
    },
    alert: {
      main: "#EDC700",
      dark: "#C3A300",
    },
    error: {
      main: "#E42436",
      dark: "#C41122",
    },
    black: {
      main: "#333333",
      light: "#6e6e6e",
      lighter: "#9e9e9e",
      silver: "#cbcbcb",
    },
    disabled: {
      text: "#c3c3c3",
      bg: "#e2e2e2",
      border: "#c0c0c0",
    },
    transparent: "transparent",
    toast: {
      success: "#E4F4FF",
      error: "#FFF1F3",
      sucessBorder: "#3095DE",
      errorBorder: "#E42436 ",
    }
  },
  gradient: "linear-gradient(180deg, rgba(48,149,222,0.20) 0%, rgba(94,199,241,0.00) 100%)",
  modalGradient: "rgba(0,0,0,0.70)",
  transition: "all ease 0.2s",
});

export default theme;
