import styled, { css } from "styled-components";
import theme from "~/styles/theme";

export const BoxInput = styled.div`
  position: relative;
  input {
    border: 1px solid ${theme.palette.black.lighter};
    padding: 16px;
    display: block;
    height: 56px;
    width: 100%;
    font-size: 16px;
    border-radius: 6px;
    color: ${theme.palette.black.light};
    ${(props) =>
      props.icon &&
      css`
        padding-right: 40px;
      `}
    &:focus {
      border: 2px solid ${theme.palette.primary.light};
    }
  }
  label {
    color: ${theme.palette.black.main};
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  ${(props) =>
    props.errors &&
    css`
      input {
        color: ${theme.palette.error.main};
        border: 2px solid ${theme.palette.error.main};
      }
    `}
  ${(props) =>
    props.valid &&
    css`
      input {
        border: 2px solid ${theme.palette.success.main};
      }
    `}
`;
export const RelativeDiv = styled.div`
  position: relative;
  width: 100%;
`;
export const Icon = styled.span`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 15px;
  svg {
    width: 8px;
    height: 8px;
  }
`;
export const Error = styled.span`
  color: ${theme.palette.error.main};
  svg {
    width: 100%;
    height: 100%;
  }
`;
export const Success = styled.span`
  color: ${theme.palette.success.main};

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Change = styled.button`
  color: ${theme.palette.primary.main};
  text-decoration: underline;
  font-size: 0.9em;
`;
export const Label = styled.label`
  display: flex;
  justify-content: flex-start;
`;

export const Loader = styled.span`
  .MuiCircularProgress-root {
    width: 25px !important;
    height: 25px !important;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const PasswordButton = styled.div`
  svg {
    cursor: pointer;
    margin-left: 16px;
  }
`;

export const IconButton = styled.div`
  position: absolute;
  border-radius: 0px 6px 6px 0px;
  right: 2px;
  background: ${theme.palette.secondary.darker};
  width: 55px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 28px;
    height: 21px;
    cursor: pointer;
  }
`;

export const IconInput = styled.div`
  background: ${theme.palette.secondary.darker};
  width: 48px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 7px;
`