import styled from "styled-components";
import theme from "~/styles/theme";

export const LabelHolder = styled.div`
  width: 100%;
  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 230px;
  }
  > div {
    width: 100%;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      width: 90%;
    }

  }
  label {
    font-size: 14px;
    line-height: 18px;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 270px;
  }
`;

export const SubmitButtonHolder = styled.div`
  text-align: center;
  width: 100%;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: auto;
  }

  button {
    width: 100%;
    margin-top: 26px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      margin-top: 0;
    }
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    text-align: right;
    button {
      width: 272px;
    }
  }
`;

export const FormHolder = styled.div`
  margin-bottom: 35px;
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 35px;
`;
