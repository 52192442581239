import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { Margin } from "~/components/elements";
import ReactSVG from "react-svg";
import { 
  ContentModal,
  // AddInfoButtonHolder 
} from "./styles";
import { parentScrollTop } from "~/utils/tools";
import Alert from "~/assets/img/icon/alert.svg";

import { Modal } from "~/components/elements";
import isMobile from "~/hooks/isMobile";

export default function ModalLogin({ message, handleResend }) {
  const [openModal, setOpenModal] = useState(false);

  const mobile = isMobile();

  const handleModal = () => {
    setOpenModal(false);
    parentScrollTop();
  };

  useEffect(() => {
    setOpenModal(!!message);
    parentScrollTop();
  }, [message]);

  return (
    <Modal open={openModal} mobile={mobile} closeModal={handleModal}>
      <Margin mb={2}>
        <ReactSVG wrapper="div" src={Alert} />
      </Margin>
      <ContentModal>{parse(message)}</ContentModal>
      {/* <AddInfoButtonHolder>
        <Button
          primary
          onClick={() => {
            handleResend();
            setOpenModal(false);
          }}
          color="secondary"
          id="btnUpdateEmailSent"
        >
          REENVIAR E-MAIL
        </Button>
      </AddInfoButtonHolder> */}
    </Modal>
  );
}
