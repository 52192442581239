import React, { useEffect } from "react";
import { motion } from "framer-motion";
import ReactSVG from "react-svg";
import { DynamicBG } from "~/components/blocks";
import { Margin, Title, Text, Button } from "~/components/elements";
import * as S from "./styles";
import SadIcon from "~/assets/img/ilustricon/sad.svg";
import { scrollTop, parentScrollTop } from "~/utils/tools";
import isMobile from "~/hooks/isMobile";
import history from "~/routes/history";

export default function Error404() {
  const mobile = isMobile();

  const transition = {
    duration: 0.3,
    ease: [0.43, 0.13, 0.23, 0.96],
  };

  const animations = {
    exit: { y: "50%", opacity: 0, transition },
    enter: {
      y: "0%",
      opacity: 1,
      transition,
    },
  };

  useEffect(() => {
    scrollTop();
    parentScrollTop();
  }, []);

  return (
    <motion.div
      variants={animations}
      className="single"
      initial="exit"
      animate="enter"
      exit="exit"
    >
      <DynamicBG content>
        <S.PageHolder>
          <Margin mt={mobile ? 6 : 12} mb={4}>
            <S.SadHolder>
              <ReactSVG src={SadIcon} />
            </S.SadHolder>
          </Margin>
          <Margin mb={1}>
            <S.TitleHolder>
              <Title as="1" levelDesktop="3" level="4" emphasis>
                Não encontramos a página
              </Title>
            </S.TitleHolder>
          </Margin>
          <Margin mb={4}>
            <Text level={mobile ? "2" : "1"}>
              Erro 404
            </Text>
          </Margin>
          <Margin mb={mobile ? 9 : 12}>
            <S.ButtonHolder>
              <Button color="secondary" onClick={() => history.push("/")} id="homeButton404">
                Voltar para a Home
              </Button>
            </S.ButtonHolder>
          </Margin>
        </S.PageHolder>
      </DynamicBG>
    </motion.div>
  );
}
