import React from "react";
import * as S from "./styles";
import ReactSVG from "react-svg";
import Exit from "~/assets/img/icon/exit.svg";
import { parentScrollTop } from "~/utils/tools";

export default function Modal({ open, children, closeModal }) {
  return (
    <S.Shade
      open={open}
      onClick={() => {
        closeModal(false);
        parentScrollTop();
      }}
    >
      <S.ModalBox
        open={open}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <S.Exit onClick={() => closeModal(false)}>
          <ReactSVG wrapper="div" src={Exit} />
        </S.Exit>
        {children}
      </S.ModalBox>
    </S.Shade>
  );
}
