import * as Yup from "yup";
import MESSAGE from "~/utils/messages";

export const schema = Yup.object().shape({
  Email: Yup.string()
    .email(MESSAGE.invalidMail)
    .required(MESSAGE.requiredField),
  EmailNovamente: Yup.string()
    .email(MESSAGE.invalidMail)
    .required(MESSAGE.mismatchedMail)
    .test(
      "EmailNovamente",
      MESSAGE.mismatchedMail,
      function () {
        const email = this.parent.Email?.toUpperCase();
        const confirmEmail = this.parent.EmailNovamente?.toUpperCase();
        return !(email !== confirmEmail);
      }
    ),
});
