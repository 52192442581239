import styled from "styled-components";
import theme from "~/styles/theme";

export const ContentModal = styled.div`
  font-size: 16px;
  color: ${theme.palette.primary.main};
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 24px;

  h1 {
    font-size: 32px;
    color: ${theme.palette.secondary.main};
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 700;
    font-family: ${theme.typography.secondary.bold};
  }

  strong {
    color: ${theme.palette.secondary.main};
  }
`;

export const AddInfoButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    width: 100%;
    max-width: 250px;
  }
`;
