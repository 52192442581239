import produce from "immer";
import { Types as SignUpTypes } from "./actions";

const INITIAL_STATE = {
  sign: {
    sending: false,
    success: false,
    errorMessage: "",
    userMail: "",
    userHash: "",
  },
  complement: {
    sending: false,
    success: false,
    errorMessage: "",
    data: {}
  },
  validate: {
    sending: false,
    success: false,
    message: "",
  },
  resend: {
    sending: false,
    success: false,
    message: "",
  },
};

export default function signUp(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      // Form Send
      case SignUpTypes.SEND_SIGNUP_FORM: {
        draft.sign.sending = true;
        break;
      }
      case SignUpTypes.SEND_SIGNUP_SUCCESS: {
        draft.sign.sending = false;
        draft.sign.success = true;
        draft.sign.userMail = action.payload.userMail;
        draft.sign.userHash = action.payload.userHash;
        break;
      }
      case SignUpTypes.SEND_SIGNUP_FAIL: {
        draft.sign.sending = false;
        draft.sign.errorMessage = action.payload.msg;
        break;
      }
      case SignUpTypes.SET_SIGNUP_DATA: {
        draft.sign = INITIAL_STATE.sign;
        break;
      }
      
      // Form Complement
      case SignUpTypes.SEND_COMPLEMENT_FORM: {
        draft.complement.sending = true;
        break;
      }
      case SignUpTypes.SEND_COMPLEMENT_SUCCESS: {
        draft.complement.sending = false;
        draft.complement.success = true;
        draft.complement.data = action.payload;
        break;
      }
      case SignUpTypes.SEND_COMPLEMENT_FAIL: {
        draft.complement.sending = false;
        draft.complement.errorMessage = action.payload.msg;

        break;
      }
      case SignUpTypes.SET_COMPLEMENT_DATA: {
        draft.complement = INITIAL_STATE.sign;
        break;
      }

      // Confirm code to update password
      case SignUpTypes.SEND_CONFIRM_CODE: {
        draft.validate.sending = true;
        break;
      }
      case SignUpTypes.SEND_CONFIRM_SUCCESS: {
        draft.validate.sending = false;
        draft.validate.success = true;
        draft.validate.message = action.payload.msg;
        break;
      }
      case SignUpTypes.SEND_CONFIRM_FAIL: {
        draft.validate.sending = false;
        draft.validate.message = action.payload.msg;
        break;
      }
      case SignUpTypes.SET_CONFIRM_DATA: {
        draft.validate = INITIAL_STATE.validate;
        break;
      }

      // Resend Email
      case SignUpTypes.RESEND_CONFIRM_EMAIL: {
        draft.resend.sending = true;
        break;
      }
      case SignUpTypes.RESEND_CONFIRM_SUCCESS: {
        draft.resend.sending = false;
        draft.resend.success = true;
        draft.resend.message = action.payload.msg;
        break;
      }
      case SignUpTypes.RESEND_CONFIRM_FAIL: {
        draft.resend.sending = false;
        draft.resend.message = action.payload.msg;
        break;
      }
      case SignUpTypes.SET_RESEND_DATA: {
        draft.resend = INITIAL_STATE.resend;
        break;
      }

      // Social  SignUp
      case SignUpTypes.SEND_SOCIAL_SIGNUP_INFO: {
        draft.sign.sending = false;
        draft.sign.success = true;
        draft.sign.data = action.payload;
        break;
      }

      default:
    }
  });
}
