import styled from "styled-components";
import theme from "~/styles/theme";

export const HeaderWrapper = styled.header`
  background-color: ${theme.palette.neutral.primary.key};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  @media (max-width: 768px) {
    .left {
      display: none;
    }

    .logo {
      margin-right: auto;
    }
  }

  @media (min-width: 768px) {
    padding: 20px 160px;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: flex-end;

`;

export const Link = styled.a`
  color: ${theme.palette.neutral.secondary.dark};
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
  margin-left: 5px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

export const Logo = styled.img`
  width: 116px;
  height: 38px;

  @media (min-width: 768px) {
    width: 179px;
    height: 51px;
  }
`;