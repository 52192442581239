import styled, { css } from "styled-components";
import { Button } from "@material-ui/core";
import theme from "~/styles/theme";

export const ButtonStyle = styled(Button)`
  font-family: ${theme.typography.secondary.bold};
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 28px;
  padding: 14px 32px;
  line-height: 24px;
  cursor: pointer;
  transition:all 0.2s ease-in-out;
  box-shadow: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover{
    transition:all 0.3s ease-in-out;
  }

  transition: max-width 0.2s ease, min-width 0.2s ease;
  ${(props) =>
    props.color === "primary" &&
    css`
      background: ${props.variant === "outlined"
        ? theme.palette.transparent
        : theme.palette.primary.main};
      color: theme.palette.secondary.main;
      border: 2px solid ${theme.palette.primary.main};
      &:hover {
        background: ${props.variant === "outlined"
          ? theme.palette.secondary.darker
          : theme.palette.primary.dark};
        border: 2px solid ${theme.palette.primary.dark};
      }
    `}

  ${(props) =>
    props.color === "secondary" &&
    css`
      background: ${props.variant === "outlined"
        ? theme.palette.transparent
        : theme.palette.secondary.main};
      color: ${props.variant === "outlined"
        ? theme.palette.secondary.main
        : theme.palette.secondary.light};
      border: 2px solid ${theme.palette.secondary.main};
      &:hover {
        background: ${props.variant === "outlined"
          ? theme.palette.secondary.darker
          : theme.palette.secondary.dark};
        border: 2px solid ${theme.palette.secondary.dark};
      }
    `}
  ${(props) =>
    props.color === "disabled" &&
    css`
      background: ${theme.palette.disabled.bg};
      color: ${theme.palette.disabled.text};
      border: 2px solid ${theme.palette.disabled.border};
    `}
  &.animation-size{
    transition: max-width 0.6s cubic-bezier(0.03, 0.04, 0, 0.99), min-width 0.6s cubic-bezier(0.03, 0.04, 0, 0.99);
    min-width: 100px !important;
    .MuiCircularProgress-root {
      margin: 0 !important;
      width: 24px !important;
      height: 24px !important;
    }
  }

`;
