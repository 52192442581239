import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Message, Margin } from "~/components/elements";
import { BoxInput, SelectInput } from "./styles";
import theme from "~/styles/theme";

export default function Select({
  id,
  onChange,
  errors,
  label,
  htmlFor,
  name,
  value,
  onBlur,
  options,
  placeholder,
  disabled,
  required,
}) {
  useEffect(() => {
    if (value && value.length > 0) {
      onChange(name, { value, label: value });
    }
  }, [name, onChange, value]);
  return (
    <BoxInput>
      <label htmlFor={htmlFor}>
        {label}
        {required ? <sup>*</sup> : ""}
      </label>

      <SelectInput
        isDisabled={disabled}
        components={{
          IndicatorSeparator: () => null,
        }}
        onChange={(val) => onChange(name, val)}
        onBlur={onBlur(name, true)}
        noOptionsMessage={() => "..."}
        value={value}
        placeholder={placeholder}
        options={options}
        id={id}
        theme={(items) => ({
          ...items,
          borderRadius: "6px",
          spacing: {
            controlHeight: 56,
            menuGutter: 2,
            baseUnit: 8,
          },
          colors: {
            ...items.colors,
            text: theme.palette.black.main,
            primary25: theme.palette.secondary.dark,
            primary: theme.palette.secondary.main,
          },
        })}
      />

      {!!errors && (
        <Margin mt={1}>
          <Message variant="error">{errors.value}</Message>
        </Margin>
      )}
    </BoxInput>
  );
}

Select.defaultProps = {
  id: "",
  disabled: false,
  htmlFor: "",
  onBlur: () => {},
  errors: "",
  label: "",
  value: {
    value: 0,
    label: "",
  },
};

Select.propTypes = {
  id: PropTypes.string,
  htmlFor: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  errors: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    })
  ).isRequired,
};
