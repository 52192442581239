import styled from "styled-components";
import theme from "~/styles/theme";

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Card = styled.div`
  width: 100%;
  background-color: ${theme.palette.primary.dark};
  border-radius: 8px;
  text-align: center;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    max-width: 590px;
    margin: 0 auto;

    p {
      margin: 0 auto;
      max-width: 542px;
    }
  }

  h1 {
    color: ${theme.palette.secondary.light};
  }

  p {
    color: ${theme.palette.secondary.light};
  }

  .banner {
    width: 100%;
    height: auto;
  }

  .content {
    padding: 16px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 24px;
    }
  }
`;

export const TextHolder = styled.div`
  border: 1px solid ${theme.palette.neutral.primary.medium};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;

  p {
    font-size: 20px;
    color: ${theme.palette.neutral.secondary.key};
  }

  button {
    width: 100%;

    .icon {
      margin-left: 8px;
      
      svg {
        width: 24px;
        margin-top: 5px;
      }
    }
  }
`;
