import React from "react";
import youtubeIcon from "~/assets/img/social/youtube.svg";
import instagramIcon from "~/assets/img/social/instagram.svg";
import facebookIcon from "~/assets/img/social/facebook-f.svg";
import logo from "~/assets/img/logo-johnsons-baby.png";
import {
  FooterWrapper,
  LogosWrapper,
  Logo,
  SocialIcons,
  Divider,
  Links,
  Link,
  LegalText,
} from "./styles";

export default function Footer() {
  return (
    <FooterWrapper>
      <LogosWrapper>
        <Logo src={logo} alt="Logo" />
        <SocialIcons>
          <a
            href="https://www.youtube.com/channel/UCz6iuMEiLrq0c-60R7D7LqQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtubeIcon} alt="Youtube icon" />
          </a>
          <a
            href="https://www.instagram.com/jbabybr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramIcon} alt="Instagram icon" />
          </a>
          <a
            href="https://www.facebook.com/johnsonsbabybrasil?ref=hl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebookIcon} alt="Facebook icon" />
          </a>
        </SocialIcons>
      </LogosWrapper>

      <Divider />
      <Links>
        <Link
          href="https://www.johnsonsbaby.com.br/politica-de-privacidade"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de Privacidade
        </Link>
        <Link
          href="https://www.johnsonsbaby.com.br/politica-de-cookies"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de Cookies
        </Link>
        <Link
          href="https://www.johnsonsbaby.com.br/termos-e-condicoes"
          target="_blank"
          rel="noopener noreferrer"
        >
          Informações Legais
        </Link>
        <Link
          href="https://www.johnsonsbaby.com.br/mapa-do-site"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mapa do Site
        </Link>
        <Link href="https://www.johnsonsbaby.com.br/faq" target="_blank">
          Dúvidas Frequentes
        </Link>
        <Link
          href="https://www.johnsonsbaby.com.br/fale-conosco"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fale Conosco
        </Link>
      </Links>
      <Divider />
      <LegalText>
        <sup class="copyright">©</sup>JNTL Consumer Health (Brazil) Ltda. 2023.
        Todos os direitos reservados.
        <br /> Este site é de propriedade da JNTL Consumer Health (Brazil)
        Ltda., única responsável por seu conteúdo, e destina-se a residentes no
        Brasil.
      </LegalText>
    </FooterWrapper>
  );
}
