import styled from "styled-components";
import theme from "~/styles/theme";

export const TitleHolder = styled.div`
  text-align: center;
  h3 {
    color: ${theme.palette.primary.main};
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    text-align: left;
  }
`;

export const PersonalCard = styled.div`
  padding: 32px;
  background: ${theme.palette.secondary.light};
  border: 1px solid ${theme.palette.menu.border};
  border-radius: 16px;
  text-align: left;
  position: relative;
  p {
    color: ${theme.palette.black.main};
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 40px;
  }
`;

export const NameHolder = styled.div`
  color: ${theme.palette.primary.main};
  width: 100%;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 230px;
  }

  input {
    @media (min-width: ${theme.breakpoints.values.md}px) {
      width: 90%;
    }
  }
  label {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const LabelHolder = styled.div`
  width: 100%;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 230px;
  }
  > div {
    width: 100%;
    @media (min-width: ${theme.breakpoints.values.md}px) {
      width: 90%;
    }
  }
  label {
    font-size: 14px;
    line-height: 18px;
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    width: 192px;
  }
`;

export const SubmitButtonHolder = styled.div`
  text-align: center;

  button {
    width: 100%;
    margin-top: 26px;
    @media (min-width: ${theme.breakpoints.values.md}px) {
      margin-top: 0;
    }
  }
  @media (min-width: ${theme.breakpoints.values.md}px) {
    text-align: right;
    button {
      width: 272px;
    }
  }
`;

export const CardAbstract = styled.div`
  margin-top: 24px;
`;
