import produce from "immer";
import { Types as AuthenticationTypes } from "./actions";

const INITIAL_STATE = {
  auth: {
    sending: false,
    success: false,
    errorMessage: "",
    user: {
      nome: "",
      email: "",
      estado: "",
      aceitaTermos: "",
      cadastroCompleto: "",
      ParticipanteHash: "",
      genero: "",
      dataNascimento: ""
    },
    loginToken: "",
    emailValidated: false,
    showMsg: false,
    showFormSignUp: "",
    data: {},
  },
  validateAuth: {
    sending: false,
    success: false,
    errorMessage: "",
  },
  forgotPass: {
    sending: false,
    success: false,
    errorMessage: "",
  },
};

export default function authentication(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      //login
      case AuthenticationTypes.SEND_AUTHENTICATION: {
        draft.auth.sending = true;
        draft.auth.errorMessage = INITIAL_STATE.auth.errorMessage;
        break;
      }
      case AuthenticationTypes.SEND_AUTHENTICATION_SUCCESS: {
        draft.auth.sending = false;
        draft.auth.success = action.payload.success;
        draft.auth.showMsg = true;
        draft.auth.user.nome = action.payload.nome;
        draft.auth.user.email = action.payload.email;
        draft.auth.user.estado = action.payload.estado;
        draft.auth.user.aceitaTermos = action.payload.aceitaTermos;
        draft.auth.user.cadastroCompleto = action.payload.cadastroCompleto;
        draft.auth.user.ParticipanteHash = action.payload.ParticipanteHash;
        draft.auth.loginToken = action.payload.loginToken;
        draft.auth.user.genero = action.payload.genero;
        draft.auth.user.dataNascimento = action.payload.dataNascimento;
        break;
      }
      case AuthenticationTypes.SEND_AUTHENTICATION_FAIL: {
        draft.auth.sending = false;
        draft.auth.errorMessage = action.payload.msg;
        draft.auth.success = INITIAL_STATE.auth.success;
        draft.auth.showMsg = false;
        draft.auth.user.ParticipanteHash = action.payload.ParticipanteHash;
        break;
      }
      case AuthenticationTypes.SET_AUTHENTICATION_MESSAGE: {
        draft.auth.errorMessage = INITIAL_STATE.auth.errorMessage;
        break;
      }
      case AuthenticationTypes.SET_COMPLETE_SIGNUP: {
        draft.auth.user.cadastroCompleto = true;
        break;
      }
      
      // Social SignUp
      case AuthenticationTypes.SEND_SOCIAL_AUTHENTICATION_INFO: {
        draft.auth.sending = false;
        draft.auth.success = action.payload.success;
        draft.auth.user.email = action.payload.email;
        draft.auth.user.id = action.payload.userId;
        draft.auth.data = action.payload;
        break;
      }
      case AuthenticationTypes.SEND_SOCIAL_AUTHENTICATION: {
        draft.auth.sending = true;
        draft.auth.errorMessage = INITIAL_STATE.auth.errorMessage;
        break;
      }
      case AuthenticationTypes.SEND_SOCIAL_AUTHENTICATION_SUCCESS: {
        draft.auth.sending = false;
        draft.auth.success = action.payload.success;
        draft.auth.user.nome = action.payload.nome;
        draft.auth.user.email = action.payload.email;
        draft.auth.user.id = action.payload.userId;           
        draft.auth.user.estado = action.payload.estado;
        draft.auth.user.aceitaTermos = action.payload.aceitaTermos;
        draft.auth.user.cadastroCompleto = action.payload.cadastroCompleto;
        draft.auth.user.ParticipanteHash = action.payload.ParticipanteHash;
        draft.auth.loginToken = action.payload.loginToken;
        draft.auth.user.genero = action.payload.genero;
        draft.auth.user.dataNascimento = action.payload.dataNascimento;
        break;
      }
      case AuthenticationTypes.SEND_SOCIAL_AUTHENTICATION_FAIL: {
        draft.auth.sending = false;
        draft.auth.errorMessage = action.payload.msg;
        draft.auth.user.ParticipanteHash = action.payload.ParticipanteHash;
        break;
      }

    // validate autentication code
      case AuthenticationTypes.SEND_AUTHENTICATION_CODE: {
        draft.auth.sending = true;
        draft.auth.errorMessage = INITIAL_STATE.auth.errorMessage;
        break;
      }
      case AuthenticationTypes.SEND_AUTHENTICATION_CODE_SUCCESS: {
        draft.auth.sending = false;
        draft.auth.success = action.payload.success;
        draft.auth.user.nome = action.payload.nome;
        draft.auth.user.email = action.payload.email;
        draft.auth.user.estado = action.payload.estado;
        draft.auth.user.aceitaTermos = action.payload.aceitaTermos;
        draft.auth.user.cadastroCompleto = action.payload.cadastroCompleto;
        draft.auth.user.ParticipanteHash = action.payload.ParticipanteHash;
        draft.auth.loginToken = action.payload.loginToken;
        break;
      }
      case AuthenticationTypes.SEND_AUTHENTICATION_CODE_FAIL: {
        draft.auth.sending = false;
        draft.auth.errorMessage = action.payload.msg;
        draft.auth.user.ParticipanteHash = action.payload.ParticipanteHash;
        break;
      }
      case AuthenticationTypes.SET_AUTHENTICATION_CODE_MESSAGE: {
        draft.auth.errorMessage = INITIAL_STATE.auth.errorMessage;
        break;
      }
      case AuthenticationTypes.SHOW_CODE_SUCCESS: {
        draft.auth.showFormSignUp = true;
        break;
      }      
      // validate autentication
      case AuthenticationTypes.SEND_VALIDATE_AUTHENTICATION: {
        draft.validateAuth.success = INITIAL_STATE.validateAuth.success;

        draft.validateAuth.sending = true;
        break;
      }

      case AuthenticationTypes.SEND_VALIDATE_AUTHENTICATION_SUCCESS: {
        draft.validateAuth.sending = INITIAL_STATE.validateAuth.sending;
        draft.validateAuth.success = action.payload.sucesso;

        draft.auth.loginToken = action.payload.loginToken;

        break;
      }

      case AuthenticationTypes.SEND_VALIDATE_AUTHENTICATION_FAIL: {
        draft.validateAuth.sending = INITIAL_STATE.validateAuth.sending;
        draft.validateAuth.success = action.payload.success;
        draft.validateAuth.errorMessage = action.payload.msg;

        draft.auth.loginToken = INITIAL_STATE.auth.loginToken;
        break;
      }

      case AuthenticationTypes.SET_VALIDATE_AUTH: {
        draft.validateAuth = INITIAL_STATE.validateAuth;
        break;
      }

      // logout
      case AuthenticationTypes.PURGE_AUTHENTICATION: {
        draft.auth = INITIAL_STATE.auth;
        draft.validateAuth = INITIAL_STATE.validateAuth;
        break;
      }

      // forgot password
      case AuthenticationTypes.SEND_FORGOT_PASS: {
        draft.forgotPass.sending = true;
        draft.forgotPass.errorMessage = INITIAL_STATE.forgotPass.errorMessage;
        break;
      }
      case AuthenticationTypes.SEND_FORGOT_PASS_SUCCESS: {
        draft.forgotPass.sending = false;
        draft.forgotPass.success = action.payload.sucesso;
        draft.forgotPass.errorMessage = action.payload.msg;
        break;
      }
      case AuthenticationTypes.SEND_FORGOT_PASS_FAIL: {
        draft.forgotPass.sending = false;
        draft.forgotPass.success = action.payload.sucesso;
        draft.forgotPass.errorMessage = action.payload.msg;
        break;
      }
      case AuthenticationTypes.SEND_FORGOT_PASS_RESET: {
        draft.forgotPass = INITIAL_STATE.forgotPass;
        break;
      }
      default:
    }
  });
}
