import styled from "styled-components";
import { Grid } from "@material-ui/core";
import theme from "~/styles/theme";

export const BGColor = styled.div`
  overflow: ${(props) => (props.content ? "hidden" : "visible")};
  @media (min-width: ${theme.breakpoints.values.md}px) {
    min-height: ${(props) => (props.content ? "calc(100vh - 429px)" : "unset")};
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 329px;
    // background-image: ${theme.gradient};
    top: 0;
  }
`;

export const BGGrid = styled(Grid)`
  position: relative;
`;
