import { combineReducers } from "redux";
import authentication from "./modules/authentication/reducer";
import signUp from "./modules/signUp/reducer";
import promo from "./modules/promo/reducer";
import account from "./modules/account/reducer";

export default combineReducers({
  authentication,
  signUp,
  promo,
  account,
});
