import React from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { hot } from "react-hot-loader/root";
import { ThemeProvider, StylesProvider } from "@material-ui/styles";
import GlobalStyle from "./styles/global";
import Routes from "./routes";
import { store } from "./store";
import defaultTheme from "./styles/theme";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import history from "~/routes/history";
import { Header, Footer } from "~/components/blocks";

function App() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <Provider store={store}>
          <Router history={history}>
            <>
              <Header />
              <Routes />
              <GlobalStyle />
              <ToastContainer
                position="bottom-right"
                autoClose={3000}
                newestOnTop={false}
                hideProgressBar={true}
                closeOnClick={false}
                rtl={false} //Right to left layout
                pauseOnVisibilityChange={true}
                draggable={false}
                closeButton={false}
                transition={Slide}
                theme="light" //dark or colored
              />
              <Footer />
            </>
          </Router>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default process.env.NODE_ENV === "development" ? hot(App) : App;
// export default App;
