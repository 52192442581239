import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field } from "formik";
import { useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";
import {
  Title,
  Text,
  Margin,
  Input,
  Button,
  Select,
  Checkbox,
  Message,
  ErrorMessage,
  Loading,
} from "~/components/elements";
import { Creators as SignUpActions } from "~/store/modules/signUp/actions";
import { dateMask, formatDate, formatDateView } from "~/utils/tools";
import { genderSelect, babyAge } from "~/utils/variables";
import isMobile from "~/hooks/isMobile";
import { schema } from "./validation";
import * as S from "./styles";

export default function FormComplement() {
  const location = useLocation();
  const mobile = isMobile();
  const [midiaParams, setMidiaParams] = useState({});
  const [childButton, setChildButton] = useState(false);
  const [childNumbers, setChildNumbers] = useState(false);
  const dispatch = useDispatch();
  const sending = useSelector((state) => state.signUp.complement.sending);
  const participanteHash = useSelector((state) => state.authentication.auth.user.ParticipanteHash);
  const loginToken = useSelector((state) => state.authentication.auth.loginToken);
  const errorMessage = useSelector((state) => state.signUp.complement.errorMessage);
  const userDetails = useSelector((state) => state.account?.details)
  const initialValues = {
    Sexo: genderSelect.find((gender) => gender.value === userDetails?.dadosPessoais?.Sexo) || genderSelect[0],
    DataNascimento: formatDateView(userDetails?.dadosPessoais?.DataNascimento) || "",
    PossuiFilhos: userDetails?.respostas?.Pergunta1 || "",
    QuantosFilhos: userDetails?.respostas?.Pergunta2 || "",
    ChegadaDoBebe: userDetails?.interesses?.ChegadaDoBebe || false,
    DicasDeCuidado: userDetails?.interesses?.DicasDeCuidado || false,
    FasesDoDesenvolvimento: userDetails?.interesses?.FasesDoDesenvolvimento || false,
    SaudeMentalMaterna: userDetails?.interesses?.SaudeMentalMaterna || false,
    SegurancaEIngredientes: userDetails?.interesses?.SegurancaEIngredientes || false,
    AceitaReceberNoticias: userDetails?.AceitaReceberNoticias || false,
    FaixaEtaria1: "",
    FaixaEtaria2: "",
    FaixaEtaria3: "",
    FaixaEtaria4: "",
    FaixaEtaria5: "",
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramObj = {};
    for (const [key, value] of queryParams.entries()) {
      paramObj[key] = value;
    }
    setMidiaParams(JSON.stringify(paramObj));
  }, [location]);

  const url = process.env.REACT_APP_HOST_URL;
  const origin = process.env.REACT_APP_ORIGIN;

  useEffect(() => {
    dispatch(SignUpActions.setSignUpData());
  }, [dispatch]);

  const handleChildClick = (buttonId) => {
    setChildButton(buttonId === childButton ? null : buttonId);
    if (buttonId !== "simButton") {
      setChildNumbers(null);
    }
  };

  const handleChildNumberClick = (buttonId) => {
    setChildNumbers(buttonId === childNumbers ? null : buttonId);
  };

  return (
    <S.FormHolder>
      <Margin mb={mobile ? 3 : 4}>
        <Margin mb={2}>
          <Title as="2" levelDesktop="5" level="5" primary emphasis>
            Queremos te conhecer melhor!
          </Title>
        </Margin>
        <Text as="p" level={mobile ? "2" : "1"}>
          Com as informações abaixo, podemos indicar as melhores ofertas e
          produtos para sua rotina, além de benefícios exclusivos.
        </Text>
      </Margin>
      <Formik
        initialValues={initialValues}
        validationSchema={() => schema(childButton, childNumbers)}
        // enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          dispatch(SignUpActions.setSignUpData());

          const Perguntas = [
            { id: process.env.REACT_APP_COMPLEMENT_QUESTION_1, resposta: childButton === "simButton" ? "Sim" : (childButton === "naoButton" ? "Não" : "Pretendo ter") },
            { id: process.env.REACT_APP_COMPLEMENT_QUESTION_2, resposta: childNumbers === "fiveMore" ? "Mais de 5" : childNumbers },
            { id: process.env.REACT_APP_COMPLEMENT_QUESTION_3, resposta: values.FaixaEtaria1.label },
            { id: process.env.REACT_APP_COMPLEMENT_QUESTION_4, resposta: values.FaixaEtaria2.label },
            { id: process.env.REACT_APP_COMPLEMENT_QUESTION_5, resposta: values.FaixaEtaria3.label },
            { id: process.env.REACT_APP_COMPLEMENT_QUESTION_6, resposta: values.FaixaEtaria4.label },
            { id: process.env.REACT_APP_COMPLEMENT_QUESTION_7, resposta: values.FaixaEtaria5.label }
          ];
          
          const formatedQuestions = Perguntas.reduce((acc, item, index) => {
            if (item.resposta !== undefined && item.resposta !== null && item.resposta !== "") {
              acc[`Perguntas[${index}][id]`] = item.id;
              acc[`Perguntas[${index}][resposta]`] = item.resposta;
            }
            return acc;
          }, {});

          const payloadValues = {
            ...values,
            PossuiFilhos:
              childButton === "simButton"
                ? "Sim"
                : childButton === "naoButton"
                ? "Não"
                : "Pretendo ter",
            QuantosFilhos:
              childNumbers === "fiveMore" ? "Mais de 5" : childNumbers,
            ChegadaDoBebe: values.ChegadaDoBebe ? true : false,
            DicasDeCuidado: values.DicasDeCuidado ? true : false,
            FasesDoDesenvolvimento: values.FasesDoDesenvolvimento
              ? true
              : false,
            SaudeMentalMaterna: values.SaudeMentalMaterna ? true : false,
            SegurancaEIngredientes: values.SegurancaEIngredientes
              ? true
              : false,
            AceitaReceberNoticias: values.AceitaReceberNoticias ? true : false,
            Sexo: values.Sexo.value,
            DataNascimento: formatDate(values.DataNascimento),
            ...formatedQuestions,
            ParticipanteHash: participanteHash,
            LoginToken: loginToken,
            url: url,
            origin: origin,
            midia: midiaParams,
          };
          
          delete payloadValues.FaixaEtaria1;
          delete payloadValues.FaixaEtaria2;
          delete payloadValues.FaixaEtaria3;
          delete payloadValues.FaixaEtaria4;
          delete payloadValues.FaixaEtaria5;

          dispatch(SignUpActions.sendComplementForm(payloadValues));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isValid,
          dirty,
        }) => (
          <S.FormLimiter onSubmit={handleSubmit}>
            <Margin mb={mobile ? 7 : 2}>
              <Select
                label="Gênero"
                name="Sexo"
                placeholder="Selecione"
                onChange={setFieldValue}
                onBlur={handleBlur}
                value={values.Sexo}
                errors={errors.Sexo && touched.Sexo && errors.Sexo}
                valid={!errors.Sexo && touched.Sexo && !errors.Sexo}
                options={genderSelect}
                required
                id="genderFieldFormSignup"
              />
            </Margin>
            <Margin mb={mobile ? 7 : 4}>
              <Input
                mask={dateMask}
                label="Data de nascimento"
                placeholder="00/00/0000"
                htmlFor="birthFieldFormSignup"
                name="DataNascimento"
                value={values.DataNascimento}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={
                  errors.DataNascimento &&
                  touched.DataNascimento &&
                  errors.DataNascimento
                }
                valid={
                  !errors.DataNascimento &&
                  touched.DataNascimento &&
                  !errors.DataNascimento
                }
                required
              />
            </Margin>

            <Margin mb={2}>
              <Text as="p" level={mobile ? "2" : "1"}>
                Possui filhos?
              </Text>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={4} md={3}>
                  <S.NewButton>
                    <Button
                      className="animation-size"
                      color={
                        childButton === "simButton" ? "secondary" : "primary"
                      }
                      variant={
                        childButton === "simButton" ? "contained" : "outlined"
                      }
                      onClick={() => {
                        handleChildClick("simButton");
                        if (values.PossuiFilhos === "simButton") {
                          setFieldValue("PossuiFilhos", "");
                          // setFieldValue("QuantosFilhos", "");
                        } else {
                          setFieldValue("PossuiFilhos", "simButton");
                        }
                      }}
                    >
                      Sim
                    </Button>
                  </S.NewButton>
                </Grid>
                <Grid item xs={4} md={3}>
                  <S.NewButton>
                    <Button
                      className="animation-size"
                      color={
                        childButton === "naoButton" ? "secondary" : "primary"
                      }
                      variant={
                        childButton === "naoButton" ? "contained" : "outlined"
                      }
                      onClick={() => {
                        handleChildClick("naoButton");
                        if (values.PossuiFilhos === "naoButton") {
                          setFieldValue("PossuiFilhos", "");
                          // setFieldValue("QuantosFilhos", "");
                        } else {
                          setFieldValue("PossuiFilhos", "naoButton");
                          // setFieldValue("QuantosFilhos", "");
                        }
                      }}
                    >
                      Não
                    </Button>
                  </S.NewButton>
                </Grid>
                <Grid item xs={12} md={6}>
                  <S.NewButton>
                    <Button
                      className="animation-size"
                      color={
                        childButton === "pretendoTerButton"
                          ? "secondary"
                          : "primary"
                      }
                      variant={
                        childButton === "pretendoTerButton"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        handleChildClick("pretendoTerButton");
                        if (values.PossuiFilhos === "pretendoTerButton") {
                          setFieldValue("PossuiFilhos", "");
                          // setFieldValue("QuantosFilhos", "");
                        } else {
                          setFieldValue("PossuiFilhos", "pretendoTerButton");
                          // setFieldValue("QuantosFilhos", "");
                        }
                      }}
                    >
                      Ainda não, mas pretendo ter!
                    </Button>
                  </S.NewButton>
                </Grid>
              </Grid>
              {errors.PossuiFilhos && errors.PossuiFilhos.length < 50 && (
                <Message variant="error">{errors.PossuiFilhos}</Message>
              )}
            </Margin>
            <Margin mb={3}>
              {childButton === "simButton" && (
                <>
                  <Text as="p" level={mobile ? "2" : "1"}>
                    Quantos filhos?
                  </Text>
                  <Grid container spacing={1}>
                    {["01", "02", "03", "04", "05", "fiveMore"].map(
                      (option) => (
                        <Grid item>
                          <S.NewButton>
                            <Button
                              key={option}
                              color={
                                childNumbers === option
                                  ? "secondary"
                                  : "primary"
                              }
                              variant={
                                childNumbers === option
                                  ? "contained"
                                  : "outlined"
                              }
                              onClick={() => {
                                handleChildNumberClick(option);
                                setFieldValue("QuantosFilhos", option);
                                // if (values.QuantosFilhos === option) {
                                //   setFieldValue("QuantosFilhos", "");
                                // } else {
                                //   setFieldValue("QuantosFilhos", option);
                                // }
                              }}
                            >
                              {option === "fiveMore" ? "Mais que 5" : option}
                            </Button>
                          </S.NewButton>
                        </Grid>
                      )
                    )}
                  </Grid>
                  {errors.QuantosFilhos &&
                    errors.QuantosFilhos.length < 50 && (
                      <Message variant="error">
                        {errors.QuantosFilhos}
                      </Message>
                    )}
                </>
              )}
            </Margin>
            {childButton === "simButton" && childNumbers !== "fiveMore" && (
              <Margin mb={2}>
                <>
                  <Text as="p" level={mobile ? "2" : "1"}>
                    Qual a faixa etária das crianças?
                  </Text>
                  <Grid container spacing={1}>
                    {Array.from(
                      { length: parseInt(childNumbers, 10) || 0 },
                      (_, index) => (
                        <Grid item xs={12} md={12} key={index}>
                          <Select
                            name={`FaixaEtaria${index + 1}`}
                            placeholder="Selecione a faixa etária"
                            onChange={setFieldValue}
                            onBlur={handleBlur}
                            value={
                              values[`FaixaEtaria${index + 1}`] || babyAge[0]
                            }
                            errors={
                              errors[`FaixaEtaria${index + 1}`] &&
                              touched[`FaixaEtaria${index + 1}`] &&
                              errors[`FaixaEtaria${index + 1}`]
                            }
                            valid={
                              !errors[`FaixaEtaria${index + 1}`] &&
                              touched[`FaixaEtaria${index + 1}`] &&
                              !errors[`FaixaEtaria${index + 1}`]
                            }
                            options={babyAge}
                            id={`babyAgeFormComplement${index}`}
                          />
                          {errors[`FaixaEtaria${index + 1}`] &&
                            touched[`FaixaEtaria${index + 1}`] &&
                            errors[`FaixaEtaria${index + 1}`].length < 50 && (
                              <Message variant="error">
                                {errors[`FaixaEtaria${index + 1}`]}
                              </Message>
                            )}
                        </Grid>
                      )
                    )}
                  </Grid>
                </>
              </Margin>
            )}
            <Margin mb={mobile ? 3 : 4}>
              <S.TermsHolder>
                <Margin mb={1}>
                  <Text level="1" emphasis>
                    Qual o seu interesse?
                  </Text>
                </Margin>
                <Margin mb={2}>
                  <Text level="3">
                    Selecione os interesses sobre os quais gostaria de receber
                    nosso conteúdo:
                  </Text>
                </Margin>
                <Margin mb={2}>
                  <label>
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      name="ChegadaDoBebe"
                      value="1"
                      checked={values.ChegadaDoBebe}
                      // errors={errors.ChegadaDoBebe && touched.ChegadaDoBebe && errors.ChegadaDoBebe}
                      valid={!errors.ChegadaDoBebe}
                    />
                    <Text level="3">Chegada do bebê</Text>
                    {errors.ChegadaDoBebe &&
                      errors.ChegadaDoBebe.length < 50 && (
                        <Message variant="error">
                          {errors.ChegadaDoBebe}
                        </Message>
                      )}
                  </label>
                </Margin>
                <Margin mb={2}>
                  <label>
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      name="DicasDeCuidado"
                      value="1"
                      checked={values.DicasDeCuidado}
                      // errors={errors.DicasDeCuidado && touched.DicasDeCuidado && errors.DicasDeCuidado}
                      valid={!errors.DicasDeCuidado}
                    />
                    <Text level="3">Dicas de cuidado</Text>
                    {errors.DicasDeCuidado &&
                      errors.DicasDeCuidado.length < 50 && (
                        <Message variant="error">
                          {errors.DicasDeCuidado}
                        </Message>
                      )}
                  </label>
                </Margin>
                <Margin mb={2}>
                  <label>
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      name="FasesDoDesenvolvimento"
                      value="1"
                      checked={values.FasesDoDesenvolvimento}
                      // errors={errors.FasesDoDesenvolvimento && touched.FasesDoDesenvolvimento && errors.FasesDoDesenvolvimento}
                      valid={!errors.FasesDoDesenvolvimento}
                    />
                    <Text level="3">Fases do desenvolvimento</Text>
                    {errors.FasesDoDesenvolvimento &&
                      errors.FasesDoDesenvolvimento.length < 50 && (
                        <Message variant="error">
                          {errors.FasesDoDesenvolvimento}
                        </Message>
                      )}
                  </label>
                </Margin>
                <Margin mb={2}>
                  <label>
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      name="SaudeMentalMaterna"
                      value="1"
                      checked={values.SaudeMentalMaterna}
                      // errors={errors.SaudeMentalMaterna && touched.SaudeMentalMaterna && errors.SaudeMentalMaterna}
                      valid={!errors.SaudeMentalMaterna}
                    />
                    <Text level="3">Saúde mental materna</Text>
                    {errors.SaudeMentalMaterna &&
                      errors.SaudeMentalMaterna.length < 50 && (
                        <Message variant="error">
                          {errors.SaudeMentalMaterna}
                        </Message>
                      )}
                  </label>
                </Margin>
                <Margin mb={0}>
                  <label>
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      name="SegurancaEIngredientes"
                      value="1"
                      checked={values.SegurancaEIngredientes}
                      // errors={errors.SegurancaEIngredientes && touched.SegurancaEIngredientes && errors.SegurancaEIngredientes}
                      valid={!errors.SegurancaEIngredientes}
                    />
                    <Text level="3">Segurança e ingredientes</Text>
                    {errors.SegurancaEIngredientes &&
                      errors.SegurancaEIngredientes.length < 50 && (
                        <Message variant="error">
                          {errors.SegurancaEIngredientes}
                        </Message>
                      )}
                  </label>
                </Margin>
              </S.TermsHolder>
              { /*
                <S.CommunicationHolder>
                <label>
                  <Field
                    component={Checkbox}
                    type="checkbox"
                    name="AceitaReceberNoticias"
                    value="1"
                    checked={values.AceitaReceberNoticias}
                    // errors={errors.AceitaReceberNoticias && touched.AceitaReceberNoticias && errors.AceitaReceberNoticias}
                    valid={!errors.AceitaReceberNoticias}
                  />
                  <Text level="3">
                    Quero receber comunicações personalizadas sobre os
                    produtos KENVUE.{" "}
                  </Text>
                  {errors.AceitaReceberNoticias &&
                    errors.AceitaReceberNoticias.length < 50 && (
                      <Message variant="error">
                        {errors.AceitaReceberNoticias}
                      </Message>
                    )}
                </label>
              </S.CommunicationHolder> */
              }
            </Margin>
            <S.ButtonsGrid container justifyContent="flex-end">
              <Margin mb={3}>
                <Button
                  className="animation-size"
                  color={
                    !isValid || !dirty || sending ? "disabled" : "secondary"
                  }
                  type="submit"
                  disabled={!isValid || !dirty || sending}
                  id="nextButtonFormSignup"
                >
                  {sending ? <Loading button /> : "Finalizar"}
                </Button>
              </Margin>
            </S.ButtonsGrid>
            {errorMessage && (
              <Margin mt={mobile ? 3 : 5}>
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </Margin>
            )}
          </S.FormLimiter>
        )}
      </Formik>
    </S.FormHolder>
  );
}
